import { Complaint } from "@/lib/types/complaints";
import { GridView } from "./ComplaintGrid";
import { ListView } from "./ListView";
import { KanbanView } from "./KanbanView";
import { debugLog } from "@/lib/debug";
import { useEffect } from "react";

interface ViewRendererProps {
  view: string;
  complaints: Complaint[];
}

export function ViewRenderer({ view, complaints }: ViewRendererProps) {
  // Ensure complaints is always an array
  const safeComplaints = Array.isArray(complaints) ? complaints : [];
  
  // Log view rendering with useEffect to avoid excessive logging
  useEffect(() => {
    debugLog("ViewRenderer", `Rendering ${view} view with ${safeComplaints.length} complaints`, {
      view,
      complaintCount: safeComplaints.length,
      firstComplaintId: safeComplaints[0]?.id || 'none'
    });
  }, [view, safeComplaints]);
  
  // Determine which view to render
  const renderView = () => {
    switch(view) {
      case "list":
        return <ListView complaints={safeComplaints} />;
      case "kanban":
        return <KanbanView complaints={safeComplaints} />;
      case "grid":
      default:
        return <GridView complaints={safeComplaints} />;
    }
  };
  
  return (
    <div className="w-full overflow-x-auto pb-4">
      {renderView()}
    </div>
  );
}
