
import { useState, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { DashboardLayout } from '@/components/layout/DashboardLayout';
import { Map } from '@/components/map/Map';
import { MapFilters } from '@/components/map/MapFilters';
import { ComplaintLocationsList } from '@/components/map/ComplaintLocationsList';
import { SupabaseService } from '@/lib/SupabaseService';
import { Complaint } from '@/lib/types/complaints';
import { useAuth } from '@/lib/AuthContext';
import { Button } from '@/components/ui/button';
import { debugError } from '@/lib/debug';
import { toast } from 'sonner';

const MapView = () => {
  const { user } = useAuth();
  const [filteredComplaints, setFilteredComplaints] = useState<Complaint[] | null>(null);
  
  const { 
    data: complaints, 
    isLoading, 
    error,
    refetch
  } = useQuery({
    queryKey: ['complaints'],
    queryFn: async () => {
      try {
        return await SupabaseService.fetchComplaints();
      } catch (error) {
        debugError('MapView', 'Error fetching complaints:', error);
        throw error;
      }
    },
    enabled: !!user,
    retry: 2,
    meta: {
      onError: (error: Error) => {
        debugError('MapView', 'Query error:', error);
        toast.error('Error loading complaints');
      }
    }
  });

  const handleFilterChange = (filters: { search: string; department: string; status: string }) => {
    if (!complaints) return;

    const filtered = complaints.filter(complaint => {
      if (filters.search && !complaint.title.toLowerCase().includes(filters.search.toLowerCase()) &&
          !complaint.description.toLowerCase().includes(filters.search.toLowerCase()) &&
          !(complaint.location && complaint.location.toLowerCase().includes(filters.search.toLowerCase()))) {
        return false;
      }

      if (filters.department && complaint.department !== filters.department) {
        return false;
      }

      if (filters.status && complaint.status !== filters.status) {
        return false;
      }

      return true;
    });

    setFilteredComplaints(filtered);
  };

  useEffect(() => {
    setFilteredComplaints(null);
  }, [complaints]);

  const handleRetry = () => {
    refetch();
    toast.info('Retrying data fetch...');
  };

  if (!user) {
    return (
      <div className="min-h-screen flex flex-col bg-background">
        <div className="container mx-auto flex-1 flex items-center justify-center">
          <div className="py-20 text-center space-y-4 max-w-md">
            <h2 className="text-2xl font-semibold">Welcome to Jan Samadhan Map View</h2>
            <p className="text-muted-foreground">
              Please login to view complaint locations on the map.
            </p>
            <Button asChild className="mt-4">
              <a href="/auth">Login or Create Account</a>
            </Button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <DashboardLayout>
      <div className="space-y-6">
        <div className="flex justify-between items-center">
          <div>
            <h1 className="text-3xl font-bold tracking-tight">Map View</h1>
            <p className="text-muted-foreground">
              Visualize complaints by location
            </p>
          </div>
          {error && (
            <Button onClick={handleRetry} variant="outline">
              Retry Loading Data
            </Button>
          )}
        </div>
        
        {isLoading ? (
          <div className="h-64 flex items-center justify-center">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-primary"></div>
          </div>
        ) : error ? (
          <div className="text-center py-10 text-destructive">
            <p className="mb-4">Error loading complaints. Please try again later.</p>
            <pre className="text-sm bg-muted p-4 rounded overflow-auto max-w-full">
              {error instanceof Error ? error.message : JSON.stringify(error)}
            </pre>
          </div>
        ) : (
          <>
            <MapFilters onFilterChange={handleFilterChange} />
            
            <div className="grid gap-6 lg:grid-cols-3">
              <div className="lg:col-span-2">
                <Map 
                  complaints={complaints || []} 
                  filteredComplaints={filteredComplaints || undefined} 
                />
              </div>
              <div className="lg:col-span-1">
                <ComplaintLocationsList 
                  complaints={filteredComplaints || complaints || []} 
                />
              </div>
            </div>
          </>
        )}
      </div>
    </DashboardLayout>
  );
};

export default MapView;
