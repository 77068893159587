
import { Complaint } from './complaints';

export interface MapProps {
  complaints: Complaint[];
  filteredComplaints?: Complaint[];
}

export interface TooltipConfig {
  show: boolean;
  complaint?: Complaint;
  position?: {
    x: number;
    y: number;
  };
}

export interface InteractiveMarkerConfig {
  map: google.maps.Map;
  complaint: Complaint;
  onClick?: (complaint: Complaint) => void;
  onHover?: (complaint: Complaint) => void;
  onLeave?: () => void;
}

export const DELHI_COORDINATES = {
  lat: 28.6139,
  lng: 77.209
};

export const MAP_ZOOM_LEVELS = {
  city: 10,
  district: 12,
  neighborhood: 14,
  street: 16,
  building: 18
};
