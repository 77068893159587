import { AreaChart, Area, ResponsiveContainer, Tooltip, Legend } from "recharts";
import { CustomTooltip } from "./CustomTooltip";
import { CustomXAxis, CustomYAxis } from "./RechartsWrappers";

interface TrendData {
  day: string;
  complaints: number;
  resolved: number;
}

export function TrendChart({ data }: { data: TrendData[] }) {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <AreaChart 
        data={data} 
        margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
      >
        <defs>
          <linearGradient id="colorComplaints" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="hsl(var(--analytics-blue))" stopOpacity={0.8}/>
            <stop offset="95%" stopColor="hsl(var(--analytics-blue))" stopOpacity={0.1}/>
          </linearGradient>
          <linearGradient id="colorResolved" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="hsl(var(--analytics-green))" stopOpacity={0.8}/>
            <stop offset="95%" stopColor="hsl(var(--analytics-green))" stopOpacity={0.1}/>
          </linearGradient>
        </defs>
        <CustomXAxis 
          dataKey="day" 
          tick={{ fill: 'hsl(var(--muted-foreground))' }}
        />
        <CustomYAxis 
          tick={{ fill: 'hsl(var(--muted-foreground))' }}
        />
        <Tooltip content={<CustomTooltip />} />
        <Legend />
        <Area 
          type="monotone" 
          dataKey="complaints" 
          stroke="hsl(var(--analytics-blue))" 
          fillOpacity={1} 
          fill="url(#colorComplaints)"
          activeDot={{ r: 8, strokeWidth: 0 }}
          animationDuration={1500}
        />
        <Area 
          type="monotone" 
          dataKey="resolved" 
          stroke="hsl(var(--analytics-green))" 
          fillOpacity={1} 
          fill="url(#colorResolved)"
          activeDot={{ r: 8, strokeWidth: 0 }}
          animationDuration={1500}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
}
