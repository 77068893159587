import { Link } from "react-router-dom";
import { formatDistanceToNow } from "date-fns";
import { Complaint } from "@/lib/data";
import { Badge } from "@/components/ui/badge";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "@/components/ui/card";
import { StatusBadge } from "@/components/ui/StatusBadge";
import { User, MapPin, MessageCircle, Calendar, Tag } from "lucide-react";
import { cn } from "@/lib/utils";
import { motion } from "framer-motion";

interface ComplaintCardProps {
  complaint: Complaint;
}

export function ComplaintCard({ complaint }: ComplaintCardProps) {
  const isWhatsAppComplaint = complaint.source === 'whatsapp_bot';
  const hasEmoji = complaint.title.match(/\p{Emoji}/u);
  
  return (
    <Link to={`/complaint/${complaint.id}`}>
      <Card className="h-full overflow-hidden transition-all hover:border-primary/50 hover:shadow-md group">
        <CardHeader className={cn(
          "pb-2",
          isWhatsAppComplaint ? "bg-gradient-to-r from-green-50 to-transparent" : ""
        )}>
          <div className="flex justify-between items-start gap-2">
            <CardTitle className="line-clamp-1 flex items-center gap-1 group-hover:text-primary transition-colors">
              {isWhatsAppComplaint && (
                <MessageCircle className="h-4 w-4 text-green-600 flex-shrink-0" />
              )}
              <span>{complaint.title}</span>
            </CardTitle>
            <div className="flex flex-col gap-1 items-end">
              <StatusBadge status={complaint.status} />
              {complaint.isDummy && (
                <Badge variant="outline" className="bg-amber-100 text-amber-800 border-amber-200">
                  Demo
                </Badge>
              )}
              {complaint.manuallyCreated && (
                <Badge variant="outline" className="bg-purple-100 text-purple-800 border-purple-200">
                  Manual
                </Badge>
              )}
            </div>
          </div>
          <CardDescription className="flex items-center gap-1">
            {isWhatsAppComplaint && (
              <span className="text-green-600 text-xs font-medium">WhatsApp</span>
            )}
            <div className="flex items-center gap-1">
              <Calendar className="h-3 w-3 text-muted-foreground" />
              <span>{formatDistanceToNow(new Date(complaint.createdAt), { addSuffix: true })}</span>
            </div>
          </CardDescription>
        </CardHeader>
        <CardContent className="pb-2">
          <p className="text-sm line-clamp-2 text-muted-foreground group-hover:text-foreground transition-colors">
            {complaint.description}
          </p>
          
          {complaint.issueTypeTitle && (
            <div className="mt-2 flex items-center gap-1">
              <Tag className="h-3 w-3 text-blue-600" />
              <Badge variant="outline" className="bg-blue-50 text-blue-700 border-blue-100">
                {complaint.issueTypeTitle}
              </Badge>
            </div>
          )}
        </CardContent>
        <CardFooter className="flex flex-col gap-2 text-xs text-muted-foreground">
          <div className="flex justify-between w-full">
            <div className="flex items-center">
              <span className="font-medium">Dept:</span>{" "}
              <span className="capitalize ml-1">{complaint.department}</span>
            </div>
            {complaint.location && (
              <div className="flex items-center gap-1">
                <MapPin className="h-3 w-3" />
                <span className="max-w-[120px] truncate">{complaint.location}</span>
              </div>
            )}
          </div>
          
          {(complaint.assignedToName || (isWhatsAppComplaint && complaint.whatsapp_user_name)) && (
            <div className="w-full flex items-center gap-1 text-xs">
              <User className="h-3 w-3" />
              <span className="truncate">
                {isWhatsAppComplaint && complaint.whatsapp_user_name ? 
                  `From: ${complaint.whatsapp_user_name}` : 
                  `Assigned to: ${complaint.assignedToName}`}
              </span>
            </div>
          )}
        </CardFooter>
      </Card>
    </Link>
  );
}
