
import { createEnv } from "@t3-oss/env-core";
import { z } from "zod";

/**
 * Defines a typesafe schema for environment variables.
 * This provides runtime validation and type safety for environment variables.
 */
export const env = createEnv({
  /**
   * Server-side environment variables (not exposed to the client)
   */
  server: {
    // Add server-only env vars here if needed
  },

  /**
   * Client-side environment variables prefix
   * Vite uses VITE_ as the prefix for client-side env vars
   */
  clientPrefix: "VITE_",

  /**
   * Client-side environment variables (exposed to the client)
   */
  client: {
    // Facebook/WhatsApp access token for media - made optional to prevent build errors
    VITE_FACEBOOK_ACCESS_TOKEN: z.string().optional(),
  },

  /**
   * Runtime environment object
   * In Vite, this is import.meta.env
   */
  runtimeEnv: import.meta.env,

  /**
   * Treat empty strings as undefined
   * This helps with optional environment variables
   */
  emptyStringAsUndefined: true,
});
