
import { createContext, useContext, useState, useEffect, ReactNode, useCallback } from "react";
import { supabase } from "@/integrations/supabase/client";
import { useAuth } from "./AuthContext";
import { toast } from "sonner";
import { AdminService } from "./services/AdminService";
import { Database } from "@/integrations/supabase/types";

export type UserRole = Database["public"]["Enums"]["app_role"] | null;

interface UserRoleContextType {
  role: UserRole;
  isRoleLoading: boolean;
  isAdmin: boolean;
  isStaff: boolean;
  refreshRole: () => Promise<void>;
}

const UserRoleContext = createContext<UserRoleContextType | undefined>(undefined);

// Use your admin email for proper recognition
const ADMIN_EMAIL = 'danisharora099@gmail.com';

export function UserRoleProvider({ children }: { children: ReactNode }) {
  const { user } = useAuth();
  const [role, setRole] = useState<UserRole>(null);
  const [isRoleLoading, setIsRoleLoading] = useState(true);

  const fetchUserRole = useCallback(async () => {
    if (!user) {
      setRole(null);
      setIsRoleLoading(false);
      return;
    }

    try {
      setIsRoleLoading(true);
      
      // First, check if this is the admin email
      if (user.email === ADMIN_EMAIL) {
        console.log("Admin email detected, setting admin role directly");
        // Set admin role immediately
        setRole('admin');
        
        // Try to ensure this user is an admin in the database
        // But don't wait for it to complete before setting UI role
        try {
          AdminService.ensureUserIsAdmin(ADMIN_EMAIL)
            .then(success => {
              if (!success) {
                console.warn("Failed to ensure admin role in database, but continuing with admin UI access");
              }
            })
            .catch(err => {
              console.error("Error ensuring admin role:", err);
            });
        } catch (error) {
          console.error("Error initiating admin role update:", error);
          // Still continue with admin UI access
        }
      } else {
        // For other users, check their role in user_roles table
        try {
          // Use the has_role function instead of direct table access
          // This bypasses some of the role access restrictions
          const { data: isAdmin, error: adminError } = await supabase
            .rpc('has_role', { _user_id: user.id, _role: 'admin' });
            
          if (!adminError && isAdmin) {
            setRole('admin');
          } else {
            const { data: isStaff, error: staffError } = await supabase
              .rpc('has_role', { _user_id: user.id, _role: 'staff' });
              
            if (!staffError && isStaff) {
              setRole('staff');
            } else {
              // New user with no role yet
              setRole(null);
              toast.error("Your account is pending approval");
            }
          }
        } catch (error) {
          console.error("Error in user_roles query:", error);
          setRole(null);
        }
      }
    } catch (error) {
      console.error("Error in fetchUserRole:", error);
      setRole(null);
    } finally {
      setIsRoleLoading(false);
    }
  }, [user]);

  useEffect(() => {
    fetchUserRole();
  }, [fetchUserRole]);

  const value = {
    role,
    isRoleLoading,
    isAdmin: role === 'admin',
    isStaff: role === 'staff',
    refreshRole: fetchUserRole,
  };

  return <UserRoleContext.Provider value={value}>{children}</UserRoleContext.Provider>;
}

export function useUserRole() {
  const context = useContext(UserRoleContext);
  if (context === undefined) {
    throw new Error("useUserRole must be used within a UserRoleProvider");
  }
  return context;
}
