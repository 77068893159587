
import { Link } from "react-router-dom";
import { ChevronLeft, MessageSquare, Globe, Phone } from "lucide-react";
import { formatDistanceToNow } from "date-fns";
import { Complaint } from "@/lib/data";
import { StatusBadge } from "@/components/ui/StatusBadge";
import { Badge } from "@/components/ui/badge";
import { cn } from "@/lib/utils";

interface BackLinkProps {
  url?: string;
}

export const BackLink = ({ url = "/complaints" }: BackLinkProps) => (
  <Link
    to={url}
    className="inline-flex items-center gap-1 text-sm font-medium text-muted-foreground hover:text-foreground mb-6 transition-colors"
  >
    <ChevronLeft className="h-4 w-4" />
    Back to Dashboard
  </Link>
);

interface ComplaintHeaderProps {
  complaint: Complaint;
}

export const ComplaintHeader = ({ complaint }: ComplaintHeaderProps) => {
  const isWhatsAppComplaint = complaint.source === 'whatsapp_bot';
  
  return (
    <div className="space-y-2">
      <div className="flex items-start justify-between">
        <div className={cn(
          "max-w-3xl",
          isWhatsAppComplaint ? "bg-gradient-to-r from-green-50 to-transparent p-4 -mx-4 rounded-lg" : ""
        )}>
          <h1 className={cn(
            "text-2xl font-bold tracking-tight md:text-3xl flex items-center gap-2",
            isWhatsAppComplaint ? "text-green-900" : ""
          )}>
            {isWhatsAppComplaint && (
              <MessageSquare className="h-6 w-6 text-green-600" />
            )}
            {complaint.title}
          </h1>
          <p className="text-muted-foreground">
            Submitted {formatDistanceToNow(new Date(complaint.createdAt), { addSuffix: true })}
          </p>
          
          {isWhatsAppComplaint && (
            <div className="mt-3 space-y-2">
              {complaint.whatsapp_user_name && (
                <div className="flex items-center gap-2 text-sm">
                  <Badge variant="outline" className="bg-green-50 text-green-800 border-green-200 py-1.5">
                    <MessageSquare className="h-3.5 w-3.5 mr-1" /> 
                    From WhatsApp
                  </Badge>
                </div>
              )}
              
              <div className="flex flex-wrap gap-2">
                {complaint.issueTypeTitle && (
                  <Badge variant="outline" className="bg-blue-50 text-blue-800 border-blue-200">
                    {complaint.issueTypeTitle}
                  </Badge>
                )}
                
                {complaint.language && (
                  <Badge variant="outline" className="bg-purple-50 text-purple-800 border-purple-200">
                    <Globe className="h-3 w-3 mr-1" /> 
                    {complaint.language}
                  </Badge>
                )}

                {complaint.whatsapp_user_phone && (
                  <Badge variant="outline" className="bg-slate-50 text-slate-800 border-slate-200">
                    <Phone className="h-3 w-3 mr-1" /> 
                    {complaint.whatsapp_user_phone}
                  </Badge>
                )}
              </div>
            </div>
          )}
        </div>
        <div className="flex flex-col gap-2">
          <StatusBadge status={complaint.status} />
          {complaint.isDummy && (
            <Badge variant="outline" className="bg-amber-100 text-amber-800 border-amber-200">
              Demo Data
            </Badge>
          )}
          {complaint.manuallyCreated && (
            <Badge variant="outline" className="bg-purple-100 text-purple-800 border-purple-200">
              Manually Created
            </Badge>
          )}
        </div>
      </div>
    </div>
  );
};
