import { Complaint } from "@/lib/data";
import { Button } from "@/components/ui/button";
import { useWhatsAppMedia } from "@/lib/hooks/useWhatsAppMedia";
import { useEffect, useState } from "react";
import { env } from "@/lib/env";
import { AlertCircle, ExternalLink, RefreshCw } from "lucide-react";

interface AttachmentsProps {
  complaint: Complaint;
}

export function Attachments({ complaint }: AttachmentsProps) {
  const [missingAccessToken, setMissingAccessToken] = useState(false);
  const [imageLoadingStates, setImageLoadingStates] = useState<Record<string, boolean>>({});
  const [imageErrorStates, setImageErrorStates] = useState<Record<string, boolean>>({});

  useEffect(() => {
    // Check if the Facebook access token is configured
    if (!env.VITE_FACEBOOK_ACCESS_TOKEN) {
      setMissingAccessToken(true);
    }

    // Initialize loading states for all attachments
    if (complaint.attachments && complaint.attachments.length > 0) {
      const initialLoadingStates: Record<string, boolean> = {};
      complaint.attachments.forEach((attachment) => {
        initialLoadingStates[attachment] = true;
      });
      setImageLoadingStates(initialLoadingStates);
    }
  }, [complaint.attachments]);

  const handleImageError = (url: string) => {
    setImageErrorStates((prev) => ({
      ...prev,
      [url]: true,
    }));
    setImageLoadingStates((prev) => ({
      ...prev,
      [url]: false,
    }));
  };

  const handleImageLoad = (url: string) => {
    setImageLoadingStates((prev) => ({
      ...prev,
      [url]: false,
    }));
  };

  if (!complaint.attachments || complaint.attachments.length === 0) {
    return null;
  }

  return (
    <div className="space-y-4">
      <h3 className="text-lg font-medium">Attachments</h3>
      
      {missingAccessToken && (
        <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 mb-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <AlertCircle className="h-5 w-5 text-yellow-400" />
            </div>
            <div className="ml-3">
              <p className="text-sm text-yellow-700">
                <strong>Configuration Warning:</strong> Facebook access token is not configured. 
                WhatsApp media attachments may not display correctly.
              </p>
            </div>
          </div>
        </div>
      )}

      <div className="bg-blue-50 border-l-4 border-blue-400 p-4 mb-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <AlertCircle className="h-5 w-5 text-blue-400" />
          </div>
          <div className="ml-3">
            <p className="text-sm text-blue-700">
              <strong>Note:</strong> WhatsApp media is retrieved through the WhatsApp bot server at{' '}
              <code>bot.jansamadhan.in</code>. If media doesn't load, please check that the server is running 
              and accessible.
            </p>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
        {complaint.attachments.map((attachment) => (
          <AttachmentItem
            key={attachment}
            url={attachment}
            isLoading={imageLoadingStates[attachment] || false}
            hasError={imageErrorStates[attachment] || false}
            onLoad={() => handleImageLoad(attachment)}
            onError={() => handleImageError(attachment)}
          />
        ))}
      </div>
    </div>
  );
}

interface AttachmentItemProps {
  url: string;
  isLoading: boolean;
  hasError: boolean;
  onLoad: () => void;
  onError: () => void;
}

function AttachmentItem({ url, isLoading, hasError, onLoad, onError }: AttachmentItemProps) {
  const { url: mediaUrl, isLoading: isMediaLoading, error: mediaError, refreshMediaUrl } = useWhatsAppMedia(url);

  // Combine loading states
  const isItemLoading = isLoading || isMediaLoading;
  
  // Combine error states
  const hasItemError = hasError || !!mediaError;

  // Get a user-friendly error message
  const getErrorMessage = () => {
    if (!mediaError) return "Unknown error";
    
    const errorMessage = mediaError.message;
    
    if (errorMessage.includes("timed out") || errorMessage.includes("unavailable")) {
      return "The WhatsApp bot server is unavailable. Please check that it's running.";
    }
    
    if (errorMessage.includes("Failed to retrieve media")) {
      return "Failed to retrieve media from WhatsApp. The media may have expired or been deleted.";
    }
    
    return errorMessage;
  };

  if (hasItemError) {
    return (
      <div className="border rounded-md p-4 flex flex-col items-center justify-center bg-gray-50 h-48">
        <div className="text-center text-sm text-gray-500">
          <p className="font-medium text-red-500">Failed to load attachment</p>
          <p className="text-xs mt-1 max-w-full overflow-hidden text-ellipsis px-2">
            {getErrorMessage()}
          </p>
          <div className="flex flex-col gap-2 mt-3">
            <Button 
              variant="outline" 
              size="sm"
              className="flex items-center gap-1"
              onClick={refreshMediaUrl}
            >
              <RefreshCw className="h-3 w-3" />
              Retry
            </Button>
            <Button 
              variant="outline" 
              size="sm"
              className="flex items-center gap-1"
              onClick={() => window.open(url, '_blank')}
            >
              <ExternalLink className="h-3 w-3" />
              Open Original URL
            </Button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="border rounded-md overflow-hidden relative">
      {isItemLoading && (
        <div className="absolute inset-0 flex items-center justify-center bg-gray-50 z-10">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
        </div>
      )}
      
      {mediaUrl && (
        <img
          src={mediaUrl}
          alt="Attachment"
          className="w-full h-48 object-cover"
          onLoad={onLoad}
          onError={onError}
        />
      )}
    </div>
  );
}
