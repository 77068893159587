import { 
  Complaint, 
  PMComplaintStatus as ComplaintStatus, 
  PMDepartment as Department, 
  departmentMapping,
  convertToSharedStatus
} from "../../types/complaints";
import { BaseService } from "../BaseService";
import { debugError } from "../../debug";
import { PostgrestError } from "@supabase/supabase-js";
import { ComplaintStatus as SharedComplaintStatus } from "jansamadhan-shared-types";

export class UpdateComplaintService extends BaseService {
  static async updateComplaint(id: string, updates: Partial<Complaint>): Promise<Complaint | null> {
    try {
      // Convert from our frontend model to the database model
      const dbUpdates: Record<string, unknown> = {};
      
      if (updates.status) dbUpdates.status = updates.status;
      if (updates.assignedTo !== undefined) dbUpdates.assigned_to = updates.assignedTo;
      if (updates.title) dbUpdates.title = updates.title;
      if (updates.description) dbUpdates.description = updates.description;
      if (updates.department) dbUpdates.department = updates.department;
      if (updates.location !== undefined) dbUpdates.location = updates.location;
      
      const { data, error } = await this.supabase
        .from('complaints')
        .update(dbUpdates)
        .eq('id', id)
        .select()
        .single();

      if (error) {
        throw new Error(`Failed to update complaint: ${error.message}`);
      }

      if (!data) {
        throw new Error(`No data returned after updating complaint with ID: ${id}`);
      }

      // Ensure department is properly mapped to Department type
      const departmentValue: Department = departmentMapping[data.department] || 'other';

      return {
        id: data.id,
        title: data.title,
        description: data.description,
        status: data.status as ComplaintStatus,
        department: departmentValue,
        createdAt: data.created_at,
        updatedAt: data.updated_at,
        location: data.location || undefined,
        assignedTo: data.assigned_to,
        submittedBy: {
          name: 'Unknown', // We don't have submitter details in the response
          phone: 'Unknown',
          email: 'Unknown'
        },
        attachments: data.attachments || [],
        comments: []
      };
    } catch (error) {
      let errorMessage = 'Unknown error in updateComplaint';
      
      if (error instanceof Error) {
        errorMessage = error.message;
      } else if (typeof error === 'object' && error !== null) {
        const pgError = error as PostgrestError;
        errorMessage = pgError.message || 'Database error';
      }
      
      debugError('UpdateComplaintService', errorMessage, error);
      return null;
    }
  }

  static async updateStatus(
    complaintId: string,
    newStatus: ComplaintStatus,
    comment?: string,
    userId?: string
  ): Promise<boolean> {
    try {
      // Update the complaint status directly
      // No need for status conversion since we're using the PM dashboard status directly
      const { error: updateError } = await this.supabase
        .from('complaints')
        .update({
          status: newStatus,
          updated_at: new Date().toISOString(),
        })
        .eq('id', complaintId);

      if (updateError) {
        console.error('Error updating complaint status:', updateError);
        return false;
      }

      // If there's a comment and a user ID, add the comment
      if (comment && userId) {
        const { error: commentError } = await this.supabase
          .from('comments')
          .insert({
            complaint_id: complaintId,
            text: comment,
            user_id: userId,
            created_at: new Date().toISOString(),
          });

        if (commentError) {
          console.error('Error adding comment:', commentError);
          // We don't return false here because the status update was successful
        }
      }

      return true;
    } catch (error) {
      console.error('Error in updateStatus:', error);
      return false;
    }
  }

  static async assignComplaint(
    complaintId: string,
    assigneeId: string,
    comment?: string,
    userId?: string
  ): Promise<boolean> {
    try {
      // Update the complaint assignment
      const { error: updateError } = await this.supabase
        .from('complaints')
        .update({
          assigned_to: assigneeId,
          updated_at: new Date().toISOString(),
        })
        .eq('id', complaintId);

      if (updateError) {
        console.error('Error assigning complaint:', updateError);
        return false;
      }

      // If there's a comment and a user ID, add the comment
      if (comment && userId) {
        const { error: commentError } = await this.supabase
          .from('comments')
          .insert({
            complaint_id: complaintId,
            text: comment,
            user_id: userId,
            created_at: new Date().toISOString(),
          });

        if (commentError) {
          console.error('Error adding comment:', commentError);
          // We don't return false here because the assignment was successful
        }
      }

      return true;
    } catch (error) {
      console.error('Error in assignComplaint:', error);
      return false;
    }
  }

  static async updateComplaintDetails(
    complaintId: string,
    updates: {
      title?: string;
      description?: string;
      department?: string;
      location?: string;
      priority?: string;
      googleMapsLink?: string;
      latitude?: number;
      longitude?: number;
    }
  ): Promise<boolean> {
    try {
      // Prepare the update payload
      const updatePayload: Record<string, string | number | boolean | null> = {
        updated_at: new Date().toISOString(),
      };

      // Add fields that are present in the updates object
      if (updates.title) updatePayload.title = updates.title;
      if (updates.description) updatePayload.description = updates.description;
      if (updates.department) updatePayload.department = updates.department;
      if (updates.location) updatePayload.location = updates.location;
      if (updates.priority) updatePayload.priority = updates.priority;
      
      // Add WhatsApp-specific fields
      if (updates.googleMapsLink !== undefined) updatePayload.google_maps_link = updates.googleMapsLink;
      if (updates.latitude !== undefined) updatePayload.latitude = updates.latitude;
      if (updates.longitude !== undefined) updatePayload.longitude = updates.longitude;

      // Update the complaint
      const { error } = await this.supabase
        .from('complaints')
        .update(updatePayload)
        .eq('id', complaintId);

      if (error) {
        console.error('Error updating complaint details:', error);
        return false;
      }

      return true;
    } catch (error) {
      console.error('Error in updateComplaintDetails:', error);
      return false;
    }
  }
}
