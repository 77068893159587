import { useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "sonner";
import { Button } from "@/components/ui/button";
import { useUserRole, UserRole } from "@/lib/UserRoleContext";
import { RoleService } from "@/lib/RoleService";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { UserPlus } from "lucide-react";

// Department list
const DEPARTMENTS = [
  "Education",
  "Healthcare",
  "Infrastructure",
  "Water & Sanitation",
  "Agriculture",
  "Public Safety",
  "Social Welfare"
];

interface AssignRoleDialogProps {
  availableUsers: { id: string; email: string; name: string }[] | undefined;
  isLoadingAvailableUsers: boolean;
}

export const AssignRoleDialog = ({ 
  availableUsers, 
  isLoadingAvailableUsers 
}: AssignRoleDialogProps) => {
  const queryClient = useQueryClient();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState<string>("");
  const [selectedRole, setSelectedRole] = useState<UserRole>("staff");
  const [selectedDepartment, setSelectedDepartment] = useState<string>("");
  
  // Mutation for assigning a role
  const assignRoleMutation = useMutation({
    mutationFn: (data: { userId: string; role: UserRole; department?: string }) => 
      RoleService.assignRole(data.userId, data.role, data.department),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['user-roles'] });
      queryClient.invalidateQueries({ queryKey: ['available-users'] });
      toast.success("Role assigned successfully");
      setIsOpen(false);
      resetForm();
    },
    onError: (error: Error) => {
      toast.error(error.message || "Failed to assign role");
    }
  });
  
  const handleAssignRole = () => {
    if (!selectedUserId) {
      toast.error("Please select a user");
      return;
    }
    
    const data: { userId: string; role: UserRole; department?: string } = {
      userId: selectedUserId,
      role: selectedRole
    };
    
    if (selectedRole === 'staff' && selectedDepartment) {
      data.department = selectedDepartment;
    }
    
    assignRoleMutation.mutate(data);
  };
  
  const resetForm = () => {
    setSelectedUserId("");
    setSelectedRole("staff");
    setSelectedDepartment("");
  };

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <Button className="gap-2">
          <UserPlus className="h-4 w-4" />
          Assign Role
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Assign User Role</DialogTitle>
          <DialogDescription>
            Assign a role to a user to control their permissions within the system.
          </DialogDescription>
        </DialogHeader>
        
        <div className="grid gap-4 py-4">
          <div className="space-y-2">
            <label htmlFor="user" className="text-sm font-medium">User</label>
            <Select 
              value={selectedUserId} 
              onValueChange={setSelectedUserId}
              disabled={isLoadingAvailableUsers}
            >
              <SelectTrigger>
                <SelectValue placeholder="Select a user" />
              </SelectTrigger>
              <SelectContent>
                {availableUsers?.map(user => (
                  <SelectItem key={user.id} value={user.id}>
                    {user.name || user.email}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
          
          <div className="space-y-2">
            <label htmlFor="role" className="text-sm font-medium">Role</label>
            <Select 
              value={selectedRole} 
              onValueChange={(value) => setSelectedRole(value as UserRole)}
            >
              <SelectTrigger>
                <SelectValue placeholder="Select a role" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="admin">Administrator</SelectItem>
                <SelectItem value="staff">Staff</SelectItem>
              </SelectContent>
            </Select>
          </div>
          
          {selectedRole === 'staff' && (
            <div className="space-y-2">
              <label htmlFor="department" className="text-sm font-medium">Department</label>
              <Select 
                value={selectedDepartment} 
                onValueChange={setSelectedDepartment}
              >
                <SelectTrigger>
                  <SelectValue placeholder="Select a department" />
                </SelectTrigger>
                <SelectContent>
                  {DEPARTMENTS.map(dept => (
                    <SelectItem key={dept} value={dept}>
                      {dept}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
          )}
        </div>
        
        <DialogFooter>
          <Button variant="outline" onClick={() => setIsOpen(false)}>
            Cancel
          </Button>
          <Button
            onClick={handleAssignRole}
            disabled={assignRoleMutation.isPending || !selectedUserId}
          >
            {assignRoleMutation.isPending ? "Assigning..." : "Assign Role"}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
