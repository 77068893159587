
import { Complaint } from "../types/complaints";
import { debugLog, debugSearch } from "../debug";

export class SearchService {
  /**
   * Search complaints based on a query string
   * @param complaints The list of complaints to search
   * @param query The search query
   * @returns Filtered complaints that match the search query
   */
  static searchComplaints(complaints: Complaint[], query: string): Complaint[] {
    if (!query || query.trim() === "") {
      return complaints;
    }
    
    const searchTerm = query.toLowerCase().trim();
    debugLog("SearchService", `Searching for: "${searchTerm}"`);
    
    const results = complaints.filter(complaint => {
      // Search in various fields
      const matchesTitle = complaint.title.toLowerCase().includes(searchTerm);
      const matchesDescription = complaint.description.toLowerCase().includes(searchTerm);
      const matchesLocation = complaint.location ? 
        complaint.location.toLowerCase().includes(searchTerm) : false;
      const matchesDepartment = complaint.department.toLowerCase().includes(searchTerm);
      const matchesStatus = complaint.status.toLowerCase().includes(searchTerm);
      const matchesSubmitter = complaint.submittedBy.name.toLowerCase().includes(searchTerm);
      
      // Check for ID search (partial matches)
      const matchesId = complaint.id.toLowerCase().includes(searchTerm);
      
      return (
        matchesTitle || 
        matchesDescription || 
        matchesLocation || 
        matchesDepartment || 
        matchesStatus || 
        matchesSubmitter ||
        matchesId
      );
    });
    
    // Log search results statistics
    debugSearch(searchTerm, results.length, complaints.length);
    
    return results;
  }
}
