
import { Complaint } from "@/lib/data";
import { format } from "date-fns";
import { StatusBadge } from "@/components/ui/StatusBadge";

interface ComplaintTimelineProps {
  complaint: Complaint;
}

export function ComplaintTimeline({ complaint }: ComplaintTimelineProps) {
  return (
    <div className="rounded-xl border bg-card shadow-sm p-6 animate-scale-in">
      <h2 className="text-lg font-medium mb-4">Timeline</h2>
      
      <ol className="relative border-l border-muted space-y-4">
        <li className="ml-4">
          <div className="absolute w-3 h-3 bg-primary rounded-full -left-1.5 mt-1.5 border border-background"></div>
          <time className="mb-1 text-sm font-normal leading-none text-muted-foreground">
            {format(new Date(complaint.createdAt), 'PPp')}
          </time>
          <h3 className="text-sm font-medium">Complaint Created</h3>
        </li>
        
        {complaint.status !== 'new' && (
          <li className="ml-4">
            <div className="absolute w-3 h-3 bg-primary rounded-full -left-1.5 mt-1.5 border border-background"></div>
            <time className="mb-1 text-sm font-normal leading-none text-muted-foreground">
              {format(new Date(complaint.updatedAt), 'PPp')}
            </time>
            <h3 className="text-sm font-medium">
              Status updated to {' '}
              <StatusBadge status={complaint.status} className="ml-1" />
            </h3>
          </li>
        )}
      </ol>
    </div>
  );
}
