
import { Complaint } from "@/lib/data";
import { BackLink, ComplaintHeader } from "./ComplaintHeader";
import { ComplaintComments } from "./ComplaintComments";
import { ComplaintStatus } from "./ComplaintStatus";
import { SubmitterInfo } from "./SubmitterInfo";
import { ComplaintTimeline } from "./ComplaintTimeline";
import { LocationInfo } from "./LocationInfo";
import { ComplaintMetadata } from "./ComplaintMetadata";
import { Attachments } from "./Attachments";
import { ComplaintDescription } from "./ComplaintDescription";

interface ComplaintDetailProps {
  complaint: Complaint;
  onStatusChange?: (status: string) => void;
  onAssigneeChange?: (assigneeId: string | null) => void;
  onAddComment?: (text: string) => void;
  isUpdatingStatus?: boolean;
  isAssigning?: boolean;
  isAddingComment?: boolean;
}

export function ComplaintDetail({ 
  complaint, 
  onStatusChange,
  onAssigneeChange,
  onAddComment,
  isUpdatingStatus = false,
  isAssigning = false,
  isAddingComment = false
}: ComplaintDetailProps) {
  return (
    <div className="animate-fade-in">
      <BackLink />
      
      <div className="grid grid-cols-1 gap-6 md:grid-cols-3">
        {/* Main content */}
        <div className="md:col-span-2 space-y-6">
          <ComplaintHeader complaint={complaint} />
          <ComplaintDescription complaint={complaint} />
          <LocationInfo complaint={complaint} />
          <Attachments complaint={complaint} />
          <ComplaintComments 
            complaint={complaint} 
            onAddComment={onAddComment}
            isAddingComment={isAddingComment}
          />
        </div>
        
        {/* Sidebar */}
        <div className="space-y-6">
          <ComplaintStatus 
            complaint={complaint} 
            onStatusChange={onStatusChange}
            onAssigneeChange={onAssigneeChange}
            isUpdatingStatus={isUpdatingStatus}
            isAssigning={isAssigning}
          />
          <SubmitterInfo complaint={complaint} />
          <ComplaintMetadata complaint={complaint} />
          <ComplaintTimeline complaint={complaint} />
        </div>
      </div>
    </div>
  );
}
