
import { useState } from "react";
import { Filter, Check } from "lucide-react";
import { 
  DropdownMenu, 
  DropdownMenuContent, 
  DropdownMenuCheckboxItem, 
  DropdownMenuTrigger,
  DropdownMenuSeparator 
} from "@/components/ui/dropdown-menu";
import { statusOptions, departmentOptions } from "@/lib/types/complaints";

interface ComplaintFilterProps {
  statusFilter: string[];
  departmentFilter: string[];
  setStatusFilter: (value: string[]) => void;
  setDepartmentFilter: (value: string[]) => void;
  resetFilters: () => void;
}

export function ComplaintFilter({
  statusFilter = [],
  departmentFilter = [],
  setStatusFilter,
  setDepartmentFilter,
  resetFilters
}: ComplaintFilterProps) {
  const toggleStatus = (value: string) => {
    setStatusFilter(
      statusFilter.includes(value)
        ? statusFilter.filter(item => item !== value)
        : [...statusFilter, value]
    );
  };

  const toggleDepartment = (value: string) => {
    setDepartmentFilter(
      departmentFilter.includes(value)
        ? departmentFilter.filter(item => item !== value)
        : [...departmentFilter, value]
    );
  };

  const hasActiveFilters = statusFilter.length > 0 || departmentFilter.length > 0;

  return (
    <div className="flex flex-wrap gap-2">
      <DropdownMenu>
        <DropdownMenuTrigger className="inline-flex items-center justify-center rounded-md font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 border border-input bg-background hover:bg-accent hover:text-accent-foreground h-9 px-4 py-2 text-sm">
          <Filter className="mr-2 h-4 w-4" />
          <span>Filters</span>
          {hasActiveFilters && (
            <span className="ml-1 flex h-2 w-2">
              <span className="animate-pulse absolute inline-flex h-2 w-2 rounded-full bg-sky-400"></span>
            </span>
          )}
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-56" align="end">
          <div className="font-medium px-2 py-1.5 text-sm">Status</div>
          {statusOptions.map((option) => (
            <DropdownMenuCheckboxItem
              key={option.value}
              checked={statusFilter.includes(option.value)}
              onCheckedChange={() => toggleStatus(option.value)}
            >
              {option.label}
            </DropdownMenuCheckboxItem>
          ))}
          
          <DropdownMenuSeparator />
          
          <div className="font-medium px-2 py-1.5 text-sm">Department</div>
          {departmentOptions.map((option) => (
            <DropdownMenuCheckboxItem
              key={option.value}
              checked={departmentFilter.includes(option.value)}
              onCheckedChange={() => toggleDepartment(option.value)}
            >
              {option.label}
            </DropdownMenuCheckboxItem>
          ))}
          
          {hasActiveFilters && (
            <>
              <DropdownMenuSeparator />
              <button
                className="w-full flex items-center px-2 py-1.5 text-sm text-muted-foreground hover:bg-accent hover:text-accent-foreground cursor-pointer"
                onClick={resetFilters}
              >
                <Check className="mr-2 h-4 w-4" /> Clear filters
              </button>
            </>
          )}
        </DropdownMenuContent>
      </DropdownMenu>
      
      {hasActiveFilters && (
        <button
          onClick={resetFilters}
          className="inline-flex items-center justify-center rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 bg-secondary text-secondary-foreground hover:bg-secondary/80 h-9 px-4 py-2"
        >
          Clear all filters
        </button>
      )}
    </div>
  );
}
