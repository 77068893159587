
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "sonner";
import { Check, X } from "lucide-react";
import { Button } from "@/components/ui/button";
import { RoleService } from "@/lib/RoleService";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";

interface PendingUsersTableProps {
  users: { id: string; email: string; name: string }[] | undefined;
  isLoading: boolean;
}

export const PendingUsersTable = ({ users, isLoading }: PendingUsersTableProps) => {
  const queryClient = useQueryClient();

  // Mutation for assigning a role
  const assignRoleMutation = useMutation({
    mutationFn: (data: { userId: string; role: "admin" | "staff"; department?: string }) => 
      RoleService.assignRole(data.userId, data.role, data.department),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['user-roles'] });
      queryClient.invalidateQueries({ queryKey: ['available-users'] });
      toast.success("Role assigned successfully");
    },
    onError: (error: Error) => {
      toast.error(error.message || "Failed to assign role");
    }
  });

  if (isLoading) {
    return (
      <div className="flex justify-center p-6">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary"></div>
      </div>
    );
  }

  if (!users || users.length === 0) {
    return (
      <div className="text-center py-6 text-muted-foreground">
        No pending users available
      </div>
    );
  }

  const handleApprove = (userId: string, asAdmin: boolean) => {
    assignRoleMutation.mutate({
      userId,
      role: asAdmin ? "admin" : "staff",
      department: asAdmin ? undefined : "General"
    });
  };

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>User</TableHead>
          <TableHead>Email</TableHead>
          <TableHead className="text-right">Actions</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {users.map((user) => (
          <TableRow key={user.id}>
            <TableCell className="font-medium">{user.name || "Unnamed User"}</TableCell>
            <TableCell>{user.email || "No email"}</TableCell>
            <TableCell className="text-right">
              <div className="flex justify-end gap-2">
                <Button
                  variant="outline"
                  size="sm"
                  onClick={() => handleApprove(user.id, false)}
                  disabled={assignRoleMutation.isPending}
                  className="flex items-center gap-1"
                >
                  <Check className="h-4 w-4 text-green-600" />
                  As Staff
                </Button>
                <Button
                  variant="outline"
                  size="sm"
                  onClick={() => handleApprove(user.id, true)}
                  disabled={assignRoleMutation.isPending}
                  className="flex items-center gap-1"
                >
                  <Check className="h-4 w-4 text-purple-600" />
                  As Admin
                </Button>
              </div>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
