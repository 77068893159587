
import { useState, useEffect } from "react";
import { 
  ClipboardList, 
  Clock, 
  CheckCircle, 
  XCircle,
} from "lucide-react";
import { StatCard } from "./stats/StatCard";
import { ChartContainer } from "./stats/ChartContainer";
import { KeyInsights } from "./stats/KeyInsights";

interface DashboardStatsProps {
  stats?: ReturnType<typeof import("@/lib/utils/statsCalculator").calculateStats>;
  isLoading?: boolean;
}

export function DashboardStats({ stats, isLoading = false }: DashboardStatsProps) {
  const [animationActive, setAnimationActive] = useState(false);
  
  useEffect(() => {
    setAnimationActive(true);
  }, []);
  
  console.log("DashboardStats stats:", stats);
  
  const departmentChartData = stats 
    ? [
        { name: "Water", value: stats.byDepartment["water"] || 0 },
        { name: "Electricity", value: stats.byDepartment["electricity"] || 0 },
        { name: "Roads", value: stats.byDepartment["roads"] || 0 },
        { name: "Sanitation", value: stats.byDepartment["sanitation"] || 0 },
        { name: "Education", value: stats.byDepartment["education"] || 0 },
        { name: "Healthcare", value: stats.byDepartment["healthcare"] || 0 },
        { name: "Other", value: stats.byDepartment["other"] || 0 },
      ].filter(item => item.value > 0)
    : [];
    
  const statusChartData = stats
    ? [
        { name: "New", value: stats.new || 0, color: "hsl(var(--status-new))" },
        { name: "In Progress", value: stats.inProgress || 0, color: "hsl(var(--status-in-progress))" },
        { name: "Resolved", value: stats.resolved || 0, color: "hsl(var(--status-resolved))" },
        { name: "Rejected", value: stats.rejected || 0, color: "hsl(var(--status-rejected))" },
      ].filter(item => item.value > 0)
    : [];
    
  const trendData = [
    { day: 'Mon', complaints: 5, resolved: 3 },
    { day: 'Tue', complaints: 7, resolved: 4 },
    { day: 'Wed', complaints: 10, resolved: 5 },
    { day: 'Thu', complaints: 8, resolved: 6 },
    { day: 'Fri', complaints: 12, resolved: 7 },
    { day: 'Sat', complaints: 6, resolved: 3 },
    { day: 'Sun', complaints: 4, resolved: 2 },
  ];

  return (
    <div className="space-y-6 mb-8 animate-fade-in">
      <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-4">
        <StatCard
          title="Total Complaints"
          value={stats?.total || 0}
          icon={<ClipboardList className="h-5 w-5" />}
          isLoading={isLoading}
          className="analytics-card-purple"
          iconColor="text-analytics-purple"
        />
        <StatCard
          title="New"
          value={stats?.new || 0}
          icon={<ClipboardList className="h-5 w-5 text-status-new" />}
          className="bg-status-new/5 border-status-new/10"
          isLoading={isLoading}
          iconColor="text-status-new"
        />
        <StatCard
          title="In Progress"
          value={stats?.inProgress || 0}
          icon={<Clock className="h-5 w-5 text-status-in-progress" />}
          className="bg-status-in-progress/5 border-status-in-progress/10"
          isLoading={isLoading}
          iconColor="text-status-in-progress"
        />
        <StatCard
          title="Resolved"
          value={stats?.resolved || 0}
          icon={<CheckCircle className="h-5 w-5 text-status-resolved" />}
          description={stats ? `${stats.resolutionRate}% resolution rate` : undefined}
          className="bg-status-resolved/5 border-status-resolved/10"
          isLoading={isLoading}
          iconColor="text-status-resolved"
        />
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        <ChartContainer 
          departmentChartData={departmentChartData}
          statusChartData={statusChartData}
          trendData={trendData}
          isLoading={isLoading}
          animationActive={animationActive}
        />
        
        <div className="rounded-xl bg-card p-6 shadow-sm border animate-slide-in">
          <h3 className="text-sm font-medium text-muted-foreground mb-4">Key Insights</h3>
          <KeyInsights stats={stats} isLoading={isLoading} />
        </div>
      </div>
    </div>
  );
}
