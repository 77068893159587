
import { MapPin } from 'lucide-react';
import { Complaint } from '@/lib/types/complaints';
import { Badge } from '@/components/ui/badge';
import { Card, CardContent } from '@/components/ui/card';
import { ScrollArea } from '@/components/ui/scroll-area';
import { useNavigate } from 'react-router-dom';

interface ComplaintLocationsListProps {
  complaints: Complaint[];
}

export function ComplaintLocationsList({ complaints }: ComplaintLocationsListProps) {
  const navigate = useNavigate();
  
  // Filter complaints with location data
  const complaintsWithLocation = complaints.filter(
    complaint => complaint.location || (complaint.latitude && complaint.longitude)
  );

  if (complaintsWithLocation.length === 0) {
    return (
      <Card>
        <CardContent className="p-4">
          <div className="text-center py-8 text-muted-foreground">
            <p>No complaints with location data found.</p>
          </div>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card className="h-full">
      <CardContent className="p-0">
        <h3 className="font-medium p-4 border-b">Complaint Locations</h3>
        <ScrollArea className="h-[500px]">
          <div className="divide-y">
            {complaintsWithLocation.map((complaint) => (
              <div
                key={complaint.id}
                className="p-4 hover:bg-accent cursor-pointer"
                onClick={() => navigate(`/complaint/${complaint.id}`)}
              >
                <div className="flex items-start gap-3">
                  <MapPin className="h-5 w-5 text-muted-foreground mt-0.5 flex-shrink-0" />
                  <div>
                    <div className="flex items-center gap-2 mb-1">
                      <span className="font-medium text-sm truncate">
                        {complaint.title}
                      </span>
                      <Badge
                        variant={
                          complaint.status === 'resolved'
                            ? 'secondary'
                            : complaint.status === 'in-progress'
                            ? 'default'
                            : complaint.status === 'rejected'
                            ? 'destructive'
                            : 'outline'
                        }
                        className="ml-auto flex-shrink-0"
                      >
                        {complaint.status}
                      </Badge>
                    </div>
                    <p className="text-xs text-muted-foreground mb-1">
                      {complaint.location || 'No address specified'}
                    </p>
                    <div className="flex items-center justify-between text-xs">
                      <span>{complaint.department}</span>
                      <span className="text-muted-foreground">
                        {new Date(complaint.createdAt).toLocaleDateString()}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </ScrollArea>
      </CardContent>
    </Card>
  );
}
