
import { Button } from "@/components/ui/button";
import { LayoutGrid, List, Kanban } from "lucide-react";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";

interface ViewSelectorProps {
  activeView: string;
  onViewChange: (view: string) => void;
}

export function ViewSelector({ activeView, onViewChange }: ViewSelectorProps) {
  return (
    <TooltipProvider delayDuration={300}>
      <div className="flex items-center border rounded-md overflow-hidden">
        <Tooltip>
          <TooltipTrigger asChild>
            <Button
              variant={activeView === "grid" ? "default" : "ghost"}
              size="sm"
              className="h-9 px-3 rounded-none border-0"
              onClick={() => onViewChange("grid")}
            >
              <LayoutGrid className="h-4 w-4" />
              <span className="sr-only">Grid View</span>
            </Button>
          </TooltipTrigger>
          <TooltipContent>Grid View</TooltipContent>
        </Tooltip>
        
        <Tooltip>
          <TooltipTrigger asChild>
            <Button
              variant={activeView === "list" ? "default" : "ghost"}
              size="sm"
              className="h-9 px-3 rounded-none border-0"
              onClick={() => onViewChange("list")}
            >
              <List className="h-4 w-4" />
              <span className="sr-only">List View</span>
            </Button>
          </TooltipTrigger>
          <TooltipContent>List View</TooltipContent>
        </Tooltip>
        
        <Tooltip>
          <TooltipTrigger asChild>
            <Button
              variant={activeView === "kanban" ? "default" : "ghost"}
              size="sm"
              className="h-9 px-3 rounded-none border-0"
              onClick={() => onViewChange("kanban")}
            >
              <Kanban className="h-4 w-4" />
              <span className="sr-only">Kanban View</span>
            </Button>
          </TooltipTrigger>
          <TooltipContent>Kanban View</TooltipContent>
        </Tooltip>
      </div>
    </TooltipProvider>
  );
}
