
import { UserRoleService } from './services/UserRoleService';
import { AdminService } from './services/AdminService';
import { UserRoleData } from './types/userRoles';
import { BaseService } from './services/BaseService';
import { Database } from "@/integrations/supabase/types";

type AppRole = Database["public"]["Enums"]["app_role"];

export class RoleService extends BaseService {
  // Proxy calls to the appropriate service
  static async assignRole(userId: string, role: AppRole, department?: string) {
    // Forward to AdminService if it has the method, otherwise handle here
    if (typeof AdminService.assignRole === 'function') {
      return AdminService.assignRole(userId, role, department);
    }
    console.warn('AdminService.assignRole not implemented');
    return false;
  }
  
  static async getUserRoles() {
    return UserRoleService.getUserRoles();
  }
  
  static async getUserRolesByEmail(email: string) {
    return UserRoleService.getUserRoleByEmail(email);
  }
  
  // Add missing methods referenced in other components
  static async getAllUserRoles() {
    return UserRoleService.getUserRoles();
  }
  
  static async ensureUserIsAdmin(email?: string) {
    if (!email) return false;
    return AdminService.ensureUserIsAdmin(email);
  }
  
  static async removeRole(roleId: string) {
    try {
      const { error } = await this.supabase
        .from('user_roles')
        .delete()
        .eq('id', roleId);
        
      if (error) {
        console.error('Error removing role:', error);
        return false;
      }
      
      return true;
    } catch (error) {
      console.error('Error in removeRole:', error);
      return false;
    }
  }
  
  static async getAvailableUsers() {
    try {
      // First, get all profiles
      const { data: profiles, error: profilesError } = await this.supabase
        .from('profiles')
        .select('id, name, email');
        
      if (profilesError) {
        console.error('Error fetching profiles:', profilesError);
        return [];
      }
      
      // Then get all user IDs that already have roles
      const { data: userRoles, error: rolesError } = await this.supabase
        .from('user_roles')
        .select('user_id');
        
      if (rolesError) {
        console.error('Error fetching user roles:', rolesError);
        return [];
      }
      
      // Create a Set of user IDs that already have roles for faster lookup
      const userIdsWithRoles = new Set(userRoles.map(role => role.user_id));
      
      // Filter out profiles that already have roles
      const availableUsers = profiles.filter(profile => !userIdsWithRoles.has(profile.id));
      
      return availableUsers.map(profile => ({
        id: profile.id,
        name: profile.name || 'Unknown',
        email: profile.email || 'No email'
      }));
    } catch (error) {
      console.error('Error in getAvailableUsers:', error);
      return [];
    }
  }
}

export type { UserRoleData };
export default RoleService;
