
import { FetchCommentsService } from "./FetchCommentsService";
import { AddCommentService } from "./AddCommentService";
import { Comment } from "../../types/comments";

export class CommentService {
  static async getComments(complaintId: string): Promise<Comment[]> {
    return FetchCommentsService.getComments(complaintId);
  }
  
  static async addComment(complaintId: string, comment: { text: string, authorId: string }): Promise<Comment | null> {
    return AddCommentService.addComment(complaintId, comment);
  }
}
