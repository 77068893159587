import { useState, useEffect } from "react";
import { useParams, Navigate, useLocation } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { SupabaseService } from "@/lib/SupabaseService";
import { Comment } from "@/lib/data";
import { ComplaintDetail } from "@/components/dashboard/complaint-detail";
import { toast } from "sonner";
import { useAuth } from "@/lib/AuthContext";
import { DashboardLayout } from "@/components/layout/DashboardLayout";
import { debugLog, debugError } from "@/lib/debug";

const ComplaintView = () => {
  const { complaintId } = useParams<{ complaintId: string }>();
  const { user } = useAuth();
  const queryClient = useQueryClient();
  const [commentText, setCommentText] = useState("");
  const location = useLocation();
  
  // Log navigation events
  useEffect(() => {
    debugLog("ComplaintView", `Component mounted with ID: ${complaintId}`, {
      pathname: location.pathname,
      complaintId
    });
    
    // Cleanup log
    return () => {
      debugLog("ComplaintView", `Component unmounting for ID: ${complaintId}`);
    };
  }, [complaintId, location]);
  
  // Fetch the complaint data
  const { 
    data: complaint, 
    isLoading,
    error,
    isError
  } = useQuery({
    queryKey: ['complaint', complaintId],
    queryFn: () => complaintId ? SupabaseService.getComplaintById(complaintId) : null,
    enabled: !!complaintId,
    meta: {
      onSuccess: (data) => {
        // Add detailed logging to debug issues
        debugLog("ComplaintView", `Complaint data fetched successfully for ID: ${complaintId}`, data);
        if (data) {
          debugLog("ComplaintView", "Location fields:", {
            location: data.location,
            google_maps_link: data.google_maps_link,
            latitude: data.latitude,
            longitude: data.longitude
          });
          debugLog("ComplaintView", "Submitter info:", {
            name: data.submittedBy?.name,
            email: data.submittedBy?.email,
            phone: data.submittedBy?.phone,
            whatsapp_user_name: data.whatsapp_user_name,
            whatsapp_user_phone: data.whatsapp_user_phone
          });
        } else {
          debugError("ComplaintView", `Fetched null data for ID: ${complaintId}`);
        }
      },
      onError: (err) => {
        debugError("ComplaintView", `Error fetching complaint with ID: ${complaintId}`, err);
      }
    }
  });
  
  // Mutation for updating complaint status
  const statusMutation = useMutation({
    mutationFn: (newStatus: string) => 
      complaintId ? SupabaseService.updateStatus(complaintId, newStatus) : Promise.resolve(null),
    onSuccess: () => {
      if (complaintId) {
        queryClient.invalidateQueries({ queryKey: ['complaint', complaintId] });
        queryClient.invalidateQueries({ queryKey: ['complaints'] });
        toast.success("Status updated successfully");
      }
    },
    onError: (error: Error) => {
      toast.error(error.message || "Failed to update status");
    }
  });
  
  // Mutation for assigning complaint
  const assignMutation = useMutation({
    mutationFn: (assigneeId: string | null) => 
      complaintId ? SupabaseService.assignComplaint(complaintId, assigneeId) : Promise.resolve(null),
    onSuccess: () => {
      if (complaintId) {
        queryClient.invalidateQueries({ queryKey: ['complaint', complaintId] });
        queryClient.invalidateQueries({ queryKey: ['complaints'] });
        toast.success("Complaint assigned successfully");
      }
    },
    onError: (error: Error) => {
      toast.error(error.message || "Failed to assign complaint");
    }
  });
  
  // Mutation for adding comments
  const commentMutation = useMutation({
    mutationFn: (commentData: { text: string, authorId: string }) => 
      complaintId ? SupabaseService.addComment(complaintId, commentData) : Promise.resolve(null),
    onSuccess: (newComment: Comment | null) => {
      if (complaintId) {
        queryClient.invalidateQueries({ queryKey: ['complaint', complaintId] });
        queryClient.invalidateQueries({ queryKey: ['comments', complaintId] });
        setCommentText("");
        toast.success("Comment added successfully");
      }
    },
    onError: (error: Error) => {
      toast.error(error.message || "Failed to add comment");
    }
  });
  
  // Handle status change
  const handleStatusChange = (newStatus: string) => {
    statusMutation.mutate(newStatus);
  };
  
  // Handle assignee change
  const handleAssigneeChange = (assigneeId: string | null) => {
    assignMutation.mutate(assigneeId);
  };
  
  // Handle adding a comment
  const handleAddComment = (text: string) => {
    if (!text.trim() || !user?.id) return;
    
    commentMutation.mutate({
      text: text,
      authorId: user.id
    });
  };
  
  // Redirect if no complaint ID
  if (!complaintId) {
    debugLog("ComplaintView", "No complaint ID found, redirecting to home");
    return <Navigate to="/" replace />;
  }
  
  if (isLoading) {
    debugLog("ComplaintView", `Loading complaint data for ID: ${complaintId}`);
    return (
      <DashboardLayout>
        <div className="container py-8 animate-fade-in">
          <div className="flex flex-col space-y-4 md:space-y-0 md:flex-row md:space-x-4">
            <div className="w-full md:w-2/3 space-y-4">
              <div className="h-8 w-48 bg-muted animate-pulse rounded"></div>
              <div className="h-12 w-full bg-muted animate-pulse rounded"></div>
              <div className="h-4 w-32 bg-muted animate-pulse rounded"></div>
              <div className="h-48 w-full bg-muted animate-pulse rounded"></div>
            </div>
            <div className="w-full md:w-1/3 space-y-4">
              <div className="h-48 w-full bg-muted animate-pulse rounded"></div>
              <div className="h-48 w-full bg-muted animate-pulse rounded"></div>
            </div>
          </div>
        </div>
      </DashboardLayout>
    );
  }
  
  if (isError || !complaint) {
    debugError("ComplaintView", `Error or null complaint for ID: ${complaintId}`, error);
    return (
      <DashboardLayout>
        <div className="container py-8">
          <div className="text-center py-12">
            <h2 className="text-2xl font-bold text-destructive mb-2">Error Loading Complaint</h2>
            <p className="text-muted-foreground mb-4">
              {error instanceof Error ? error.message : "Could not load the complaint details. Please try again."}
            </p>
            <a href="/" className="text-primary hover:underline">Back to Dashboard</a>
          </div>
        </div>
      </DashboardLayout>
    );
  }

  debugLog("ComplaintView", `Rendering complaint details for ID: ${complaintId}`, {
    title: complaint.title,
    status: complaint.status
  });
  
  return (
    <DashboardLayout>
      <div className="container py-8">
        <ComplaintDetail 
          complaint={complaint}
          onStatusChange={handleStatusChange}
          onAssigneeChange={handleAssigneeChange}
          onAddComment={handleAddComment}
          isUpdatingStatus={statusMutation.isPending}
          isAssigning={assignMutation.isPending}
          isAddingComment={commentMutation.isPending}
        />
      </div>
    </DashboardLayout>
  );
};

export default ComplaintView;
