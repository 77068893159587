
import { supabase } from "@/integrations/supabase/client";
import { BaseService } from "./BaseService";
import { Database } from "@/integrations/supabase/types";

type AppRole = Database["public"]["Enums"]["app_role"];

export class AdminService extends BaseService {
  /**
   * Ensures a specific user has admin privileges
   */
  static async ensureUserIsAdmin(email: string): Promise<boolean> {
    try {
      console.log(`Attempting to ensure admin privileges for email: ${email}`);
      
      // Find the user by email in profiles
      const { data: profileData, error: profileError } = await supabase
        .from('profiles')
        .select('id, email')
        .eq('email', email)
        .single();

      if (profileError) {
        console.error("Error finding user profile:", profileError);
        
        // If the profile doesn't exist, check auth.users
        // This is handled by getting the current session
        const { data: { session } } = await supabase.auth.getSession();
        
        if (!session || session.user.email !== email) {
          console.error("Cannot create profile: user not found or not authenticated");
          return false;
        }
        
        const userId = session.user.id;
        
        // Create a profile for this user if it doesn't exist
        const { data: newProfile, error: createProfileError } = await supabase
          .from('profiles')
          .insert({
            id: userId,
            email: email,
            created_at: new Date().toISOString()
          })
          .select('id')
          .single();
        
        if (createProfileError) {
          console.error("Error creating user profile:", createProfileError);
          return false;
        }
        
        // Set the admin role for this new profile
        const { error: newRoleError } = await supabase
          .from('user_roles')
          .insert({
            user_id: userId,
            role: 'admin' as AppRole
          });
        
        if (newRoleError) {
          console.error("Error setting admin role for new profile:", newRoleError);
          return false;
        }
        
        console.log("Created new profile and set admin role for:", email);
        return true;
      }

      if (!profileData) {
        console.error("User profile not found for email:", email);
        return false;
      }
      
      console.log("Found user profile for:", email, "with ID:", profileData.id);

      // Check if user already has an admin role
      const { data: existingRole, error: roleError } = await supabase
        .from('user_roles')
        .select('id, role')
        .eq('user_id', profileData.id)
        .single();
      
      if (roleError && roleError.code !== 'PGRST116') {
        console.error("Error checking existing role:", roleError);
        // Continue to attempt to set role
      }

      if (existingRole && existingRole.role === 'admin') {
        console.log("User already has admin role:", email);
        return true;
      }

      // Upsert the admin role for this user
      const { error: upsertError } = await supabase
        .from('user_roles')
        .upsert({
          user_id: profileData.id,
          role: 'admin' as AppRole
        }, {
          onConflict: 'user_id'
        });

      if (upsertError) {
        console.error("Error setting admin role:", upsertError);
        return false;
      }

      console.log("Successfully ensured admin role for user:", email);
      return true;
    } catch (error) {
      console.error("Error in ensureUserIsAdmin:", error);
      return false;
    }
  }

  static async assignRole(userId: string, role: AppRole, department?: string) {
    try {
      // Check if the user already has a role
      const { data: existingRole, error: fetchError } = await this.supabase
        .from('user_roles')
        .select('*')
        .eq('user_id', userId)
        .single();
      
      if (fetchError && fetchError.code !== 'PGRST116') {
        console.error('Error checking existing role:', fetchError);
        return false;
      }
      
      // If user already has a role, update it
      if (existingRole) {
        const { error: updateError } = await this.supabase
          .from('user_roles')
          .update({ 
            role, 
            department: department || null,
          })
          .eq('id', existingRole.id);
        
        if (updateError) {
          console.error('Error updating role:', updateError);
          return false;
        }
        
        return true;
      }
      
      // Otherwise, create a new role
      const { error: insertError } = await this.supabase
        .from('user_roles')
        .insert({ 
          user_id: userId, 
          role,
          department: department || null,
        });
      
      if (insertError) {
        console.error('Error creating role:', insertError);
        return false;
      }
      
      return true;
    } catch (error) {
      console.error('Error in assignRole:', error);
      return false;
    }
  }
}
