
import { Complaint } from "@/lib/types/complaints";
import { formatDistanceToNow } from "date-fns";
import { User, MapPin, MessageCircle, Phone } from "lucide-react";
import { StatusBadge } from "@/components/ui/StatusBadge";
import { Badge } from "@/components/ui/badge";
import { Link } from "react-router-dom";
import { cn } from "@/lib/utils";
import { debugLog } from "@/lib/debug";

interface ListViewProps {
  complaints: Complaint[];
}

export function ListView({ complaints }: ListViewProps) {
  // Ensure complaints is always an array
  const safeComplaints = Array.isArray(complaints) ? complaints : [];
  
  // Log list view rendering
  debugLog("ListView", `Rendering list view with ${safeComplaints.length} complaints`);

  if (safeComplaints.length === 0) {
    return <div className="py-10 text-center text-muted-foreground">No complaints to display</div>;
  }

  return (
    <div className="overflow-hidden rounded-md border">
      <div className="bg-muted/50 p-3">
        <div className="grid grid-cols-12 gap-3 text-xs font-medium text-muted-foreground">
          <div className="col-span-4 md:col-span-5">Title</div>
          <div className="col-span-2 hidden md:block">Department</div>
          <div className="col-span-2 hidden md:block">Location</div>
          <div className="col-span-2">Status</div>
          <div className="col-span-4 md:col-span-1">Created</div>
        </div>
      </div>
      <div className="divide-y">
        {safeComplaints.map((complaint) => {
          const isWhatsAppComplaint = complaint.source === 'whatsapp_bot';
          
          return (
            <Link
              key={complaint.id}
              to={`/complaint/${complaint.id}`}
              className="block hover:bg-muted/50 transition-colors"
            >
              <div className={cn(
                "grid grid-cols-12 gap-3 p-3",
                isWhatsAppComplaint ? "bg-gradient-to-r from-green-50 to-transparent" : ""
              )}>
                <div className="col-span-4 md:col-span-5">
                  <div className="flex items-start gap-2">
                    {isWhatsAppComplaint && (
                      <MessageCircle className="h-4 w-4 text-green-600 flex-shrink-0 mt-0.5" />
                    )}
                    <div>
                      <div className="font-medium truncate">{complaint.title}</div>
                      <div className="text-xs text-muted-foreground line-clamp-1">
                        {isWhatsAppComplaint && complaint.whatsapp_user_name ? (
                          <span className="flex items-center gap-1 text-green-700">
                            <User className="h-3 w-3" /> {complaint.whatsapp_user_name}
                            {complaint.whatsapp_user_phone && (
                              <>
                                <span className="mx-1">•</span>
                                <Phone className="h-3 w-3" /> {complaint.whatsapp_user_phone}
                              </>
                            )}
                          </span>
                        ) : (
                          <span>{complaint.submittedBy?.name || 'Unknown'}</span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-span-2 hidden md:flex items-center">
                  <span className="truncate capitalize">
                    {complaint.department}
                  </span>
                </div>
                <div className="col-span-2 hidden md:flex items-center">
                  {complaint.location ? (
                    <div className="flex items-center gap-1">
                      <MapPin className="h-3 w-3 text-muted-foreground" />
                      <span className="truncate">{complaint.location}</span>
                    </div>
                  ) : (
                    <span className="text-muted-foreground italic">No location</span>
                  )}
                </div>
                <div className="col-span-2 flex items-center">
                  <StatusBadge status={complaint.status} />
                </div>
                <div className="col-span-4 md:col-span-1 flex items-center text-xs">
                  {formatDistanceToNow(new Date(complaint.createdAt), { addSuffix: true })}
                </div>
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  );
}
