import { BarChart, Bar, ResponsiveContainer, Tooltip } from "recharts";
import { CustomTooltip } from "./CustomTooltip";
import { CustomXAxis } from "./RechartsWrappers";

interface DepartmentChartProps {
  data: Array<{ name: string; value: number }>;
  isLoading: boolean;
  animationActive: boolean;
}

export function DepartmentChart({ data, isLoading, animationActive }: DepartmentChartProps) {
  if (isLoading) {
    return (
      <div className="h-full flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-primary"></div>
      </div>
    );
  }
  
  if (data.length === 0) {
    return (
      <div className="h-full flex items-center justify-center text-muted-foreground">
        No data available
      </div>
    );
  }
  
  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart 
        data={data} 
        margin={{ top: 10, right: 10, left: 10, bottom: 20 }}
        barGap={8}
      >
        <CustomXAxis 
          dataKey="name" 
          fontSize={12}
          tick={{ fill: 'hsl(var(--muted-foreground))' }}
        />
        <Tooltip 
          content={<CustomTooltip />}
          cursor={{ fill: 'rgba(0, 0, 0, 0.05)' }}
        />
        <Bar 
          dataKey="value" 
          name="Complaints"
          fill="hsl(var(--analytics-purple))" 
          radius={[4, 4, 0, 0]}
          animationDuration={1500}
          animationBegin={animationActive ? 0 : 2000}
        />
      </BarChart>
    </ResponsiveContainer>
  );
}
