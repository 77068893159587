import { useState, useEffect } from "react";
import { Complaint } from "@/lib/types/complaints";
import { ComplaintListLogic } from "./ComplaintListLogic";
import { ScrollArea } from "@/components/ui/scroll-area";
import { debugLog } from "@/lib/debug";
import { RefreshCw } from "lucide-react";
import { motion } from "framer-motion";

interface ComplaintListProps {
  complaints: Complaint[];
  isLoading?: boolean;
  searchQuery?: string;
  searchResults?: Complaint[];
  isSearching?: boolean;
  onClearSearch?: () => void;
}

export function ComplaintList({ 
  complaints, 
  isLoading = false,
  searchQuery = "",
  searchResults = [],
  isSearching = false,
  onClearSearch = () => {}
}: ComplaintListProps) {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isDataReady, setIsDataReady] = useState(false);

  // Ensure complaints is always an array
  const safeComplaints = Array.isArray(complaints) ? complaints : [];

  // Log the complaints we receive
  useEffect(() => {
    debugLog("ComplaintList", `Received ${safeComplaints.length} complaints`, { 
      isLoading, 
      complaintsCount: safeComplaints.length,
      firstComplaintId: safeComplaints[0]?.id || 'none'
    });
    
    // Set data ready after a short delay to allow for animation
    if (!isLoading && safeComplaints.length > 0) {
      const timer = setTimeout(() => {
        setIsDataReady(true);
      }, 300);
      return () => clearTimeout(timer);
    }
  }, [safeComplaints, isLoading]);

  // Add scroll effect for animations
  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      setIsScrolled(scrollTop > 50);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  if (isLoading) {
    return (
      <div className="h-64 flex flex-col items-center justify-center">
        <RefreshCw className="h-10 w-10 animate-spin text-primary/70 mb-4" />
        <p className="text-muted-foreground animate-pulse">Loading complaints...</p>
      </div>
    );
  }

  return (
    <motion.div 
      className={`transition-all duration-300 ${isScrolled ? 'pt-2' : 'pt-0'}`}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
    >
      <ScrollArea className="h-full">
        <ComplaintListLogic 
          complaints={safeComplaints} 
          isLoading={isLoading}
          searchQuery={searchQuery}
          searchResults={searchResults}
          isSearching={isSearching} 
        />
      </ScrollArea>
    </motion.div>
  );
}
