import { ReactNode } from "react";
import { RoleProtectedRoute } from "./RoleProtectedRoute";
import { useUserRole, UserRole } from "@/lib/UserRoleContext";

interface ProtectedRouteProps {
  children: ReactNode;
  requiredRoles?: UserRole[];
}

export const ProtectedRoute = ({ 
  children, 
  requiredRoles = ['admin', 'staff'] 
}: ProtectedRouteProps) => {
  return (
    <RoleProtectedRoute allowedRoles={requiredRoles}>
      {children}
    </RoleProtectedRoute>
  );
};
