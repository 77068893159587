import { Complaint } from "@/lib/types/complaints";
import { EmptyState } from "./EmptyState";
import { debugLog } from "@/lib/debug";
import { useEffect } from "react";
import { Button } from "@/components/ui/button";
import { RefreshCw } from "lucide-react";

interface EmptyStateHandlerProps {
  isLoading: boolean;
  isSearching: boolean;
  complaints: Complaint[];
  filteredComplaints: Complaint[];
  searchResults: Complaint[];
  searchQuery: string;
  activeFilter: string;
  hasActiveFilters: boolean;
  resetFilters: () => void;
}

export function EmptyStateHandler({
  isLoading,
  isSearching,
  complaints = [],
  filteredComplaints = [],
  searchResults = [],
  searchQuery = "",
  activeFilter,
  hasActiveFilters,
  resetFilters
}: EmptyStateHandlerProps) {
  // Ensure all arrays are valid
  const safeComplaints = Array.isArray(complaints) ? complaints : [];
  const safeFilteredComplaints = Array.isArray(filteredComplaints) ? filteredComplaints : [];
  const safeSearchResults = Array.isArray(searchResults) ? searchResults : [];
  
  // Log empty state check with useEffect to avoid excessive logging
  useEffect(() => {
    debugLog("EmptyStateHandler", "Checking empty states", {
      hasNoComplaints: safeComplaints.length === 0,
      hasNoFilteredComplaints: safeFilteredComplaints.length === 0 && safeComplaints.length > 0,
      hasNoSearchResults: isSearching && safeSearchResults.length === 0,
      complaintsLength: safeComplaints.length,
      filteredLength: safeFilteredComplaints.length,
      searchResultsLength: safeSearchResults.length,
      activeFilter,
      hasActiveFilters
    });
  }, [
    safeComplaints, 
    safeFilteredComplaints, 
    safeSearchResults, 
    isSearching, 
    activeFilter, 
    hasActiveFilters
  ]);

  // Don't show empty state during loading
  if (isLoading) {
    return (
      <div className="flex justify-center items-center py-20">
        <RefreshCw className="h-8 w-8 animate-spin text-muted-foreground" />
        <span className="ml-2 text-muted-foreground">Loading complaints...</span>
      </div>
    );
  }

  // No complaints at all
  if (safeComplaints.length === 0) {
    return <EmptyState type="no-complaints" />;
  }

  // Has complaints, but none match the filter
  if (safeFilteredComplaints.length === 0 && safeComplaints.length > 0) {
    return (
      <div className="py-10 text-center">
        <EmptyState
          type="no-filtered-results"
          filter={activeFilter}
          hasActiveFilters={hasActiveFilters}
          resetFilters={resetFilters}
        />
        {hasActiveFilters && (
          <Button 
            variant="outline" 
            className="mt-4" 
            onClick={resetFilters}
          >
            Clear All Filters
          </Button>
        )}
      </div>
    );
  }

  // No search results
  if (isSearching && safeSearchResults.length === 0) {
    return (
      <div className="py-10 text-center">
        <EmptyState type="no-search-results" query={searchQuery} />
        <p className="text-sm text-muted-foreground mt-2">
          Try using different keywords or check for spelling errors.
        </p>
      </div>
    );
  }

  // If we have filtered complaints to show, don't show empty state
  return null;
}
