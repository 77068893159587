import { useState, useEffect } from 'react';
import { env } from '../env';

interface MediaUrlCache {
  objectUrl: string;
  expiresAt: number; // Timestamp when URL expires
}

interface MediaState {
  isLoading: boolean;
  url: string | null;
  error: Error | null;
}

/**
 * Custom hook to handle WhatsApp media URLs according to the WhatsApp Cloud API documentation.
 * Uses the WhatsApp bot server to retrieve media and manages URL expiration.
 */
export function useWhatsAppMedia(originalUrl: string | undefined) {
  const [mediaState, setMediaState] = useState<MediaState>({
    isLoading: true,
    url: null,
    error: null,
  });
  
  // In-memory cache for media URLs to avoid unnecessary API calls
  const [mediaCache, setMediaCache] = useState<Record<string, MediaUrlCache>>({});
  
  // Get access token from validated environment variables
  const accessToken = env.VITE_FACEBOOK_ACCESS_TOKEN;
  
  // Log access token status (but not the token itself for security)
  useEffect(() => {
    if (!accessToken) {
      console.warn('WhatsApp media access token is not set in environment variables');
    }
  }, []);

  // Cleanup function for object URLs
  useEffect(() => {
    return () => {
      // Cleanup all object URLs when the component unmounts
      Object.values(mediaCache).forEach(cache => {
        if (cache.objectUrl && cache.objectUrl.startsWith('blob:')) {
          URL.revokeObjectURL(cache.objectUrl);
        }
      });
    };
  }, [mediaCache]);

  useEffect(() => {
    if (!originalUrl) {
      setMediaState({
        isLoading: false,
        url: null,
        error: new Error('No URL provided'),
      });
      return;
    }

    // If it's not a WhatsApp/Facebook URL, use it directly
    if (!(originalUrl.includes('facebook.com') || originalUrl.includes('fbsbx.com'))) {
      setMediaState({
        isLoading: false,
        url: originalUrl,
        error: null,
      });
      return;
    }

    // If no access token, we can't proceed with WhatsApp media
    if (!accessToken) {
      setMediaState({
        isLoading: false,
        url: originalUrl, // Fall back to original URL
        error: new Error('No access token available for WhatsApp media'),
      });
      return;
    }

    const fetchMediaUrl = async () => {
      try {
        setMediaState(prev => ({ ...prev, isLoading: true }));
        
        // Extract media ID from URL
        const mediaIdMatch = originalUrl.match(/mid=(\d+)/) || 
                            originalUrl.match(/\/(\d+)\/$/);
        
        if (!mediaIdMatch || !mediaIdMatch[1]) {
          // If no media ID found, use original URL as fallback
          setMediaState({
            isLoading: false,
            url: originalUrl,
            error: null,
          });
          return;
        }

        const mediaId = mediaIdMatch[1];
        
        // Check if we have a cached URL that hasn't expired
        const cachedMedia = mediaCache[mediaId];
        const now = Date.now();
        
        if (cachedMedia && cachedMedia.expiresAt > now) {
          // Use cached URL if it's still valid
          setMediaState({
            isLoading: false,
            url: cachedMedia.objectUrl,
            error: null,
          });
          return;
        }

        // Use the WhatsApp bot server to get the media
        console.log(`Fetching media for ID: ${mediaId}`);
        
        // Construct the URL to the WhatsApp bot server media endpoint
        const proxyUrl = `https://bot.jansamadhan.in/api/whatsapp/media/${mediaId}`;
        
        // Fetch the media from the bot server with a timeout
        const controller = new AbortController();
        const timeoutId = setTimeout(() => controller.abort(), 15000); // 15 second timeout
        
        try {
          const response = await fetch(proxyUrl, { 
            signal: controller.signal,
          });
          
          clearTimeout(timeoutId);
          
          if (!response.ok) {
            const errorData = await response.json().catch(() => ({ error: response.statusText }));
            throw new Error(`Failed to retrieve media: ${errorData.error || response.statusText}`);
          }
          
          // Create a blob URL from the response
          const blob = await response.blob();
          const objectUrl = URL.createObjectURL(blob);
          
          setMediaState({
            isLoading: false,
            url: objectUrl,
            error: null,
          });
          
          // Cache the URL with expiration time (4.5 minutes = 270000 ms)
          // Setting slightly less than 5 minutes to be safe
          const expiresAt = now + 270000;
          
          setMediaCache(prev => {
            return {
              ...prev,
              [mediaId]: {
                objectUrl,
                expiresAt,
              },
            };
          });
        } catch (fetchError) {
          clearTimeout(timeoutId);
          
          if (fetchError.name === 'AbortError') {
            throw new Error('Request timed out. The WhatsApp bot server may be unavailable.');
          }
          
          throw fetchError;
        }
      } catch (error) {
        console.error('Error fetching WhatsApp media:', error);
        setMediaState({
          isLoading: false,
          url: originalUrl, // Fall back to original URL on error
          error: error instanceof Error ? error : new Error(String(error)),
        });
      }
    };

    fetchMediaUrl();
    
    // Set up a refresh interval for URLs that might expire
    // Only needed if we're dealing with a WhatsApp media ID
    const mediaIdMatch = originalUrl.match(/mid=(\d+)/) || originalUrl.match(/\/(\d+)\/$/);
    
    if (mediaIdMatch && mediaIdMatch[1]) {
      // Refresh URL every 4.5 minutes to ensure we always have a valid URL
      const refreshInterval = setInterval(fetchMediaUrl, 270000); // 4.5 minutes
      
      return () => clearInterval(refreshInterval);
    }
  }, [originalUrl, accessToken, mediaCache]);

  // Function to force refresh the media URL
  const refreshMediaUrl = () => {
    if (!originalUrl) return;
    
    const mediaIdMatch = originalUrl.match(/mid=(\d+)/) || originalUrl.match(/\/(\d+)\/$/);
    
    if (mediaIdMatch && mediaIdMatch[1]) {
      const mediaId = mediaIdMatch[1];
      
      // Remove from cache to force a refresh
      setMediaCache(prev => {
        const newCache = { ...prev };
        
        // Revoke object URL if it exists
        if (newCache[mediaId]?.objectUrl && newCache[mediaId].objectUrl.startsWith('blob:')) {
          URL.revokeObjectURL(newCache[mediaId].objectUrl);
        }
        
        delete newCache[mediaId];
        return newCache;
      });
      
      // Set loading state
      setMediaState(prev => ({ ...prev, isLoading: true }));
      
      // The useEffect will handle the refresh
    }
  };

  return {
    ...mediaState,
    refreshMediaUrl,
  };
} 