import { useState, useMemo, useCallback, useEffect } from "react";
import { Complaint } from "@/lib/types/complaints";
import { debugLog } from "@/lib/debug";

export function useComplaintFilters(complaints: Complaint[] = []) {
  // Ensure complaints is always an array
  const safeComplaints = useMemo(() => 
    Array.isArray(complaints) ? complaints : [], 
    [complaints]
  );
  
  // Local state for filters and sorting
  const [activeFilter, setActiveFilter] = useState("all");
  const [sortField, setSortField] = useState("createdAt");
  const [sortDirection, setSortDirection] = useState("desc");
  const [showFilters, setShowFilters] = useState(false);
  const [activeView, setActiveView] = useState("grid"); // grid, list, kanban
  
  // Add filter state
  const [statusFilter, setStatusFilter] = useState<string[]>([]);
  const [departmentFilter, setDepartmentFilter] = useState<string[]>([]);
  
  // Log when complaints change
  useEffect(() => {
    debugLog("useComplaintFilters", "Complaints data updated", {
      count: safeComplaints.length,
      firstId: safeComplaints[0]?.id || 'none'
    });
  }, [safeComplaints]);
  
  // Toggle filters visibility
  const toggleFilters = useCallback(() => {
    setShowFilters((prev) => !prev);
  }, []);

  // Handle filter changes
  const handleFilterChange = useCallback((value: string) => {
    setActiveFilter(value);
    // Reset additional filters when changing main filter
    if (value !== activeFilter) {
      setStatusFilter([]);
      setDepartmentFilter([]);
    }
  }, [activeFilter]);

  // Handle sort changes
  const handleSortChange = useCallback((field: string, direction: string) => {
    setSortField(field);
    setSortDirection(direction);
  }, []);

  // Handle view changes
  const handleViewChange = useCallback((view: string) => {
    setActiveView(view);
  }, []);
  
  // Reset all filters
  const resetFilters = useCallback(() => {
    setStatusFilter([]);
    setDepartmentFilter([]);
    setActiveFilter("all");
  }, []);

  // Filter complaints based on the active filter
  const filteredComplaints = useMemo(() => {
    if (!safeComplaints.length) return [];
    
    let filtered = [...safeComplaints];
    
    // First filter by status tab
    if (activeFilter !== "all") {
      filtered = filtered.filter((complaint) => complaint.status === activeFilter);
    }
    
    // Then apply additional filters
    if (statusFilter.length > 0) {
      filtered = filtered.filter(complaint => statusFilter.includes(complaint.status));
    }
    
    if (departmentFilter.length > 0) {
      filtered = filtered.filter(complaint => departmentFilter.includes(complaint.department));
    }
    
    debugLog("useComplaintFilters", "Filtered complaints", {
      originalCount: safeComplaints.length,
      filteredCount: filtered.length,
      activeFilter,
      statusFilter,
      departmentFilter
    });
    
    return filtered;
  }, [safeComplaints, activeFilter, statusFilter, departmentFilter]);

  // Sort the filtered complaints
  const sortedComplaints = useMemo(() => {
    if (!filteredComplaints.length) return [];
    
    const sorted = [...filteredComplaints].sort((a, b) => {
      // Handle different field types
      if (sortField === "createdAt" || sortField === "updatedAt") {
        const dateA = new Date(a[sortField as keyof Complaint] as string).getTime();
        const dateB = new Date(b[sortField as keyof Complaint] as string).getTime();
        return sortDirection === "asc" ? dateA - dateB : dateB - dateA;
      }
      
      // Handle string fields
      const valueA = String(a[sortField as keyof Complaint] || "");
      const valueB = String(b[sortField as keyof Complaint] || "");
      
      return sortDirection === "asc"
        ? valueA.localeCompare(valueB)
        : valueB.localeCompare(valueA);
    });
    
    debugLog("useComplaintFilters", "Sorted complaints", {
      filteredCount: filteredComplaints.length,
      sortedCount: sorted.length,
      sortField,
      sortDirection
    });
    
    return sorted;
  }, [filteredComplaints, sortField, sortDirection]);

  return {
    // Filter states
    activeFilter,
    setActiveFilter,
    statusFilter,
    setStatusFilter,
    departmentFilter,
    setDepartmentFilter,
    
    // Sort states
    sortField,
    sortDirection,
    
    // View states
    showFilters,
    activeView,
    
    // Actions
    toggleFilters,
    handleFilterChange,
    handleSortChange,
    handleViewChange,
    resetFilters,
    
    // Filtered and sorted data
    filteredComplaints,
    sortedComplaints
  };
}
