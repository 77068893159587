import { toast } from 'sonner';
import { debugError } from '@/lib/debug';
import { DELHI_COORDINATES, MAP_ZOOM_LEVELS } from '@/lib/types/maps';
import type { Complaint } from '@/lib/types/complaints';
import { createInteractiveMarker } from './interactiveMarker';

/**
 * Initializes the map markers based on complaints
 */
export const initializeMapMarkers = (
  map: google.maps.Map,
  complaints: Complaint[],
  onMarkerClick?: (complaint: Complaint) => void,
  onMarkerHover?: (complaint: Complaint) => void,
  onMarkerLeave?: () => void
): google.maps.marker.AdvancedMarkerElement[] => {
  const markers: google.maps.marker.AdvancedMarkerElement[] = [];
  const bounds = new google.maps.LatLngBounds();
  let hasValidComplaints = false;
  
  // Filter out complaints without coordinates
  const validComplaints = complaints.filter(c => c.latitude && c.longitude);
  
  if (validComplaints.length === 0) {
    debugError('Map', 'No valid complaint coordinates found');
    map.setCenter(DELHI_COORDINATES);
    map.setZoom(MAP_ZOOM_LEVELS.city);
    return markers;
  }
  
  // Create markers for each complaint
  validComplaints.forEach(complaint => {
    if (!complaint.latitude || !complaint.longitude) return;
    hasValidComplaints = true;
    
    try {
      const marker = createInteractiveMarker({
        map,
        complaint,
        onClick: onMarkerClick,
        onHover: onMarkerHover,
        onLeave: onMarkerLeave
      });
      
      if (marker) {
        markers.push(marker as google.maps.marker.AdvancedMarkerElement);
        bounds.extend({ lat: complaint.latitude, lng: complaint.longitude });
      }
    } catch (error) {
      debugError('Map', `Error creating marker for complaint ${complaint.id}:`, error);
    }
  });
  
  // Adjust map view based on markers
  if (markers.length > 1) {
    map.fitBounds(bounds, 50); // Add padding for better view
  } else if (markers.length === 1) {
    const complaint = validComplaints[0];
    map.setCenter({ lat: complaint.latitude!, lng: complaint.longitude! });
    map.setZoom(MAP_ZOOM_LEVELS.neighborhood);
  } else {
    map.setCenter(DELHI_COORDINATES);
    map.setZoom(MAP_ZOOM_LEVELS.city);
  }
  
  return markers;
};

/**
 * Clears all markers from the map
 */
export const clearMarkers = (markers: google.maps.marker.AdvancedMarkerElement[]): void => {
  markers.forEach(marker => {
    if (marker && marker.map) {
      marker.map = null;
    }
  });
};

/**
 * Updates existing markers with new complaints data
 */
export const updateMapMarkers = (
  map: google.maps.Map,
  complaints: Complaint[],
  existingMarkers: google.maps.marker.AdvancedMarkerElement[],
  onMarkerClick?: (complaint: Complaint) => void,
  onMarkerHover?: (complaint: Complaint) => void,
  onMarkerLeave?: () => void
): google.maps.marker.AdvancedMarkerElement[] => {
  // Clear existing markers
  clearMarkers(existingMarkers);
  
  // Create new markers
  return initializeMapMarkers(
    map,
    complaints,
    onMarkerClick,
    onMarkerHover,
    onMarkerLeave
  );
};
