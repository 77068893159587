
import { Skeleton } from "@/components/ui/skeleton";
import { TrendingUp, Activity, XCircle } from "lucide-react";
import { RadialBarChart, RadialBar, ResponsiveContainer } from "recharts";

interface KeyInsightsProps {
  stats?: ReturnType<typeof import("@/lib/utils/statsCalculator").calculateStats>;
  isLoading: boolean;
}

export function KeyInsights({ stats, isLoading }: KeyInsightsProps) {
  const resolutionData = stats ? [
    {
      name: 'Resolution Rate',
      value: stats.resolutionRate,
      fill: "hsl(var(--analytics-green))"
    }
  ] : [];
  
  if (isLoading) {
    return (
      <div className="space-y-4">
        <Skeleton className="h-8 w-full" />
        <Skeleton className="h-20 w-full" />
        <Skeleton className="h-8 w-full" />
        <Skeleton className="h-20 w-full" />
      </div>
    );
  }
  
  return (
    <div className="space-y-4">
      <div className="p-4 rounded-lg bg-analytics-purple-light border border-analytics-purple/20">
        <div className="flex items-center gap-2">
          <TrendingUp className="h-5 w-5 text-analytics-purple" />
          <h4 className="font-medium">Most Common Issue</h4>
        </div>
        <p className="mt-2 text-sm text-muted-foreground">
          {stats?.mostCommonDepartment || "No data"} department has the most complaints.
        </p>
      </div>
      
      <div className="p-4 rounded-lg bg-analytics-purple-light border border-analytics-purple/20">
        <div className="flex items-center gap-2">
          <Activity className="h-5 w-5 text-analytics-purple" />
          <h4 className="font-medium">Resolution Rate</h4>
        </div>
        
        <div className="mt-2 flex items-center justify-between">
          <p className="text-sm text-muted-foreground">
            {stats?.resolutionRate || 0}% of all complaints have been resolved.
          </p>
          
          <div className="h-16 w-16 relative">
            {stats && (
              <ResponsiveContainer width="100%" height="100%">
                <RadialBarChart 
                  innerRadius="70%" 
                  outerRadius="100%" 
                  data={resolutionData} 
                  startAngle={90} 
                  endAngle={-270}
                >
                  <RadialBar
                    background={{ fill: 'hsl(var(--analytics-purple-light))' }}
                    dataKey="value"
                    cornerRadius={10}
                  />
                  <text
                    x="50%"
                    y="50%"
                    textAnchor="middle"
                    dominantBaseline="middle"
                    className="text-xs font-medium"
                    fill="hsl(var(--analytics-green))"
                  >
                    {stats?.resolutionRate || 0}%
                  </text>
                </RadialBarChart>
              </ResponsiveContainer>
            )}
          </div>
        </div>
      </div>
      
      {stats?.rejected ? (
        <div className="p-4 rounded-lg bg-red-50 border border-red-200">
          <div className="flex items-center gap-2">
            <XCircle className="h-5 w-5 text-status-rejected" />
            <h4 className="font-medium">Rejected Complaints</h4>
          </div>
          <p className="mt-2 text-sm text-muted-foreground">
            {stats.rejected} complaints have been rejected 
            ({Math.round((stats.rejected / stats.total) * 100)}% of total).
          </p>
        </div>
      ) : null}
    </div>
  );
}
