
import { useQuery } from "@tanstack/react-query";
import { DashboardLayout } from "@/components/layout/DashboardLayout";
import { RoleService } from "@/lib/RoleService";
import { RoleProtectedRoute } from "@/components/auth/RoleProtectedRoute";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { UserRolesTable } from "@/components/user-management/UserRolesTable";
import { AssignRoleDialog } from "@/components/user-management/AssignRoleDialog";
import { UserManagementHeader } from "@/components/user-management/UserManagementHeader";
import { PendingUsersTable } from "@/components/user-management/PendingUsersTable";

const UserManagement = () => {
  // Query for user roles
  const { 
    data: userRoles, 
    isLoading 
  } = useQuery({
    queryKey: ['user-roles'],
    queryFn: () => RoleService.getAllUserRoles()
  });

  // Query for available users (users without roles)
  const { 
    data: availableUsers, 
    isLoading: isLoadingAvailableUsers 
  } = useQuery({
    queryKey: ['available-users'],
    queryFn: () => RoleService.getAvailableUsers()
  });

  return (
    <RoleProtectedRoute allowedRoles={['admin']}>
      <DashboardLayout>
        <div className="space-y-8">
          <UserManagementHeader 
            action={
              <AssignRoleDialog 
                availableUsers={availableUsers}
                isLoadingAvailableUsers={isLoadingAvailableUsers}
              />
            }
          />
          
          {availableUsers && availableUsers.length > 0 && (
            <Card>
              <CardHeader>
                <CardTitle>Pending Users</CardTitle>
                <CardDescription>
                  These users have signed up but haven't been assigned roles yet
                </CardDescription>
              </CardHeader>
              <CardContent>
                <PendingUsersTable 
                  users={availableUsers}
                  isLoading={isLoadingAvailableUsers}
                />
              </CardContent>
            </Card>
          )}
          
          <Card>
            <CardHeader>
              <CardTitle>User Roles</CardTitle>
              <CardDescription>
                Manage user roles and their assigned departments
              </CardDescription>
            </CardHeader>
            <CardContent>
              <UserRolesTable 
                userRoles={userRoles}
                isLoading={isLoading}
              />
            </CardContent>
          </Card>
        </div>
      </DashboardLayout>
    </RoleProtectedRoute>
  );
};

export default UserManagement;
