import { Complaint } from "@/lib/types/complaints";
import { ComplaintCard } from "../ComplaintCard";
import { PlusCircle, AlertCircle, Clock, CheckCircle, XCircle } from "lucide-react";
import { Link } from "react-router-dom";
import { debugLog } from "@/lib/debug";
import { motion } from "framer-motion";
import { cn } from "@/lib/utils";

interface KanbanColumnProps {
  title: string;
  count: number;
  complaints: Complaint[];
  status: string;
  icon: React.ReactNode;
}

function KanbanColumn({ title, count, complaints, status, icon }: KanbanColumnProps) {
  // Ensure complaints is always an array
  const safeComplaints = Array.isArray(complaints) ? complaints : [];
  
  // Get the appropriate border color based on status
  const getBorderColor = () => {
    switch(status) {
      case 'new': return 'border-l-4 border-yellow-500';
      case 'in-progress': return 'border-l-4 border-blue-500';
      case 'resolved': return 'border-l-4 border-green-500';
      case 'rejected': return 'border-l-4 border-red-500';
      default: return 'border-l-4 border-gray-500';
    }
  };
  
  return (
    <motion.div 
      className="flex flex-col h-full"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
    >
      <div className="flex items-center justify-between mb-2">
        <h3 className="font-medium text-sm flex items-center">
          {icon}
          <span className="ml-2">{title}</span>
          <span className="ml-2 bg-muted text-muted-foreground rounded-full px-2 py-0.5 text-xs">
            {count}
          </span>
        </h3>
      </div>
      
      <div className={cn(
        "bg-muted/30 rounded-lg p-2 flex-1 overflow-hidden",
        getBorderColor()
      )}>
        <div className="space-y-2 h-full overflow-y-auto pb-4 pr-1 max-h-[calc(100vh-220px)]">
          {safeComplaints.length > 0 ? (
            safeComplaints.map((complaint, index) => (
              <motion.div
                key={complaint.id}
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.2, delay: index * 0.05 }}
              >
                <ComplaintCard complaint={complaint} />
              </motion.div>
            ))
          ) : (
            <div className="flex flex-col items-center justify-center h-24 text-center text-sm text-muted-foreground bg-background/50 rounded-md p-4">
              <p>No complaints</p>
              <Link 
                to="/new-complaint" 
                className="text-primary flex items-center mt-2 text-xs hover:underline"
              >
                <PlusCircle className="w-3 h-3 mr-1" />
                Add complaint
              </Link>
            </div>
          )}
        </div>
      </div>
    </motion.div>
  );
}

interface KanbanViewProps {
  complaints: Complaint[];
}

export function KanbanView({ complaints }: KanbanViewProps) {
  // Ensure complaints is always an array
  const safeComplaints = Array.isArray(complaints) ? complaints : [];
  
  // Log kanban view rendering
  debugLog("KanbanView", `Rendering kanban view with ${safeComplaints.length} complaints`);
  
  // Group complaints by status
  const newComplaints = safeComplaints.filter(c => c.status === "new");
  const inProgressComplaints = safeComplaints.filter(c => c.status === "in-progress");
  const resolvedComplaints = safeComplaints.filter(c => c.status === "resolved");
  const rejectedComplaints = safeComplaints.filter(c => c.status === "rejected");
  
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mt-4">
      <KanbanColumn 
        title="New" 
        count={newComplaints.length} 
        complaints={newComplaints}
        status="new"
        icon={<AlertCircle className="w-4 h-4 text-yellow-500" />}
      />
      
      <KanbanColumn 
        title="In Progress" 
        count={inProgressComplaints.length} 
        complaints={inProgressComplaints}
        status="in-progress"
        icon={<Clock className="w-4 h-4 text-blue-500" />}
      />
      
      <KanbanColumn 
        title="Resolved" 
        count={resolvedComplaints.length} 
        complaints={resolvedComplaints}
        status="resolved"
        icon={<CheckCircle className="w-4 h-4 text-green-500" />}
      />
      
      <KanbanColumn 
        title="Rejected" 
        count={rejectedComplaints.length} 
        complaints={rejectedComplaints}
        status="rejected"
        icon={<XCircle className="w-4 h-4 text-red-500" />}
      />
    </div>
  );
}
