
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import { Toaster } from "sonner";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import { AuthProvider } from "@/lib/AuthContext";
import { UserRoleProvider } from "@/lib/UserRoleContext";
import { ThemeProvider } from "@/components/theme-provider";
import { SidebarProvider } from "@/hooks/useSidebar";

import Index from "./pages/Index";
import Auth from "./pages/Auth";
import Complaints from "./pages/Complaints";
import ComplaintView from "./pages/ComplaintView";
import MapView from "./pages/MapView";
import Settings from "./pages/Settings";
import NotFound from "./pages/NotFound";
import UserManagement from "./pages/UserManagement";

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider defaultTheme="light">
        <AuthProvider>
          <UserRoleProvider>
            <SidebarProvider>
              <Router>
                <Routes>
                  <Route path="/" element={<Index />} />
                  <Route path="/auth" element={<Auth />} />
                  <Route path="/complaints" element={<Complaints />} />
                  <Route path="/complaint/:complaintId" element={<ComplaintView />} />
                  <Route path="/map" element={<MapView />} />
                  <Route path="/settings" element={<Settings />} />
                  <Route path="/users" element={<UserManagement />} />
                  <Route path="*" element={<NotFound />} />
                </Routes>
                <Toaster />
              </Router>
            </SidebarProvider>
          </UserRoleProvider>
        </AuthProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default App;
