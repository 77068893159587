
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";

export function AccordionDemo() {
  return (
    <Accordion type="single" collapsible className="w-full">
      <AccordionItem value="faq-1">
        <AccordionTrigger>Help & Resources</AccordionTrigger>
        <AccordionContent>
          <div className="flex flex-col space-y-1 text-sm">
            <a href="https://docs.example.com" className="text-muted-foreground hover:text-foreground">Documentation</a>
            <a href="https://support.example.com" className="text-muted-foreground hover:text-foreground">Support</a>
            <a href="https://community.example.com" className="text-muted-foreground hover:text-foreground">Community</a>
          </div>
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
}
