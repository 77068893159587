import { useState } from 'react';
import { toast } from 'sonner';
import { Badge } from '@/components/ui/badge';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { AlertTriangle, Globe, Lock } from 'lucide-react';
import { isDomainRestrictionError } from './MapUtils';

interface ApiKeyFormProps {
  errorMessage: string | null;
  onApiKeySubmit: (apiKey: string) => void;
}

export function ApiKeyForm({ errorMessage, onApiKeySubmit }: ApiKeyFormProps) {
  const [customApiKey, setCustomApiKey] = useState('');

  const handleApplyApiKey = () => {
    if (customApiKey.trim()) {
      onApiKeySubmit(customApiKey.trim());
      toast.info('Applying new Google Maps API key...');
    } else {
      toast.error('Please enter a valid Google Maps API key');
    }
  };

  const isApiNotActivatedError = errorMessage?.includes('not activated');
  const isApiRestrictedError = isDomainRestrictionError({ message: errorMessage });
  const isMapIdError = errorMessage?.includes('Map ID') || errorMessage?.includes('Advanced Markers');

  return (
    <div className="absolute inset-0 flex flex-col items-center justify-center bg-background p-6 z-10">
      <div className="max-w-md w-full space-y-4 bg-white p-6 rounded-lg shadow-lg">
        <div className="text-center space-y-2">
          <Badge variant={errorMessage ? "destructive" : "outline"} className="mb-2">
            {errorMessage ? "Map Error" : "Setup Required"}
          </Badge>
          <h3 className="text-lg font-medium">
            {errorMessage ? "Unable to load map" : "Google Maps API Key Required"}
          </h3>
          
          {isApiNotActivatedError && (
            <div className="bg-amber-50 border border-amber-200 p-3 rounded-md flex items-start gap-2 mt-2">
              <AlertTriangle className="h-5 w-5 text-amber-500 flex-shrink-0 mt-0.5" />
              <div className="text-sm text-left text-amber-800">
                <p className="font-medium">Maps JavaScript API not enabled</p>
                <p className="mt-1">
                  You need to enable the Maps JavaScript API for your API key in the Google Cloud Console.
                </p>
              </div>
            </div>
          )}
          
          {isApiRestrictedError && (
            <div className="bg-amber-50 border border-amber-200 p-3 rounded-md flex items-start gap-2 mt-2">
              <Lock className="h-5 w-5 text-amber-500 flex-shrink-0 mt-0.5" />
              <div className="text-sm text-left text-amber-800">
                <p className="font-medium">API Key Has Domain Restrictions</p>
                <p className="mt-1">
                  Your API key has restrictions that are preventing it from working on this domain. 
                  You have two options:
                </p>
                <ol className="list-decimal list-inside mt-2 space-y-1">
                  <li>Create a new API key without domain restrictions (for testing)</li>
                  <li>Add this domain to your key's allowed domains list</li>
                </ol>
              </div>
            </div>
          )}
          
          {isMapIdError && (
            <div className="bg-amber-50 border border-amber-200 p-3 rounded-md flex items-start gap-2 mt-2">
              <Globe className="h-5 w-5 text-amber-500 flex-shrink-0 mt-0.5" />
              <div className="text-sm text-left text-amber-800">
                <p className="font-medium">Map ID Required for Advanced Markers</p>
                <p className="mt-1">
                  To use Advanced Markers, you need to configure the Map ID in your environment variables.
                </p>
                <ol className="list-decimal list-inside mt-2 space-y-1">
                  <li>Set the VITE_GOOGLE_MAPS_MAP_ID environment variable</li>
                  <li>Create a Map ID in the <a href="https://console.cloud.google.com/google/maps-apis/map-ids" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">Maps Management page</a></li>
                  <li>Make sure the Maps JavaScript API is enabled</li>
                </ol>
              </div>
            </div>
          )}
          
          <p className="text-muted-foreground">
            {!isApiNotActivatedError && !isApiRestrictedError && !isMapIdError && errorMessage ? errorMessage : "Please enter your Google Maps API key to view the map."}
          </p>
        </div>
        
        <div className="space-y-2">
          <p className="text-sm">Enter your Google Maps API key:</p>
          <div className="flex gap-2">
            <Input 
              value={customApiKey} 
              onChange={(e) => setCustomApiKey(e.target.value)}
              placeholder="AIzaSyC..." 
              className="flex-1"
            />
            <Button onClick={handleApplyApiKey}>Apply</Button>
          </div>
          
          <div className="text-xs text-muted-foreground space-y-2">
            <p>
              Get your API key from the{' '}
              <a 
                href="https://console.cloud.google.com/google/maps-apis/credentials" 
                target="_blank" 
                rel="noopener noreferrer" 
                className="text-blue-500 hover:underline"
              >
                Google Cloud Console
              </a>
            </p>
            
            <div className="bg-muted p-2 rounded-md">
              <p className="font-medium mb-1">Important:</p>
              <ol className="list-decimal list-inside space-y-1">
                <li>
                  <a 
                    href="https://console.cloud.google.com/google/maps-apis/api-list" 
                    target="_blank" 
                    rel="noopener noreferrer" 
                    className="text-blue-500 hover:underline"
                  >
                    Enable the Maps JavaScript API
                  </a> for your project
                </li>
                <li>Make sure billing is enabled for your Google Cloud project</li>
                <li>
                  <a 
                    href="https://console.cloud.google.com/google/maps-apis/credentials" 
                    target="_blank" 
                    rel="noopener noreferrer"
                    className="text-blue-500 hover:underline"
                  >
                    Configure API key restrictions
                  </a> to include this domain or use an unrestricted key for testing
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
