
import type { Complaint } from '@/lib/types/complaints';
import { getStatusColor } from './mapColors';

/**
 * Creates tooltip content for a complaint
 */
export const createTooltipContent = (complaint: Complaint): string => {
  return `
    <div style="max-width: 250px; padding: 8px;">
      <h3 style="font-weight: 500; font-size: 14px; margin-bottom: 4px;">${complaint.title}</h3>
      <div style="font-size: 11px; margin-bottom: 6px;">
        <span style="display: inline-block; width: 10px; height: 10px; border-radius: 50%; background-color: ${getStatusColor(complaint.status)}; margin-right: 4px;"></span>
        <span>${complaint.status.charAt(0).toUpperCase() + complaint.status.slice(1)}</span>
        <span style="margin-left: 8px;">${complaint.department.charAt(0).toUpperCase() + complaint.department.slice(1)}</span>
      </div>
      <p style="font-size: 12px; color: #666; margin-bottom: 8px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">${complaint.description}</p>
      <div style="display: flex; justify-content: flex-end;">
        <span style="font-size: 12px; color: #3b82f6; cursor: pointer;">Click to view details</span>
      </div>
    </div>
  `;
};

/**
 * Creates an enhanced tooltip content with more details for clicking
 */
export const createEnhancedTooltipContent = (complaint: Complaint): string => {
  const statusColor = getStatusColor(complaint.status);
  const formattedDate = new Date(complaint.createdAt).toLocaleDateString();
  
  return `
    <div style="max-width: 300px; padding: 10px; background-color: white; border-radius: 8px; box-shadow: 0 2px 10px rgba(0,0,0,0.1);">
      <h3 style="font-weight: 600; font-size: 16px; margin-bottom: 8px; color: #111;">${complaint.title}</h3>
      
      <div style="display: flex; align-items: center; margin-bottom: 8px;">
        <span style="display: inline-block; width: 12px; height: 12px; border-radius: 50%; background-color: ${statusColor}; margin-right: 6px;"></span>
        <span style="font-size: 13px; font-weight: 500;">${complaint.status.charAt(0).toUpperCase() + complaint.status.slice(1)}</span>
        <span style="height: 12px; width: 1px; background-color: #ddd; margin: 0 8px;"></span>
        <span style="font-size: 13px;">${complaint.department.charAt(0).toUpperCase() + complaint.department.slice(1)}</span>
      </div>
      
      <p style="font-size: 13px; color: #444; margin-bottom: 10px; line-height: 1.4;">
        ${complaint.description.length > 120 ? complaint.description.substring(0, 120) + '...' : complaint.description}
      </p>
      
      <div style="font-size: 12px; color: #666; margin-bottom: 10px;">
        <div>📅 Reported on ${formattedDate}</div>
        ${complaint.location ? `<div>📍 ${complaint.location}</div>` : ''}
      </div>
      
      <div style="display: flex; justify-content: flex-end;">
        <a href="/complaint/${complaint.id}" style="
          display: inline-block;
          font-size: 13px;
          font-weight: 500;
          color: white;
          background-color: #3b82f6;
          padding: 6px 12px;
          border-radius: 4px;
          text-decoration: none;
          transition: background-color 0.2s;
        ">View details</a>
      </div>
    </div>
  `;
};
