
import { createClient } from '@supabase/supabase-js';
import { Database } from './types';

// Get environment variables
const supabaseUrl = import.meta.env.VITE_SUPABASE_URL;
const supabaseAnonKey = import.meta.env.VITE_SUPABASE_ANON_KEY;

// Validate environment variables
if (!supabaseUrl || !supabaseAnonKey) {
  console.warn('Missing Supabase environment variables. Using demo mode.');
}

// Create Supabase client with safer error handling
export const supabase = createClient<Database>(
  supabaseUrl || 'https://mqkempqvsfqcszzcspsr.supabase.co',
  supabaseAnonKey || 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im1xa2VtcHF2c2ZxY3N6emNzcHNyIiwicm9sZSI6ImFub24iLCJpYXQiOjE3NDE1NTE5OTAsImV4cCI6MjA1NzEyNzk5MH0.dRTStTipAb38o0G-j68deFnt5fKG_iJ6OuDVE7CSP3U'
);

// Helper function to check if Supabase connection is working
export const checkSupabaseConnection = async (): Promise<boolean> => {
  try {
    const { error } = await supabase.from('complaints').select('count', { count: 'exact', head: true });
    return !error;
  } catch (e) {
    console.error("Failed to connect to Supabase:", e);
    return false;
  }
};
