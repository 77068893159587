
import type { Complaint } from '@/lib/types/complaints';

/**
 * Gets the marker color based on complaint status
 */
export const getStatusColor = (status: string): string => {
  switch (status) {
    case 'new':
      return '#f97316';
    case 'in-progress':
      return '#3b82f6';
    case 'resolved':
      return '#10b981';
    case 'rejected':
      return '#ef4444';
    default:
      return '#6b7280';
  }
};
