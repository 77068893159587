
import { ReactNode } from "react";

interface UserManagementHeaderProps {
  action: ReactNode;
}

export const UserManagementHeader = ({ action }: UserManagementHeaderProps) => {
  return (
    <div className="flex justify-between items-center">
      <div>
        <h1 className="text-3xl font-bold tracking-tight">User Management</h1>
        <p className="text-muted-foreground">
          Manage user roles and permissions
        </p>
      </div>
      
      {action}
    </div>
  );
};
