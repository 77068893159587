import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "sonner";
import { Trash2 } from "lucide-react";
import { Button } from "@/components/ui/button";
import { UserRoleData, RoleService } from "@/lib/RoleService";
import { useUserRole } from "@/lib/UserRoleContext";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";

interface UserRolesTableProps {
  userRoles: UserRoleData[] | undefined;
  isLoading: boolean;
}

export const UserRolesTable = ({ userRoles, isLoading }: UserRolesTableProps) => {
  const queryClient = useQueryClient();

  // Mutation for removing a role
  const removeRoleMutation = useMutation({
    mutationFn: (roleId: string) => RoleService.removeRole(roleId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['user-roles'] });
      queryClient.invalidateQueries({ queryKey: ['available-users'] });
      toast.success("Role removed successfully");
    },
    onError: (error: Error) => {
      toast.error(error.message || "Failed to remove role");
    }
  });

  if (isLoading) {
    return (
      <div className="flex justify-center p-6">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary"></div>
      </div>
    );
  }

  if (!userRoles || userRoles.length === 0) {
    return (
      <div className="text-center py-6 text-muted-foreground">
        No user roles assigned yet
      </div>
    );
  }

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>User</TableHead>
          <TableHead>Email</TableHead>
          <TableHead>Role</TableHead>
          <TableHead>Department</TableHead>
          <TableHead className="w-24">Actions</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {userRoles.map((userRole) => (
          <TableRow key={userRole.id}>
            <TableCell className="font-medium">{userRole.name || "N/A"}</TableCell>
            <TableCell>{userRole.email || "N/A"}</TableCell>
            <TableCell>
              <span className={`
                inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium
                ${userRole.role === 'admin' ? 'bg-purple-100 text-purple-800' : 
                  userRole.role === 'staff' ? 'bg-blue-100 text-blue-800' : 
                  'bg-gray-100 text-gray-800'}
              `}>
                {userRole.role === 'admin' ? 'Administrator' : 
                 userRole.role === 'staff' ? 'Staff' : 
                 'Staff'}
              </span>
            </TableCell>
            <TableCell>{userRole.department || "N/A"}</TableCell>
            <TableCell>
              <Button
                variant="ghost"
                size="icon"
                onClick={() => removeRoleMutation.mutate(userRole.id)}
                disabled={removeRoleMutation.isPending}
              >
                <Trash2 className="h-4 w-4 text-destructive" />
                <span className="sr-only">Remove</span>
              </Button>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
