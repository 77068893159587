import { Complaint } from "@/lib/types/complaints";
import { useNavigate } from "react-router-dom";
import { debugLog } from "@/lib/debug";
import { formatDistanceToNow } from "date-fns";
import { User, MapPin, MessageCircle, Phone, Calendar } from "lucide-react";
import { cn } from "@/lib/utils";
import { StatusBadge } from "@/components/ui/StatusBadge";

interface GridViewProps {
  complaints: Complaint[];
}

export function GridView({ complaints }: GridViewProps) {
  const navigate = useNavigate();
  
  // Ensure complaints is always an array and log for debugging
  const safeComplaints = Array.isArray(complaints) ? complaints : [];
  
  debugLog("GridView", `Rendering grid with ${safeComplaints.length} complaints`, safeComplaints);

  const handleClick = (id: string) => {
    debugLog("GridView", `Navigating to complaint ${id}`);
    navigate(`/complaint/${id}`);
  };

  if (!safeComplaints.length) {
    return (
      <div className="text-center py-10 text-muted-foreground">
        No complaints to display
      </div>
    );
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 pt-4">
      {safeComplaints.map((complaint) => {
        const isWhatsAppComplaint = complaint.source === 'whatsapp_bot';
        
        return (
          <div 
            key={complaint.id} 
            className={cn(
              "border rounded-lg p-4 hover:shadow-md transition-all cursor-pointer hover:border-primary/50",
              isWhatsAppComplaint ? "bg-gradient-to-r from-green-50 to-transparent" : ""
            )}
            onClick={() => handleClick(complaint.id)}
          >
            <div className="flex items-start justify-between mb-2">
              <h3 className="text-lg font-medium line-clamp-1 flex-1">{complaint.title}</h3>
              <StatusBadge status={complaint.status} className="ml-2 flex-shrink-0" />
            </div>
            
            <div className="text-sm text-muted-foreground mb-3 line-clamp-3">
              {complaint.description}
            </div>
            
            <div className="flex flex-col gap-2 mt-4 text-xs">
              <div className="flex justify-between items-center">
                <div className="flex items-center gap-1 text-muted-foreground">
                  <Calendar className="h-3 w-3" />
                  <span>{formatDistanceToNow(new Date(complaint.createdAt), { addSuffix: true })}</span>
                </div>
                <span className="font-medium capitalize">{complaint.department}</span>
              </div>
              
              {complaint.location && (
                <div className="flex items-center gap-1 text-muted-foreground">
                  <MapPin className="h-3 w-3" />
                  <span className="truncate">{complaint.location}</span>
                </div>
              )}
              
              <div className="flex items-center gap-1 text-muted-foreground">
                {isWhatsAppComplaint ? (
                  <div className="flex items-center gap-1 text-green-700">
                    <MessageCircle className="h-3 w-3" />
                    <span>WhatsApp</span>
                    {complaint.whatsapp_user_name && (
                      <>
                        <span className="mx-1">•</span>
                        <User className="h-3 w-3" /> 
                        <span className="truncate">{complaint.whatsapp_user_name}</span>
                      </>
                    )}
                  </div>
                ) : (
                  <>
                    <User className="h-3 w-3" />
                    <span>{complaint.submittedBy?.name || 'Unknown'}</span>
                  </>
                )}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
