
import { useState } from "react";
import { ArrowUpDown, SortAsc, SortDesc, Check } from "lucide-react";
import { 
  DropdownMenu, 
  DropdownMenuContent, 
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuTrigger,
  DropdownMenuSeparator 
} from "@/components/ui/dropdown-menu";

interface SortButtonProps {
  sortField: string;
  sortDirection: 'asc' | 'desc';
  onChange: (field: string, direction: 'asc' | 'desc') => void;
}

export function SortButton({ sortField, sortDirection, onChange }: SortButtonProps) {
  const [isOpen, setIsOpen] = useState(false);
  
  const handleSelect = (field: string) => {
    // Toggle direction if selecting the same field
    const newDirection = field === sortField && sortDirection === 'asc' ? 'desc' : 'asc';
    onChange(field, newDirection);
  };

  const getItemIcon = (field: string) => {
    if (field !== sortField) return null;
    return sortDirection === 'asc' ? <SortAsc className="ml-auto h-4 w-4" /> : <SortDesc className="ml-auto h-4 w-4" />;
  };
  
  return (
    <DropdownMenu open={isOpen} onOpenChange={setIsOpen}>
      <DropdownMenuTrigger className="inline-flex items-center justify-center rounded-md font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 border border-input bg-background hover:bg-accent hover:text-accent-foreground h-9 px-4 py-2 text-sm">
        <ArrowUpDown className="mr-2 h-4 w-4" />
        <span>Sort</span>
        {sortField !== 'createdAt' && (
          <span className="ml-1 flex h-2 w-2">
            <span className="absolute inline-flex h-2 w-2 rounded-full bg-sky-400"></span>
          </span>
        )}
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56" align="end">
        <div className="font-medium px-2 py-1.5 text-sm">Sort by</div>
        
        <DropdownMenuRadioItem 
          value="createdAt"
          onClick={() => handleSelect('createdAt')}
          className="flex items-center justify-between"
        >
          Date
          {getItemIcon('createdAt')}
        </DropdownMenuRadioItem>
        
        <DropdownMenuRadioItem 
          value="title"
          onClick={() => handleSelect('title')}
          className="flex items-center justify-between"
        >
          Title
          {getItemIcon('title')}
        </DropdownMenuRadioItem>
        
        <DropdownMenuRadioItem 
          value="department"
          onClick={() => handleSelect('department')}
          className="flex items-center justify-between"
        >
          Department
          {getItemIcon('department')}
        </DropdownMenuRadioItem>
        
        <DropdownMenuSeparator />
        
        {sortField !== 'createdAt' && (
          <button
            className="w-full flex items-center px-2 py-1.5 text-sm text-muted-foreground hover:bg-accent hover:text-accent-foreground cursor-pointer"
            onClick={() => onChange('createdAt', 'desc')}
          >
            <Check className="mr-2 h-4 w-4" /> Reset to default
          </button>
        )}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
