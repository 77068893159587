import { XAxis, YAxis, XAxisProps, YAxisProps } from "recharts";

// Wrapper for XAxis using default parameters instead of defaultProps
export function CustomXAxis({ 
  dataKey = "name", 
  axisLine = false, 
  tickLine = false,
  fontSize = 12,
  tick = { fill: 'hsl(var(--muted-foreground))' },
  ...props 
}: XAxisProps) {
  return (
    <XAxis 
      dataKey={dataKey} 
      axisLine={axisLine} 
      tickLine={tickLine} 
      fontSize={fontSize}
      tick={tick}
      {...props}
    />
  );
}

// Wrapper for YAxis using default parameters instead of defaultProps
export function CustomYAxis({ 
  axisLine = false, 
  tickLine = false,
  tick = { fill: 'hsl(var(--muted-foreground))' },
  ...props 
}: YAxisProps) {
  return (
    <YAxis 
      axisLine={axisLine} 
      tickLine={tickLine} 
      tick={tick}
      {...props}
    />
  );
} 