
import { Comment } from "../../types/comments";
import { BaseService } from "../BaseService";
import { debugError } from "../../debug";
import { PostgrestError } from "@supabase/supabase-js";

export class FetchCommentsService extends BaseService {
  static async getComments(complaintId: string): Promise<Comment[]> {
    try {
      const { data, error } = await this.supabase
        .from('comments')
        .select('*')
        .eq('complaint_id', complaintId)
        .order('created_at', { ascending: true });

      if (error) {
        throw new Error(`Database error: ${error.message}`);
      }

      if (!data) {
        return [];
      }

      return data.map(comment => ({
        id: comment.id,
        complaintId: comment.complaint_id,
        text: comment.text,
        authorId: comment.user_id,
        createdAt: new Date(comment.created_at),
      }));
    } catch (error) {
      let errorMessage = 'Unknown error in getComments';
      
      if (error instanceof Error) {
        errorMessage = error.message;
      } else if (typeof error === 'object' && error !== null) {
        const pgError = error as PostgrestError;
        errorMessage = pgError.message || 'Database error';
      }
      
      debugError('FetchCommentsService', errorMessage, error);
      return [];
    }
  }
}
