
import { cn } from "@/lib/utils";
import { Skeleton } from "@/components/ui/skeleton";
import { ReactNode } from "react";

interface StatCardProps {
  title: string;
  value: number | string;
  icon: ReactNode;
  description?: string;
  className?: string;
  isLoading?: boolean;
  iconColor?: string;
}

export function StatCard({ 
  title, 
  value, 
  icon, 
  description, 
  className, 
  isLoading = false,
  iconColor = "text-muted-foreground"
}: StatCardProps) {
  return (
    <div className={cn(
      "analytics-card-stat", 
      className
    )}>
      <div className="flex items-center justify-between">
        <h3 className="stats-title">{title}</h3>
        <div className={cn("transition-transform hover:scale-110", iconColor)}>{icon}</div>
      </div>
      <div className="mt-2">
        {isLoading ? (
          <Skeleton className="h-10 w-16 mt-1" />
        ) : (
          <p className="stats-value">{value}</p>
        )}
        {description && !isLoading && (
          <p className="mt-1 text-xs text-muted-foreground">{description}</p>
        )}
      </div>
    </div>
  );
}
