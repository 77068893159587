
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Button } from "@/components/ui/button";
import { ChevronsUpDown, Filter } from "lucide-react";
import { SortButton } from "./SortButton";
import { ComplaintFilter } from "./ComplaintFilter";
import { ViewSelector } from "./ViewSelector";

interface TabNavigationProps {
  complaintCount: number;
  activeFilter: string;
  onFilterChange: (filter: string) => void;
  onSortChange: (field: string, direction: string) => void;
  sortField: string;
  sortDirection: string;
  showFilters: boolean;
  toggleFilters: () => void;
  activeView: string;
  onViewChange: (view: string) => void;
  // Filter props
  statusFilter?: string[];
  departmentFilter?: string[];
  setStatusFilter?: (value: string[]) => void;
  setDepartmentFilter?: (value: string[]) => void;
  resetFilters?: () => void;
}

export function TabNavigation({
  complaintCount,
  activeFilter,
  onFilterChange,
  onSortChange,
  sortField,
  sortDirection,
  showFilters,
  toggleFilters,
  activeView,
  onViewChange,
  statusFilter = [],
  departmentFilter = [],
  setStatusFilter = () => {},
  setDepartmentFilter = () => {},
  resetFilters = () => {}
}: TabNavigationProps) {
  // The handler to ensure we call the callback when the tab value changes
  const handleTabChange = (value: string) => {
    onFilterChange(value);
  };

  return (
    <div className="flex flex-col space-y-3 md:space-y-4">
      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-2">
        <Tabs value={activeFilter} onValueChange={handleTabChange} defaultValue="all" className="w-full sm:w-auto">
          <TabsList className="grid w-full sm:w-auto grid-cols-2 xs:grid-cols-4">
            <TabsTrigger value="all" className="text-xs sm:text-sm px-2 sm:px-3">
              All
            </TabsTrigger>
            <TabsTrigger value="new" className="text-xs sm:text-sm px-2 sm:px-3">
              New
            </TabsTrigger>
            <TabsTrigger value="in-progress" className="text-xs sm:text-sm px-2 sm:px-3">
              In Progress
            </TabsTrigger>
            <TabsTrigger value="resolved" className="text-xs sm:text-sm px-2 sm:px-3">
              Resolved
            </TabsTrigger>
          </TabsList>
        </Tabs>
        
        <div className="flex items-center space-x-1 sm:space-x-2 w-full sm:w-auto justify-between sm:justify-end mt-2 sm:mt-0">
          <ViewSelector activeView={activeView} onViewChange={onViewChange} />
          
          <SortButton
            sortField={sortField}
            sortDirection={sortDirection as 'asc' | 'desc'}
            onChange={onSortChange}
          />
          
          <Button
            variant="outline"
            size="sm"
            onClick={toggleFilters}
            className="h-8 sm:h-9"
          >
            <Filter className="h-3 w-3 sm:h-4 sm:w-4 mr-1 sm:mr-2" />
            <span className="text-xs sm:text-sm">Filters</span>
            <ChevronsUpDown className="h-3 w-3 sm:h-3.5 sm:w-3.5 ml-1 sm:ml-2 opacity-50" />
          </Button>
        </div>
      </div>
      
      {showFilters && (
        <ComplaintFilter 
          statusFilter={statusFilter}
          departmentFilter={departmentFilter}
          setStatusFilter={setStatusFilter}
          setDepartmentFilter={setDepartmentFilter}
          resetFilters={resetFilters}
        />
      )}
      
      <div className="flex items-center justify-between border-b pb-2">
        <p className="text-xs sm:text-sm text-muted-foreground">
          Showing <span className="font-medium text-foreground">{complaintCount}</span> complaints
        </p>
      </div>
    </div>
  );
}
