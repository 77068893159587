import { toast } from 'sonner';
import { debugError } from '@/lib/debug';
import { DELHI_COORDINATES, MAP_ZOOM_LEVELS } from '@/lib/types/maps';
import { handleMapError, isDomainRestrictionError } from './mapErrorUtils';

export const initializeGoogleMap = async (
  container: HTMLDivElement,
  apiKey: string,
  mapId?: string
): Promise<google.maps.Map | null> => {
  try {
    const { Loader } = await import('@googlemaps/js-api-loader');
    
    const loader = new Loader({
      apiKey: apiKey,
      version: "weekly",
      libraries: ["places", "marker"]
    });
    
    try {
      await loader.load();
      
      const mapOptions: google.maps.MapOptions = {
        center: DELHI_COORDINATES,
        zoom: MAP_ZOOM_LEVELS.city,
        mapTypeControl: true,
        streetViewControl: true,
        fullscreenControl: true,
        zoomControl: true,
        gestureHandling: 'cooperative',
        styles: [
          {
            featureType: 'administrative.locality',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#404040' }]
          },
          {
            featureType: 'poi',
            elementType: 'labels.icon',
            stylers: [{ saturation: -5 }, { lightness: 15 }]
          },
          {
            featureType: 'road.arterial',
            elementType: 'geometry.fill',
            stylers: [{ color: '#f0f0f0' }]
          }
        ]
      };

      // Only add mapId if it's provided
      if (mapId) {
        mapOptions.mapId = mapId;
      }

      const map = new google.maps.Map(container, mapOptions);

      toast.success('Map loaded successfully');
      return map;
      
    } catch (mapError: unknown) {
      const errorMessage = handleMapError(mapError, 'Map Initialization');
      toast.error(errorMessage);
      throw new Error(errorMessage);
    }
    
  } catch (error: unknown) {
    const errorMessage = handleMapError(error, 'Google Maps Loader');
    toast.error(errorMessage);
    return null;
  }
};
