
import { toast } from 'sonner';
import { debugError } from '@/lib/debug';

/**
 * Checks if the error is related to domain restrictions
 */
export const isDomainRestrictionError = (error: unknown): boolean => {
  if (!error) return false;
  
  const errorMessage = error instanceof Error ? error.message : String(error);
  return errorMessage.includes('ApiTargetBlockedMapError') || 
         errorMessage.includes('domain restrictions') ||
         errorMessage.includes('referrer restrictions');
};

/**
 * Handles and formats map errors
 */
export const handleMapError = (error: unknown, context: string = 'Map'): string => {
  debugError(context, 'Error:', error);
  
  if (!error) return 'An unknown error occurred';
  
  const errorMessage = error instanceof Error ? error.message : String(error);
  
  if (errorMessage.includes('ApiNotActivatedMapError')) {
    return 'The Maps JavaScript API is not activated for your API key. Please enable it in the Google Cloud Console.';
  } else if (isDomainRestrictionError(error)) {
    return 'Your API key has domain restrictions. Please update your API key settings in Google Cloud Console or use an unrestricted key.';
  } else if (errorMessage.includes('Map ID')) {
    return 'Invalid Map ID. Advanced Markers require a valid Map ID configured in Google Cloud Console.';
  } else {
    return `Error loading Google Maps: ${errorMessage}`;
  }
};
