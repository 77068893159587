/**
 * Debug log levels
 */
export enum LogLevel {
  NONE = 0,    // No logging
  ERROR = 1,   // Only errors
  WARN = 2,    // Errors and warnings
  INFO = 3,    // Errors, warnings, and info
  DEBUG = 4,   // All messages including debug
  VERBOSE = 5  // All messages with verbose details
}

// Configuration for debug logging
const debugConfig = {
  // Default log level (can be overridden with localStorage)
  defaultLogLevel: process.env.NODE_ENV === 'production' ? LogLevel.ERROR : LogLevel.INFO,
  
  // Components to always filter out (e.g. noisy components)
  ignoredComponents: ['AdminService'],
  
  // Get current log level (with localStorage override support)
  get logLevel(): LogLevel {
    if (typeof window !== 'undefined' && window.localStorage) {
      const storedLevel = window.localStorage.getItem('debug_level');
      if (storedLevel !== null) {
        const level = parseInt(storedLevel, 10);
        if (!isNaN(level) && level >= 0 && level <= LogLevel.VERBOSE) {
          return level;
        }
      }
    }
    return this.defaultLogLevel;
  },
  
  // Set log level (and save to localStorage)
  set logLevel(level: LogLevel) {
    if (typeof window !== 'undefined' && window.localStorage) {
      window.localStorage.setItem('debug_level', level.toString());
    }
  },
  
  // Check if component is ignored
  isComponentIgnored(component: string): boolean {
    return this.ignoredComponents.includes(component);
  },
  
  // Check if message should be logged based on level
  shouldLog(level: LogLevel): boolean {
    return level <= this.logLevel;
  }
};

/**
 * Debug utility function to help track data loading and errors
 */
export function debugLog(component: string, message: string, data?: unknown) {
  if (debugConfig.isComponentIgnored(component)) {
    return;
  }
  
  if (debugConfig.shouldLog(LogLevel.DEBUG)) {
    console.log(`[${component}] ${message}`, data ? data : '');
  }
}

/**
 * Debug utility function to help track errors
 */
export function debugError(component: string, message: string, error?: unknown) {
  if (debugConfig.isComponentIgnored(component)) {
    return;
  }
  
  if (debugConfig.shouldLog(LogLevel.ERROR)) {
    console.error(`[${component}] ${message}`, error ? error : '');
    
    // Add additional context for different error types
    if (error instanceof Error) {
      console.error(`[${component}] Error details:`, {
        name: error.name,
        message: error.message,
        stack: error.stack
      });
    } else if (typeof error === 'object' && error !== null) {
      console.error(`[${component}] Error object:`, error);
    }
  }
}

/**
 * Debug utility function for warnings
 */
export function debugWarn(component: string, message: string, data?: unknown) {
  if (debugConfig.isComponentIgnored(component)) {
    return;
  }
  
  if (debugConfig.shouldLog(LogLevel.WARN)) {
    console.warn(`[${component}] ${message}`, data ? data : '');
  }
}

/**
 * Debug utility function for info messages
 */
export function debugInfo(component: string, message: string, data?: unknown) {
  if (debugConfig.isComponentIgnored(component)) {
    return;
  }
  
  if (debugConfig.shouldLog(LogLevel.INFO)) {
    console.info(`[${component}] ${message}`, data ? data : '');
  }
}

/**
 * Debug utility to track search operations
 */
export function debugSearch(query: string, resultsCount: number, totalCount: number) {
  if (debugConfig.shouldLog(LogLevel.DEBUG)) {
    console.log(
      `[Search] Query: "${query}" | Results: ${resultsCount}/${totalCount} | ` +
      `Match rate: ${Math.round((resultsCount / totalCount) * 100)}%`
    );
  }
}

/**
 * Utility to set debug level from code
 */
export function setDebugLevel(level: LogLevel): void {
  debugConfig.logLevel = level;
}

/**
 * Utility to add component to ignored list
 */
export function ignoreComponent(component: string): void {
  if (!debugConfig.ignoredComponents.includes(component)) {
    debugConfig.ignoredComponents.push(component);
  }
}

/**
 * Utility to remove component from ignored list
 */
export function unignoreComponent(component: string): void {
  const index = debugConfig.ignoredComponents.indexOf(component);
  if (index !== -1) {
    debugConfig.ignoredComponents.splice(index, 1);
  }
}
