import { useEffect, useRef, useState } from 'react';
import { toast } from 'sonner';
import { MapProps, TooltipConfig } from '@/lib/types/maps';
import { ApiKeyForm } from './ApiKeyForm';
import { MapLoadingState } from './MapLoadingState';
import { initializeGoogleMap } from './utils/mapInitializer';
import { initializeMapMarkers, clearMarkers, updateMapMarkers } from './utils/markerManager';
import { Complaint } from '@/lib/types/complaints';
import { Card } from '@/components/ui/card';
import { 
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import { useNavigate } from 'react-router-dom';

export function Map({ complaints, filteredComplaints }: MapProps) {
  const navigate = useNavigate();
  const mapContainer = useRef<HTMLDivElement>(null);
  const mapRef = useRef<google.maps.Map | null>(null);
  const markersRef = useRef<google.maps.marker.AdvancedMarkerElement[]>([]);
  const [complaintToDisplay, setComplaintToDisplay] = useState(complaints);
  const [mapLoaded, setMapLoaded] = useState(false);
  const [mapError, setMapError] = useState<string | null>(null);
  const [isMapInitialized, setIsMapInitialized] = useState(false);
  const [apiKeyAttempts, setApiKeyAttempts] = useState(0);
  const [activeComplaint, setActiveComplaint] = useState<Complaint | null>(null);
  const [tooltipConfig, setTooltipConfig] = useState<TooltipConfig>({
    show: false
  });

  // Get API key and Map ID from environment variables
  const googleMapsApiKey = import.meta.env.VITE_GOOGLE_MAPS_API_KEY;
  const mapId = import.meta.env.VITE_GOOGLE_MAPS_MAP_ID || 'jan_samadhan_map';

  useEffect(() => {
    setComplaintToDisplay(filteredComplaints || complaints);
  }, [complaints, filteredComplaints]);

  // Handle marker interactions
  const handleMarkerClick = (complaint: Complaint) => {
    setActiveComplaint(complaint);
    navigate(`/complaint/${complaint.id}`);
  };

  const handleMarkerHover = (complaint: Complaint) => {
    setActiveComplaint(complaint);
    setTooltipConfig({
      show: true,
      complaint
    });
  };

  const handleMarkerLeave = () => {
    setTooltipConfig({
      show: false
    });
    setActiveComplaint(null);
  };

  const handleApplyApiKey = (apiKey: string) => {
    if (mapRef.current) {
      clearMarkers(markersRef.current);
      mapRef.current = null;
    }
    
    setMapError(null);
    setMapLoaded(false);
    setIsMapInitialized(false);
  };

  // Initialize map when component mounts
  useEffect(() => {
    // Try to load API key from localStorage first
    const savedApiKey = localStorage.getItem('googleMapsApiKey');
    
    if (savedApiKey) {
      setMapError(null);
      setMapLoaded(true);
      setIsMapInitialized(true);
      setApiKeyAttempts(0); // Reset attempts on success
    } else if (!googleMapsApiKey) {
      // Use the default API key as fallback
      setMapError(null);
      setMapLoaded(true);
      setIsMapInitialized(true);
      setApiKeyAttempts(0); // Reset attempts on success
    }
  }, []);

  // Handle API key changes
  useEffect(() => {
    if (googleMapsApiKey) {
      // Save API key to localStorage for future use
      localStorage.setItem('googleMapsApiKey', googleMapsApiKey);
    }
  }, [googleMapsApiKey]);

  // Initialize map when API key changes
  useEffect(() => {
    const initMap = async () => {
      if (!mapContainer.current || mapRef.current || !googleMapsApiKey) return;

      setMapError(null);
      
      try {
        const map = await initializeGoogleMap(mapContainer.current, googleMapsApiKey, mapId);
        
        if (map) {
          mapRef.current = map;
          setMapLoaded(true);
          setIsMapInitialized(true);
          setApiKeyAttempts(0); // Reset attempts on success
        } else {
          setMapError('Failed to initialize map. Please check your API key and try again.');
          setMapLoaded(false);
          setIsMapInitialized(false);
          setApiKeyAttempts(prev => prev + 1);
          
          // If we've tried multiple times with the same key, suggest using a different one
          if (apiKeyAttempts > 1) {
            toast.error('Multiple failures with this API key. Consider using a different key.');
          }
        }
      } catch (error: unknown) {
        console.error('Map initialization error:', error);
        const errorMessage = error instanceof Error ? error.message : 'Failed to initialize map. Please check your API key and try again.';
        
        if (typeof error === 'object' && error !== null && 'code' in error && (error as any).code === 'OVER_QUERY_LIMIT') {
          setMapError('API key has domain restrictions. Please update your API key settings in Google Cloud Console or use an unrestricted key.');
        } else if (errorMessage.includes('Map ID') || errorMessage.includes('Advanced Markers')) {
          setMapError('Invalid Map ID. Advanced Markers require a valid Map ID configured in Google Cloud Console.');
        } else {
          setMapError(errorMessage);
        }
        
        setMapLoaded(false);
        setIsMapInitialized(false);
        setApiKeyAttempts(prev => prev + 1);
      }
    };

    if (googleMapsApiKey) {
      initMap();
    }
    
    return () => {
      clearMarkers(markersRef.current);
      mapRef.current = null;
    };
  }, [googleMapsApiKey, apiKeyAttempts, mapId]);

  // Update markers when complaints change
  useEffect(() => {
    if (!mapRef.current || !complaintToDisplay.length || !mapLoaded) return;

    // Filter complaints that have coordinates
    const complaintsWithCoords = complaintToDisplay.filter(
      comp => comp.latitude && comp.longitude
    );

    if (complaintsWithCoords.length > 0) {
      markersRef.current = updateMapMarkers(
        mapRef.current, 
        complaintsWithCoords,
        markersRef.current,
        handleMarkerClick,
        handleMarkerHover,
        handleMarkerLeave
      );
    } else {
      clearMarkers(markersRef.current);
      markersRef.current = [];
    }
  }, [complaintToDisplay, mapLoaded]);

  const hasNoLocationData = mapLoaded && isMapInitialized && 
    !complaintToDisplay.some(c => c.latitude && c.longitude);

  // Handle complaint detail click
  const handleViewComplaintDetail = (complaint: Complaint) => {
    navigate(`/complaint/${complaint.id}`);
  };

  return (
    <TooltipProvider>
      <div className="relative w-full h-[70vh] rounded-md border shadow-sm overflow-hidden bg-background">
        <div ref={mapContainer} className="absolute inset-0" />
        
        {(!googleMapsApiKey || mapError) && (
          <ApiKeyForm 
            errorMessage={mapError} 
            onApiKeySubmit={handleApplyApiKey} 
          />
        )}
        
        <MapLoadingState 
          isLoading={googleMapsApiKey && !mapLoaded && !mapError} 
          noLocationsFound={hasNoLocationData}
        />

        {/* Floating card for active complaint info */}
        {activeComplaint && tooltipConfig.show && (
          <div className="absolute top-4 right-4 z-10 w-72 transition-all duration-200 ease-in-out">
            <Card className="shadow-lg border p-3 bg-white/95 backdrop-blur">
              <div className="flex flex-col gap-2">
                <h3 className="font-medium text-sm">{activeComplaint.title}</h3>
                <div className="flex items-center gap-2">
                  <div 
                    className="h-2 w-2 rounded-full" 
                    style={{ 
                      backgroundColor: 
                        activeComplaint.status === 'new' ? '#f97316' :
                        activeComplaint.status === 'in-progress' ? '#3b82f6' :
                        activeComplaint.status === 'resolved' ? '#10b981' : '#ef4444'
                    }}
                  />
                  <span className="text-xs text-muted-foreground">
                    {activeComplaint.status.charAt(0).toUpperCase() + activeComplaint.status.slice(1)}
                  </span>
                  <span className="text-xs text-muted-foreground">•</span>
                  <span className="text-xs text-muted-foreground">
                    {activeComplaint.department.charAt(0).toUpperCase() + activeComplaint.department.slice(1)}
                  </span>
                </div>
                <p className="text-xs line-clamp-2 text-muted-foreground">
                  {activeComplaint.description}
                </p>
                <button 
                  className="text-xs text-primary mt-1 self-end"
                  onClick={() => handleViewComplaintDetail(activeComplaint)}
                >
                  View details
                </button>
              </div>
            </Card>
          </div>
        )}
      </div>

      {/* Additional UI improvements for better UX */}
      {mapLoaded && !hasNoLocationData && (
        <div className="mt-4 text-xs text-muted-foreground">
          <div className="flex items-center justify-center space-x-8">
            <Tooltip>
              <TooltipTrigger className="flex items-center">
                <span className="h-3 w-3 rounded-full bg-[#f97316] mr-2"></span>
                <span>New</span>
              </TooltipTrigger>
              <TooltipContent>Newly reported complaints</TooltipContent>
            </Tooltip>
            
            <Tooltip>
              <TooltipTrigger className="flex items-center">
                <span className="h-3 w-3 rounded-full bg-[#3b82f6] mr-2"></span>
                <span>In Progress</span>
              </TooltipTrigger>
              <TooltipContent>Complaints being addressed</TooltipContent>
            </Tooltip>
            
            <Tooltip>
              <TooltipTrigger className="flex items-center">
                <span className="h-3 w-3 rounded-full bg-[#10b981] mr-2"></span>
                <span>Resolved</span>
              </TooltipTrigger>
              <TooltipContent>Successfully resolved complaints</TooltipContent>
            </Tooltip>
            
            <Tooltip>
              <TooltipTrigger className="flex items-center">
                <span className="h-3 w-3 rounded-full bg-[#ef4444] mr-2"></span>
                <span>Rejected</span>
              </TooltipTrigger>
              <TooltipContent>Complaints that could not be resolved</TooltipContent>
            </Tooltip>
          </div>
        </div>
      )}
    </TooltipProvider>
  );
}
