
import { Complaint } from "@/lib/data";
import { MapPin, Globe, ExternalLink } from "lucide-react";
import { Button } from "@/components/ui/button";

interface LocationInfoProps {
  complaint: Complaint;
}

export function LocationInfo({ complaint }: LocationInfoProps) {
  const hasLocation = complaint.location || complaint.googleMapsLink || (complaint.latitude && complaint.longitude);
  
  if (!hasLocation) return null;

  // Create maps link from coordinates if no googleMapsLink is provided
  const getMapsLink = () => {
    if (complaint.googleMapsLink) {
      return complaint.googleMapsLink;
    } else if (complaint.latitude && complaint.longitude) {
      return `https://www.google.com/maps?q=${complaint.latitude},${complaint.longitude}`;
    }
    return null;
  };

  const mapsLink = getMapsLink();
  
  return (
    <div className="rounded-xl border bg-card shadow-sm p-6 animate-scale-in">
      <h2 className="text-lg font-medium mb-4">Location Details</h2>
      
      <div className="space-y-3">
        {complaint.location && (
          <div className="flex items-start">
            <MapPin className="mr-2 h-4 w-4 mt-1 text-muted-foreground" />
            <p>{complaint.location}</p>
          </div>
        )}
        
        {mapsLink && (
          <div className="flex flex-col">
            <div className="flex items-start mb-2">
              <Globe className="mr-2 h-4 w-4 mt-1 text-muted-foreground" />
              <a 
                href={mapsLink}
                target="_blank"
                rel="noopener noreferrer"
                className="text-primary hover:underline"
              >
                View on Google Maps
              </a>
            </div>
            
            <Button 
              variant="outline" 
              size="sm" 
              className="flex items-center gap-2 w-fit"
              onClick={() => window.open(mapsLink, '_blank', 'noopener,noreferrer')}
            >
              <ExternalLink className="h-4 w-4" /> 
              Open Map
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}
