
import { useState } from "react";
import { Complaint } from "@/lib/data";
import { format } from "date-fns";
import { Send } from "lucide-react";
import { Textarea } from "@/components/ui/textarea";
import { Button } from "@/components/ui/button";

interface ComplaintCommentsProps {
  complaint: Complaint;
  onAddComment?: (text: string) => void;
  isAddingComment?: boolean;
}

export function ComplaintComments({ complaint, onAddComment, isAddingComment = false }: ComplaintCommentsProps) {
  const [comment, setComment] = useState("");
  
  const handleAddComment = () => {
    if (!comment.trim() || !onAddComment) return;
    onAddComment(comment);
    setComment("");
  };
  
  return (
    <div className="rounded-xl border bg-card shadow-sm p-6 animate-scale-in">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-lg font-medium">Comments &amp; Activity</h2>
        <span className="text-sm text-muted-foreground">
          {complaint.comments?.length || 0} {complaint.comments?.length === 1 ? 'comment' : 'comments'}
        </span>
      </div>
      
      {complaint.comments && complaint.comments.length > 0 ? (
        <div className="space-y-4">
          {complaint.comments.map((comment) => (
            <div key={comment.id} className="relative pl-6 pb-4">
              <div className="absolute top-0 left-0 w-px h-full bg-border"></div>
              <div className="absolute top-2 left-0 w-2 h-2 rounded-full bg-primary translate-x-[-50%]"></div>
              
              <div className="flex justify-between">
                <span className="font-medium">{comment.user}</span>
                <time className="text-sm text-muted-foreground">
                  {format(new Date(comment.createdAt), 'PPp')}
                </time>
              </div>
              
              <p className="mt-1 text-sm text-muted-foreground">{comment.text}</p>
            </div>
          ))}
        </div>
      ) : (
        <div className="text-center py-6 text-muted-foreground">
          <p>No comments yet</p>
        </div>
      )}
      
      {onAddComment && (
        <div className="mt-6">
          <h3 className="text-sm font-medium mb-2">Add Comment</h3>
          <div className="flex space-x-2">
            <Textarea 
              placeholder="Type your comment here..." 
              className="flex-1 resize-none"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
            <Button 
              onClick={handleAddComment} 
              disabled={!comment.trim() || isAddingComment}
              className="px-3"
            >
              <Send className="h-4 w-4" />
              <span className="sr-only">Send</span>
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}
