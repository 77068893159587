import { Complaint, ComplaintWithSubmitter, NewComplaint, PMComplaintStatus } from "../types/complaints";
import { BaseService } from "./BaseService";
import { FetchComplaintService } from "./complaints/FetchComplaintService";
import { CreateComplaintService } from "./complaints/CreateComplaintService";
import { UpdateComplaintService } from "./complaints/UpdateComplaintService";

export class ComplaintService extends BaseService {
  static async fetchComplaints(): Promise<Complaint[]> {
    return FetchComplaintService.fetchComplaints();
  }

  static async createComplaint(complaint: NewComplaint): Promise<Complaint | null> {
    try {
      const complaintId = await CreateComplaintService.createComplaint(complaint, complaint.submitted_by);
      if (complaintId) {
        return this.getComplaintById(complaintId);
      }
      return null;
    } catch (error) {
      console.error("Error in createComplaint:", error);
      return null;
    }
  }

  static async updateComplaint(id: string, updates: Partial<Complaint>): Promise<Complaint | null> {
    return UpdateComplaintService.updateComplaint(id, updates);
  }

  static async getComplaintById(id: string): Promise<ComplaintWithSubmitter | null> {
    return FetchComplaintService.getComplaintById(id);
  }

  static async updateStatus(complaintId: string, status: string): Promise<Complaint | null> {
    try {
      const success = await UpdateComplaintService.updateStatus(
        complaintId, 
        status as PMComplaintStatus
      );
      
      if (success) {
        return this.getComplaintById(complaintId);
      }
      return null;
    } catch (error) {
      console.error("Error in updateStatus:", error);
      return null;
    }
  }

  static async assignComplaint(complaintId: string, assigneeId: string | null): Promise<Complaint | null> {
    try {
      const success = await UpdateComplaintService.assignComplaint(
        complaintId, 
        assigneeId || ""
      );
      
      if (success) {
        return this.getComplaintById(complaintId);
      }
      return null;
    } catch (error) {
      console.error("Error in assignComplaint:", error);
      return null;
    }
  }
}
