import { createRoot } from 'react-dom/client'
import App from './App.tsx'
import './index.css'
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://b9e3a6e0bf32866dddf574da2862bf6e@o4508971999297536.ingest.de.sentry.io/4508972002377808",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      // Configure Replay to avoid font and media issues
      blockAllMedia: true, // Block all media elements from being captured
      maskAllText: true, // Mask all text content for privacy
    })
  ],
  // Tracing
  tracesSampleRate: 0.5, // Reduce to 50% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", "jansamadhan.in", /^https:\/\/jansamadhan\.in\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.05, // Reduce to 5% in production to minimize impact
  replaysOnErrorSampleRate: 0.5 // Reduce to 50% when errors occur to balance monitoring and performance
});

createRoot(document.getElementById("root")!).render(<App />);
