
import { ReactNode, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "@/lib/AuthContext";
import { useUserRole, UserRole } from "@/lib/UserRoleContext";
import { toast } from "sonner";

interface RoleProtectedRouteProps {
  children: ReactNode;
  allowedRoles: UserRole[];
  redirectTo?: string;
}

export const RoleProtectedRoute = ({ 
  children, 
  allowedRoles, 
  redirectTo = "/" 
}: RoleProtectedRouteProps) => {
  const { user, isLoading } = useAuth();
  const { role, isRoleLoading } = useUserRole();
  const navigate = useNavigate();
  const [accessGranted, setAccessGranted] = useState(false);

  useEffect(() => {
    // First check if authentication is loaded
    if (!isLoading && !user) {
      toast.error("Please login to access this page");
      navigate("/auth");
      return;
    }

    // Special handling for admin when accessing admin protected routes
    if (!isLoading && user && user.email === 'danisharora099@gmail.com' && allowedRoles.includes('admin')) {
      // This is the admin user attempting to access an admin page, let them through
      setAccessGranted(true);
      return;
    }

    // Then check if role is loaded and if user has permission
    if (!isLoading && !isRoleLoading && user) {
      // If user has no role yet, they're pending approval
      if (!role) {
        toast.error("Your account is pending approval");
        navigate("/");
        return;
      }

      const hasPermission = allowedRoles.includes(role);
      if (!hasPermission) {
        toast.error("You don't have permission to access this page");
        navigate(redirectTo);
        return;
      }
      
      setAccessGranted(true);
    }
  }, [user, isLoading, role, isRoleLoading, allowedRoles, navigate, redirectTo]);

  // Special case for admin email
  const isAdminAttemptingAdminAccess = 
    user?.email === 'danisharora099@gmail.com' && 
    allowedRoles.includes('admin') && 
    !isLoading;

  if (isLoading || (isRoleLoading && !isAdminAttemptingAdminAccess)) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-primary"></div>
      </div>
    );
  }

  // Show content only when access is granted
  return <>{(accessGranted || isAdminAttemptingAdminAccess) ? children : null}</>;
};
