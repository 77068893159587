
import { Complaint } from "@/lib/types/complaints";
import { SearchResults } from "./SearchResults";
import { ViewRenderer } from "./ViewRenderer";

interface SearchHandlerProps {
  isSearching: boolean;
  searchQuery: string;
  searchResults: Complaint[];
  totalComplaints: number;
  onClearSearch: () => void;
  activeView: string;
}

export function SearchHandler({
  isSearching,
  searchQuery,
  searchResults,
  totalComplaints,
  onClearSearch,
  activeView
}: SearchHandlerProps) {
  if (!isSearching) return null;
  
  return (
    <div className="space-y-4">
      <SearchResults
        searchQuery={searchQuery}
        searchResults={searchResults}
        totalComplaints={totalComplaints}
        onClearSearch={onClearSearch}
      />
      <ViewRenderer view={activeView} complaints={searchResults} />
    </div>
  );
}
