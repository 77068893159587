
import { Complaint } from "@/lib/types/complaints";
import { Card, CardContent } from "@/components/ui/card";
import { Filter } from "lucide-react";

interface FilteredStatsProps {
  complaints: Complaint[];
  filteredCount?: number;
  showFilterStats?: boolean;
}

export function FilteredStats({ complaints, filteredCount = 0, showFilterStats = true }: FilteredStatsProps) {
  // Only calculate stats if showing filter stats
  if (!showFilterStats) return null;
  
  // Calculate status counts for filtered complaints
  const newCount = complaints.filter(c => c.status === "new").length;
  const inProgressCount = complaints.filter(c => c.status === "in-progress").length;
  const resolvedCount = complaints.filter(c => c.status === "resolved").length;
  const rejectedCount = complaints.filter(c => c.status === "rejected").length;
  
  // Use actual count or calculate from complaints
  const actualFilteredCount = filteredCount || complaints.length;
  
  // Return null if there are no filtered complaints
  if (actualFilteredCount === 0) return null;
  
  return (
    <div className="mt-1 mb-2 animate-fade-in">
      <Card className="bg-analytics-purple-light border border-analytics-purple/20 shadow-sm hover:shadow-md transition-shadow">
        <CardContent className="p-3">
          <div className="flex flex-wrap gap-x-6 gap-y-2 text-sm text-muted-foreground">
            <div className="flex items-center">
              <Filter className="w-4 h-4 mr-1.5 text-analytics-purple" />
              <span className="font-medium mr-1">Showing:</span> {actualFilteredCount} complaints
            </div>
            
            <div className="flex items-center">
              <div className="w-3 h-3 rounded-full bg-status-new mr-1.5"></div>
              <span>New: {newCount}</span>
            </div>
            
            <div className="flex items-center">
              <div className="w-3 h-3 rounded-full bg-status-in-progress mr-1.5"></div>
              <span>In Progress: {inProgressCount}</span>
            </div>
            
            <div className="flex items-center">
              <div className="w-3 h-3 rounded-full bg-status-resolved mr-1.5"></div>
              <span>Resolved: {resolvedCount}</span>
            </div>
            
            <div className="flex items-center">
              <div className="w-3 h-3 rounded-full bg-status-rejected mr-1.5"></div>
              <span>Rejected: {rejectedCount}</span>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}
