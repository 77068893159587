import { 
  Complaint, 
  NewComplaint, 
  PMComplaintStatus as ComplaintStatus, 
  PMDepartment as Department, 
  departmentMapping,
  convertToSharedDepartment
} from "../../types/complaints";
import { ComplaintCreationRequest } from "jansamadhan-shared-types";
import { BaseService } from "../BaseService";
import { debugError } from "../../debug";
import { PostgrestError } from "@supabase/supabase-js";

// Enhanced NewComplaint interface to support geographic data
export interface EnhancedNewComplaint extends NewComplaint {
  google_maps_link?: string;
  latitude?: number;
  longitude?: number;
}

export class CreateComplaintService extends BaseService {
  static async createComplaint(
    complaintData: NewComplaint,
    userId: string
  ): Promise<string | null> {
    try {
      // Get user profile for submitter info
      const { data: userData, error: userError } = await this.supabase
        .from('profiles')
        .select('*')
        .eq('id', userId)
        .single();

      if (userError) {
        console.error('Error fetching user profile:', userError);
        return null;
      }

      // Create complaint creation request directly
      const complaintCreationRequest: ComplaintCreationRequest = {
        title: complaintData.title,
        description: complaintData.description,
        department: convertToSharedDepartment(complaintData.department),
        location: complaintData.location || '',
        submittedBy: {
          name: userData.name || 'Unknown',
          phone: userData.phone || 'No phone provided',
          email: userData.email
        },
        googleMapsLink: (complaintData as EnhancedNewComplaint).google_maps_link,
        latitude: (complaintData as EnhancedNewComplaint).latitude,
        longitude: (complaintData as EnhancedNewComplaint).longitude,
        manuallyCreated: complaintData.manually_created
      };

      // Prepare data for Supabase
      const complaintPayload = {
        title: complaintData.title,
        description: complaintData.description,
        department: complaintData.department,
        status: complaintData.status || 'new',
        location: complaintData.location || '',
        submitted_by: userId,
        manually_created: true,
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString(),
        // Add support for WhatsApp-specific fields
        google_maps_link: (complaintData as EnhancedNewComplaint).google_maps_link,
        latitude: (complaintData as EnhancedNewComplaint).latitude,
        longitude: (complaintData as EnhancedNewComplaint).longitude
      };

      // Insert into Supabase
      const { data, error } = await this.supabase
        .from('complaints')
        .insert(complaintPayload)
        .select()
        .single();

      if (error) {
        console.error('Error creating complaint:', error);
        return null;
      }

      return data.id;
    } catch (error) {
      console.error('Error in createComplaint:', error);
      return null;
    }
  }

  static async createComplaintWithMedia(
    complaintData: NewComplaint,
    userId: string,
    photoFile?: File
  ): Promise<string | null> {
    try {
      // First upload the photo if provided
      let photoUrl: string | undefined;
      
      if (photoFile) {
        const fileName = `${Date.now()}_${photoFile.name}`;
        const filePath = `complaints/${fileName}`;
        
        const { data: uploadData, error: uploadError } = await this.supabase
          .storage
          .from('attachments')
          .upload(filePath, photoFile);
        
        if (uploadError) {
          console.error('Error uploading photo:', uploadError);
        } else {
          // Get public URL for the uploaded file
          const { data: urlData } = await this.supabase
            .storage
            .from('attachments')
            .getPublicUrl(filePath);
          
          photoUrl = urlData.publicUrl;
        }
      }
      
      // Get user profile for submitter info
      const { data: userData, error: userError } = await this.supabase
        .from('profiles')
        .select('*')
        .eq('id', userId)
        .single();

      if (userError) {
        console.error('Error fetching user profile:', userError);
        return null;
      }

      // Prepare data for Supabase
      const complaintPayload = {
        title: complaintData.title,
        description: complaintData.description,
        department: complaintData.department,
        status: complaintData.status || 'new',
        location: complaintData.location || '',
        submitted_by: userId,
        manually_created: true,
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString(),
        // Add support for WhatsApp-specific fields
        google_maps_link: (complaintData as EnhancedNewComplaint).google_maps_link,
        latitude: (complaintData as EnhancedNewComplaint).latitude,
        longitude: (complaintData as EnhancedNewComplaint).longitude,
        // Add photo if uploaded
        attachments: photoUrl ? [photoUrl] : undefined
      };

      // Insert into Supabase
      const { data, error } = await this.supabase
        .from('complaints')
        .insert(complaintPayload)
        .select()
        .single();

      if (error) {
        console.error('Error creating complaint:', error);
        return null;
      }

      return data.id;
    } catch (error) {
      console.error('Error in createComplaintWithMedia:', error);
      return null;
    }
  }
}
