
import { Complaint } from "@/lib/data";
import { User, Phone, Mail, MessageSquare } from "lucide-react";

interface SubmitterInfoProps {
  complaint: Complaint;
}

export function SubmitterInfo({ complaint }: SubmitterInfoProps) {
  const isWhatsAppComplaint = complaint.source === 'whatsapp_bot';
  const phone = complaint.submittedBy?.phone;
  const email = complaint.submittedBy?.email;
  
  return (
    <div className="rounded-xl border bg-card shadow-sm p-6 animate-scale-in">
      <h2 className="text-lg font-medium mb-4">Submitted By</h2>
      
      <div className="space-y-3">
        <div className="flex items-start">
          <User className="mr-2 h-4 w-4 mt-0.5 text-muted-foreground" />
          <div>
            <p className="font-medium">{complaint.submittedBy.name}</p>
            <p className="text-sm text-muted-foreground">
              {isWhatsAppComplaint ? 'WhatsApp User' : 'Constituent'}
            </p>
          </div>
        </div>
        
        {phone && (
          <div className="flex">
            <Phone className="mr-2 h-4 w-4 text-muted-foreground" />
            <span>{phone}</span>
          </div>
        )}
        
        {email && email !== "No email provided" && (
          <div className="flex">
            <Mail className="mr-2 h-4 w-4 text-muted-foreground" />
            <span className="text-sm">{email}</span>
          </div>
        )}
        
        {complaint.language && (
          <div className="flex">
            <MessageSquare className="mr-2 h-4 w-4 text-muted-foreground" />
            <span className="text-sm">Language: {complaint.language}</span>
          </div>
        )}
        
        {isWhatsAppComplaint && (
          <div className="flex items-center mt-2 pt-2 border-t">
            <MessageSquare className="mr-2 h-4 w-4 text-green-500" />
            <span className="text-sm font-medium text-green-600">Submitted via WhatsApp</span>
          </div>
        )}
        
        {complaint.language && (
          <div className="text-xs text-muted-foreground mt-2">
            Language: {complaint.language === 'en' ? 'English' : complaint.language === 'hi' ? 'Hindi' : complaint.language}
          </div>
        )}
      </div>
    </div>
  );
}
