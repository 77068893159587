
import { Complaint, ComplaintWithSubmitter, departmentMapping } from "../../types/complaints";
import { BaseService } from "../BaseService";
import { debugLog } from "@/lib/debug";

export class FetchComplaintService extends BaseService {
  static async fetchComplaints(): Promise<Complaint[]> {
    try {
      debugLog("FetchComplaintService", "Fetching all complaints");
      
      const { data, error } = await this.supabase
        .from('complaints')
        .select('*')
        .order('created_at', { ascending: false });
      
      if (error) {
        console.error("Error fetching complaints:", error);
        return [];
      }
      
      if (!data || !Array.isArray(data)) {
        console.warn("No complaints data returned or invalid format");
        return [];
      }
      
      // Log raw data for debugging
      debugLog("FetchComplaintService", `Raw complaint data (${data.length} complaints)`, data);
      
      // Transform DB format to frontend model format
      const transformedComplaints = data.map(complaint => {
        // Handle the department mapping - convert DB format to frontend format
        const dbDepartment = complaint.department as string;
        let frontendDepartment = departmentMapping[dbDepartment] || 'other';
        
        // Parse location data
        let locationStr = '';
        let googleMapsLink = '';
        let latitude = null;
        let longitude = null;
        
        if (complaint.location) {
          if (typeof complaint.location === 'string') {
            locationStr = complaint.location;
          } else {
            try {
              // Handle both string and object formats
              locationStr = complaint.location.address || '';
              googleMapsLink = complaint.location.google_maps_link || '';
              latitude = complaint.location.latitude || null;
              longitude = complaint.location.longitude || null;
            } catch (e) {
              console.warn("Error parsing location data", e);
            }
          }
        }
        
        // Handle status - replace underscores with hyphens if needed
        const status = complaint.status === 'in_progress' ? 'in-progress' : complaint.status;
        
        return {
          id: complaint.id,
          title: complaint.title || '',
          description: complaint.description || '',
          department: frontendDepartment,
          status: status as any,
          createdAt: complaint.created_at,
          updatedAt: complaint.updated_at,
          location: locationStr,
          assignedTo: complaint.assigned_to,
          submittedBy: {
            name: 'Unknown', // Will be populated from profile later
            phone: '',
            email: ''
          },
          attachments: complaint.attachments || [],
          comments: [],
          isDummy: complaint.is_dummy || false,
          manuallyCreated: complaint.manually_created || false,
          googleMapsLink,
          latitude,
          longitude,
        };
      });
      
      debugLog("FetchComplaintService", `Transformed ${transformedComplaints.length} complaints`, transformedComplaints);
      
      return transformedComplaints;
    } catch (error) {
      console.error("Unexpected error fetching complaints:", error);
      return [];
    }
  }

  static async getComplaintById(id: string): Promise<ComplaintWithSubmitter | null> {
    try {
      debugLog("FetchComplaintService", `Fetching complaint with ID: ${id}`);
      
      const { data, error } = await this.supabase
        .from('complaints')
        .select(`
          *,
          profiles:submitted_by (
            name,
            phone
          )
        `)
        .eq('id', id)
        .single();
      
      if (error) {
        console.error("Error fetching complaint details:", error);
        return null;
      }
      
      if (!data) {
        console.warn(`No complaint found with ID: ${id}`);
        return null;
      }
      
      // Log raw data for debugging
      debugLog("FetchComplaintService", "Raw single complaint data", data);
      
      // Handle the department mapping - convert DB format to frontend format
      const dbDepartment = data.department as string;
      let frontendDepartment = departmentMapping[dbDepartment] || 'other';
      
      // Parse location data
      let locationStr = '';
      let googleMapsLink = '';
      let latitude = null;
      let longitude = null;
      
      if (data.location) {
        if (typeof data.location === 'string') {
          locationStr = data.location;
        } else {
          try {
            // Handle both string and object formats
            locationStr = data.location.address || '';
            googleMapsLink = data.location.google_maps_link || '';
            latitude = data.location.latitude || null;
            longitude = data.location.longitude || null;
          } catch (e) {
            console.warn("Error parsing location data", e);
          }
        }
      }
      
      // Handle status - replace underscores with hyphens if needed
      const status = data.status === 'in_progress' ? 'in-progress' : data.status;
      
      // Transform DB format to frontend model format
      const complaintWithSubmitter: ComplaintWithSubmitter = {
        id: data.id,
        title: data.title || '',
        description: data.description || '',
        department: frontendDepartment,
        status: status as any,
        createdAt: data.created_at,
        updatedAt: data.updated_at,
        location: locationStr,
        assignedTo: data.assigned_to,
        submittedBy: {
          name: data.profiles?.name || 'Unknown',
          phone: data.profiles?.phone || '',
          email: '',
        },
        submitter: data.profiles ? {
          id: data.submitted_by,
          name: data.profiles.name || '',
          phone: data.profiles.phone || '',
          email: ''
        } : undefined,
        attachments: data.attachments || [],
        comments: [],
        isDummy: data.is_dummy || false,
        manuallyCreated: data.manually_created || false,
        googleMapsLink,
        latitude,
        longitude,
      };
      
      debugLog("FetchComplaintService", "Transformed single complaint", complaintWithSubmitter);
      
      return complaintWithSubmitter;
    } catch (error) {
      console.error("Unexpected error fetching complaint details:", error);
      return null;
    }
  }
}
