
import { Button } from "@/components/ui/button";
import { Complaint } from "@/lib/types/complaints";
import { useNavigate } from "react-router-dom";

interface SearchResultsProps {
  searchQuery: string;
  searchResults?: Complaint[];
  totalComplaints?: number;
  onClearSearch: () => void;
}

export function SearchResults({ 
  searchQuery, 
  searchResults = [], 
  totalComplaints = 0, 
  onClearSearch 
}: SearchResultsProps) {
  const navigate = useNavigate();
  
  if (!searchQuery) return null;
  
  return (
    <div className="bg-muted p-2 rounded-md flex items-center justify-between">
      <p className="text-sm">
        Search results for: <span className="font-medium">{searchQuery}</span>
        {searchResults.length > 0 && (
          <span className="ml-1 text-muted-foreground">
            ({searchResults.length} of {totalComplaints} complaints)
          </span>
        )}
      </p>
      <Button 
        variant="ghost" 
        size="sm" 
        onClick={onClearSearch}
        className="h-8 px-2"
      >
        Clear search
      </Button>
    </div>
  );
}
