
import { BaseService } from "./BaseService";
import { UserRoleData, ProfileData, UserRole } from "../types/userRoles";
import { Database } from "@/integrations/supabase/types";

type AppRole = Database["public"]["Enums"]["app_role"];
type UserRoleRecord = {
  id: string;
  user_id: string;
  role: AppRole;
  department: string | null;
  created_at: string;
};

export class UserRoleService extends BaseService {
  static async getUserRoles(): Promise<UserRoleData[]> {
    try {
      // Modified query to use a simpler join approach
      const { data: userRoles, error: userRolesError } = await this.supabase
        .from('user_roles')
        .select('id, role, department, created_at, user_id');
        
      if (userRolesError) throw userRolesError;
      
      // If we have user roles, fetch the related profile information
      if (userRoles && userRoles.length > 0) {
        // Get all unique user IDs
        const userIds = userRoles.map(role => role.user_id);
        
        // Fetch profiles for these users
        const { data: profiles, error: profilesError } = await this.supabase
          .from('profiles')
          .select('id, name, email')
          .in('id', userIds);
          
        if (profilesError) throw profilesError;
        
        // Create a map of user IDs to profiles for quick lookup
        const profileMap = new Map();
        if (profiles) {
          profiles.forEach(profile => {
            profileMap.set(profile.id, profile);
          });
        }
        
        // Combine the data
        return userRoles.map(role => {
          const profile = profileMap.get(role.user_id) || { name: 'Unknown', email: 'No email' };
          return {
            id: role.id,
            user_id: role.user_id,
            role: role.role,
            department: role.department || '',
            created_at: role.created_at,
            name: profile.name || 'Unknown',
            email: profile.email || 'No email'
          };
        });
      }
      
      return [];
    } catch (error) {
      console.error('Error fetching user roles:', error);
      return [];
    }
  }
  
  static async getUsersByRole(role: AppRole): Promise<ProfileData[]> {
    try {
      // Get user IDs with the specified role
      const { data: roleData, error: roleError } = await this.supabase
        .from('user_roles')
        .select('user_id')
        .eq('role', role);
        
      if (roleError) throw roleError;
      
      if (!roleData || roleData.length === 0) {
        return [];
      }
      
      // Get the user IDs
      const userIds = roleData.map(item => item.user_id);
      
      // Fetch profiles for these users
      const { data: profiles, error: profilesError } = await this.supabase
        .from('profiles')
        .select('id, name, email')
        .in('id', userIds);
        
      if (profilesError) throw profilesError;
      
      return (profiles || []).map(profile => ({
        id: profile.id,
        name: profile.name || 'Unknown',
        email: profile.email || 'No email'
      }));
    } catch (error) {
      console.error(`Error fetching users with role ${role}:`, error);
      return [];
    }
  }
  
  static async getUserRoleByEmail(email: string): Promise<UserRoleRecord | null> {
    try {
      // First get the user id from profiles
      const { data: profileData, error: profileError } = await this.supabase
        .from('profiles')
        .select('id')
        .eq('email', email)
        .single();
        
      if (profileError) {
        console.error('Error fetching user profile:', profileError);
        return null;
      }
      
      if (!profileData) {
        console.log('No profile found for email:', email);
        return null;
      }
      
      // Then get the role
      const { data: roleData, error: roleError } = await this.supabase
        .from('user_roles')
        .select('*')
        .eq('user_id', profileData.id)
        .single();
        
      if (roleError) {
        if (roleError.code === 'PGRST116') {
          console.log('No role found for user:', profileData.id);
          return null;
        }
        console.error('Error fetching user role:', roleError);
        return null;
      }
      
      return roleData;
    } catch (error) {
      console.error('Error in getUserRoleByEmail:', error);
      return null;
    }
  }
  
  static async getUserRole(userId: string): Promise<UserRoleRecord | null> {
    try {
      const { data, error } = await this.supabase
        .from('user_roles')
        .select('*')
        .eq('user_id', userId)
        .single();
        
      if (error) {
        if (error.code === 'PGRST116') {
          console.log('No role found for user:', userId);
          return null;
        }
        console.error('Error fetching user role:', error);
        return null;
      }
      
      return data;
    } catch (error) {
      console.error('Error in getUserRole:', error);
      return null;
    }
  }
}
