
import {
  Home,
  LayoutDashboard,
  Map,
  MessageCircle,
  Settings,
  Users,
} from "lucide-react";
import { useLocation } from "react-router-dom";

import { useMobile } from "@/hooks/useMobile";
import { useSidebar } from "@/hooks/useSidebar";
import { AccordionDemo } from "@/components/ui/accordion-demo";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Separator } from "@/components/ui/separator";
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@/components/ui/sheet";
import { Skeleton } from "@/components/ui/skeleton";
import { useAuth } from "@/lib/AuthContext";
import { cn } from "@/lib/utils";

export function Sidebar() {
  // Default to regular user role
  let isAdmin = false;
  
  try {
    // Try to import and use the UserRoleContext
    const { useUserRole } = require("@/lib/UserRoleContext");
    try {
      const { role } = useUserRole();
      isAdmin = role === 'admin';
    } catch (error) {
      console.warn("UserRoleContext not available, defaulting to regular user:", error);
    }
  } catch (error) {
    console.warn("Could not import UserRoleContext module:", error);
  }
  
  const isMobile = useMobile();
  const { pathname } = useLocation();
  
  let collapsed = false;
  try {
    const sidebarContext = useSidebar();
    collapsed = sidebarContext.collapsed;
  } catch (error) {
    console.warn("SidebarContext not available:", error);
  }
  
  let user = null;
  try {
    const authContext = useAuth();
    user = authContext.user;
  } catch (error) {
    console.warn("AuthContext not available:", error);
  }
  
  const menuItems = [
    { href: "/", label: "Dashboard", icon: <Home className="h-4 w-4" /> },
    { href: "/complaints", label: "Complaints", icon: <MessageCircle className="h-4 w-4" /> },
    { href: "/map", label: "Map View", icon: <Map className="h-4 w-4" /> },
    ...(isAdmin ? [{ href: "/users", label: "User Management", icon: <Users className="h-4 w-4" /> }] : []),
    { href: "/settings", label: "Settings", icon: <Settings className="h-4 w-4" /> }
  ];

  if (isMobile) {
    return (
      <Sheet>
        <SheetTrigger asChild>
          <div className="p-4">
            <LayoutDashboard className="h-6 w-6" />
          </div>
        </SheetTrigger>
        <SheetContent side="left" className="w-80 p-0">
          <SheetHeader className="px-4 pb-0 pt-4">
            <SheetTitle>Menu</SheetTitle>
          </SheetHeader>
          <ScrollArea className="h-[calc(100vh-60px)]">
            <div className="flex flex-col space-y-1 px-4 py-4">
              {menuItems.map((item) => (
                <SheetClose asChild key={item.href}>
                  <a
                    href={item.href}
                    className={cn(
                      "group flex h-9 w-full items-center justify-start space-x-2 rounded-md p-2 text-sm font-medium hover:bg-secondary hover:text-accent focus:bg-secondary focus:text-accent focus:outline-none disabled:cursor-not-allowed disabled:opacity-50",
                      pathname === item.href
                        ? "bg-secondary text-accent"
                        : "text-muted-foreground"
                    )}
                  >
                    {item.icon}
                    <span>{item.label}</span>
                  </a>
                </SheetClose>
              ))}
            </div>
            <Separator />
            <div className="flex flex-col space-y-1 px-4 py-4">
              <AccordionDemo />
            </div>
          </ScrollArea>
        </SheetContent>
      </Sheet>
    );
  }

  return (
    <div className="group relative flex h-full min-h-screen flex-col border-r bg-secondary">
      <div className="flex h-16 items-center px-4">
        <Avatar className="h-8 w-8">
          {user?.user_metadata?.avatar_url ? (
            <AvatarImage src={user.user_metadata.avatar_url} alt={user?.user_metadata?.full_name || 'User'} />
          ) : (
            <AvatarFallback>{user?.user_metadata?.full_name?.charAt(0) || '?'}</AvatarFallback>
          )}
        </Avatar>
        <h1 className="ml-4 text-lg font-bold">
          {collapsed ? (
            <Skeleton className="h-6 w-16" />
          ) : (
            user?.user_metadata?.full_name || "User"
          )}
        </h1>
        <button
          className="absolute right-4 rounded-sm opacity-0 group-hover:opacity-100 transition-opacity hover:bg-accent hover:text-accent-foreground focus:bg-secondary focus:text-accent focus:outline-none"
          onClick={() => {
            try {
              const { setCollapsed } = useSidebar();
              setCollapsed(!collapsed);
            } catch (error) {
              console.warn("Error toggling sidebar:", error);
            }
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="h-4 w-4"
          >
            <path d="M3 9h18v2H3z" />
            <path d="M3 15h18v2H3z" />
            <path d="M5 19h14a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2z" />
          </svg>
          <span className="sr-only">Collapse sidebar</span>
        </button>
      </div>
      <Separator />
      <ScrollArea className="h-[calc(100vh-60px)]">
        <div className="flex flex-col space-y-1 px-4 py-4">
          {menuItems.map((item) => (
            <a
              key={item.href}
              href={item.href}
              className={cn(
                "group flex h-9 w-full items-center justify-start space-x-2 rounded-md p-2 text-sm font-medium hover:bg-accent hover:text-accent-foreground focus:bg-secondary focus:text-accent focus:outline-none disabled:cursor-not-allowed disabled:opacity-50",
                pathname === item.href
                  ? "bg-accent text-accent-foreground"
                  : "text-muted-foreground"
              )}
            >
              {item.icon}
              <span>{item.label}</span>
            </a>
          ))}
        </div>
        <Separator />
        <div className="flex flex-col space-y-1 px-4 py-4">
          <AccordionDemo />
        </div>
      </ScrollArea>
    </div>
  );
}
