
import { ReactNode, useState } from "react";
import { Tabs, TabsList, TabsTrigger, TabsContent } from "@/components/ui/tabs";
import { BarChart2, PieChartIcon, LineChartIcon } from "lucide-react";
import { DepartmentChart } from "./DepartmentChart";
import { StatusChart } from "./StatusChart";
import { TrendChart } from "./TrendChart";

interface ChartContainerProps {
  departmentChartData: Array<{ name: string; value: number }>;
  statusChartData: Array<{ name: string; value: number; color: string }>;
  trendData: Array<{ day: string; complaints: number; resolved: number }>;
  isLoading: boolean;
  animationActive: boolean;
}

export function ChartContainer({ 
  departmentChartData, 
  statusChartData, 
  trendData, 
  isLoading,
  animationActive
}: ChartContainerProps) {
  const [activeChart, setActiveChart] = useState<string>("departments");
  
  return (
    <div className="lg:col-span-2 rounded-xl bg-card p-6 shadow-sm border animate-slide-in">
      <div className="flex items-center justify-between mb-4">
        <h3 className="text-sm font-medium text-muted-foreground">Complaints Analytics</h3>
        <Tabs value={activeChart} onValueChange={setActiveChart} className="w-full">
          <div className="flex items-center space-x-2">
            <span className="text-xs text-muted-foreground">View:</span>
            <TabsList className="h-8">
              <TabsTrigger value="departments" className="text-xs px-2 py-1 h-6 flex items-center gap-1">
                <BarChart2 className="h-3.5 w-3.5" />
                <span className="hidden sm:inline">Departments</span>
              </TabsTrigger>
              <TabsTrigger value="status" className="text-xs px-2 py-1 h-6 flex items-center gap-1">
                <PieChartIcon className="h-3.5 w-3.5" />
                <span className="hidden sm:inline">Status</span>
              </TabsTrigger>
              <TabsTrigger value="trend" className="text-xs px-2 py-1 h-6 flex items-center gap-1">
                <LineChartIcon className="h-3.5 w-3.5" />
                <span className="hidden sm:inline">Trend</span>
              </TabsTrigger>
            </TabsList>
          </div>
          
          <TabsContent value="departments" className="h-[300px] mt-4">
            <DepartmentChart 
              data={departmentChartData} 
              isLoading={isLoading} 
              animationActive={animationActive} 
            />
          </TabsContent>
          
          <TabsContent value="status" className="h-[300px] mt-4">
            <StatusChart 
              data={statusChartData} 
              isLoading={isLoading} 
              animationActive={animationActive} 
            />
          </TabsContent>
          
          <TabsContent value="trend" className="h-[300px] mt-4">
            <TrendChart data={trendData} />
          </TabsContent>
        </Tabs>
      </div>
    </div>
  );
}
