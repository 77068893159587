
import { Complaint } from "@/lib/data";
import { MessageSquare, AlertCircle, Hash } from "lucide-react";

interface ComplaintMetadataProps {
  complaint: Complaint;
}

export function ComplaintMetadata({ complaint }: ComplaintMetadataProps) {
  const hasMetadata = complaint.language || complaint.issueTypeTitle || complaint.source;
  
  if (!hasMetadata) return null;

  return (
    <div className="rounded-xl border bg-card shadow-sm p-6 animate-scale-in">
      <h2 className="text-lg font-medium mb-4">Additional Information</h2>
      
      <div className="space-y-3">
        {complaint.issueTypeTitle && (
          <div className="flex items-start">
            <AlertCircle className="mr-2 h-4 w-4 mt-1 text-muted-foreground" />
            <div>
              <p className="font-medium">Issue Type</p>
              <p className="text-sm text-muted-foreground">{complaint.issueTypeTitle}</p>
            </div>
          </div>
        )}

        {complaint.language && (
          <div className="flex items-start">
            <MessageSquare className="mr-2 h-4 w-4 mt-1 text-muted-foreground" />
            <div>
              <p className="font-medium">Language</p>
              <p className="text-sm text-muted-foreground">{complaint.language}</p>
            </div>
          </div>
        )}
        
        {complaint.source && (
          <div className="flex items-start">
            <Hash className="mr-2 h-4 w-4 mt-1 text-muted-foreground" />
            <div>
              <p className="font-medium">Source</p>
              <p className="text-sm text-muted-foreground">{complaint.source}</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
