
import { Complaint } from "@/lib/data";
import { FileText, MessageSquare } from "lucide-react";
import { cn } from "@/lib/utils";

interface ComplaintDescriptionProps {
  complaint: Complaint;
}

export function ComplaintDescription({ complaint }: ComplaintDescriptionProps) {
  if (!complaint.description) return null;
  
  const isWhatsAppComplaint = complaint.source === 'whatsapp_bot';
  
  return (
    <div className={cn(
      "rounded-xl border bg-card shadow-sm p-6 animate-scale-in",
      isWhatsAppComplaint ? "bg-gradient-to-r from-green-50/50 to-transparent" : ""
    )}>
      <h2 className="text-lg font-medium mb-4 flex items-center">
        {isWhatsAppComplaint ? (
          <MessageSquare className="mr-2 h-5 w-5 text-green-600" />
        ) : (
          <FileText className="mr-2 h-5 w-5 text-muted-foreground" />
        )}
        Description
      </h2>
      
      <div className={cn(
        "prose prose-sm max-w-none",
        isWhatsAppComplaint ? "prose-green" : ""
      )}>
        <p className="whitespace-pre-wrap">
          {complaint.description}
        </p>
      </div>
    </div>
  );
}
