
interface MapLoadingStateProps {
  isLoading: boolean;
  noLocationsFound: boolean;
}

export function MapLoadingState({ isLoading, noLocationsFound }: MapLoadingStateProps) {
  if (isLoading) {
    return (
      <div className="absolute inset-0 flex flex-col items-center justify-center bg-background/50 z-10">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-primary mb-4"></div>
        <p className="text-muted-foreground">Loading map...</p>
      </div>
    );
  }

  if (noLocationsFound) {
    return (
      <div className="absolute inset-0 flex items-center justify-center bg-background/50 backdrop-blur-sm z-10">
        <div className="text-center p-4 rounded-md">
          <div className="mx-auto mb-4 h-16 w-16 text-muted-foreground">
            <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <circle cx="12" cy="12" r="10" />
              <line x1="12" y1="8" x2="12" y2="12" />
              <line x1="12" y1="16" x2="12.01" y2="16" />
            </svg>
          </div>
          <h3 className="text-lg font-medium">No location data available</h3>
          <p className="text-muted-foreground mt-2">
            None of the current complaints have location coordinates to display on the map.
          </p>
        </div>
      </div>
    );
  }

  return null;
}
