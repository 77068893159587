// Import shared types from the shared-types package
import { 
  ComplaintStatus,
  Department,
  ComplaintPriority,
  ComplaintData,
  ComplaintCreationRequest,
  validateComplaint,
  formatComplaintForDisplay
} from 'jansamadhan-shared-types';

// Re-export the shared types with PM Dashboard specific aliases for backward compatibility
export type PMComplaintStatus = "new" | "in-progress" | "resolved" | "rejected";

export type PMDepartment = 
  | "roads" 
  | "electricity" 
  | "water" 
  | "sanitation" 
  | "healthcare" 
  | "education"
  | "other";

// Type alias for backward compatibility
export interface Complaint {
  id: string;
  title: string;
  description: string;
  department: PMDepartment;
  status: PMComplaintStatus;
  createdAt: string;
  updatedAt: string;
  location?: string;
  assignedTo?: string;
  assignedToName?: string;
  submittedBy: {
    name: string;
    phone: string;
    email: string;
  };
  attachments: string[];
  comments: Array<{
    id: string;
    text: string;
    createdAt: string;
    user: string;
  }>;
  isDummy?: boolean;
  manuallyCreated?: boolean;
  googleMapsLink?: string;
  latitude?: number;
  longitude?: number;
  // Add new fields from WhatsApp bot
  source?: string;
  language?: string;
  issueTypeTitle?: string;
  whatsapp_user_name?: string;
  whatsapp_user_phone?: string;
}

export interface ComplaintWithSubmitter extends Complaint {
  submitter?: {
    id: string;
    name: string;
    email: string;
    phone: string;
  };
}

export interface NewComplaint {
  title: string;
  description: string;
  department: PMDepartment;
  status?: PMComplaintStatus;
  location?: string;
  submitted_by: string;
  manually_created?: boolean;
  google_maps_link?: string;
  latitude?: number;
  longitude?: number;
}

// Status options for dropdown filters
export const statusOptions = [
  { value: "new", label: "New" },
  { value: "in-progress", label: "In Progress" },
  { value: "resolved", label: "Resolved" },
  { value: "rejected", label: "Rejected" },
];

// Department options for dropdown filters
export const departmentOptions = [
  { value: "roads", label: "Roads" },
  { value: "electricity", label: "Electricity" },
  { value: "water", label: "Water" },
  { value: "sanitation", label: "Sanitation" },
  { value: "healthcare", label: "Healthcare" },
  { value: "education", label: "Education" },
  { value: "other", label: "Other" },
];

// Map DB department values to our frontend department values
export const departmentMapping: Record<string, PMDepartment> = {
  'roads': 'roads',
  'Roads': 'roads',
  'electricity': 'electricity',
  'Electricity': 'electricity',
  'water': 'water',
  'Water': 'water',
  'sanitation': 'sanitation',
  'Sanitation': 'sanitation',
  'healthcare': 'healthcare',
  'Healthcare': 'healthcare',
  'Health': 'healthcare',
  'health': 'healthcare',
  'education': 'education',
  'Education': 'education',
  'other': 'other',
  'Other': 'other'
};

// Priority options for dropdown filters
export const priorityOptions = [
  { value: "low", label: "Low" },
  { value: "medium", label: "Medium" },
  { value: "high", label: "High" },
  { value: "urgent", label: "Urgent" },
];

/**
 * Function to safely convert PM Dashboard status to shared status
 * @param status PM Dashboard status
 * @returns Shared complaint status
 */
export function convertToSharedStatus(status: PMComplaintStatus): ComplaintStatus {
  const statusMap: Record<PMComplaintStatus, ComplaintStatus> = {
    "new": ComplaintStatus.NEW,
    "in-progress": ComplaintStatus.IN_PROGRESS,
    "resolved": ComplaintStatus.RESOLVED,
    "rejected": ComplaintStatus.REJECTED
  };
  return statusMap[status] || ComplaintStatus.NEW;
}

/**
 * Function to safely convert shared status to PM Dashboard status
 * @param status Shared complaint status
 * @returns PM Dashboard status
 */
export function convertFromSharedStatus(status: ComplaintStatus): PMComplaintStatus {
  const statusMap: Record<string, PMComplaintStatus> = {
    [ComplaintStatus.NEW]: "new",
    [ComplaintStatus.IN_PROGRESS]: "in-progress",
    [ComplaintStatus.RESOLVED]: "resolved",
    [ComplaintStatus.REJECTED]: "rejected",
    [ComplaintStatus.PENDING]: "new" // Default pending to new for PM Dashboard
  };
  return statusMap[status] || "new";
}

/**
 * Function to convert PM Dashboard priority to shared priority
 * @param priority PM Dashboard priority
 * @returns Shared complaint priority
 */
export function convertToSharedPriority(priority: string): ComplaintPriority {
  switch (priority.toLowerCase()) {
    case 'low':
      return ComplaintPriority.LOW;
    case 'medium':
      return ComplaintPriority.MEDIUM;
    case 'high':
      return ComplaintPriority.HIGH;
    case 'urgent':
      return ComplaintPriority.URGENT;
    default:
      return ComplaintPriority.MEDIUM;
  }
}

/**
 * Function to convert shared priority to PM Dashboard priority
 * @param priority Shared complaint priority
 * @returns PM Dashboard priority
 */
export function convertFromSharedPriority(priority: ComplaintPriority): string {
  switch (priority) {
    case ComplaintPriority.LOW:
      return 'low';
    case ComplaintPriority.MEDIUM:
      return 'medium';
    case ComplaintPriority.HIGH:
      return 'high';
    case ComplaintPriority.URGENT:
      return 'urgent';
    default:
      return 'medium';
  }
}

/**
 * Convert PM department format to shared Department format
 */
export function convertToSharedDepartment(department: PMDepartment): Department {
  const deptMap: Record<PMDepartment, Department> = {
    'roads': Department.ROADS,
    'electricity': Department.ELECTRICITY,
    'water': Department.WATER,
    'sanitation': Department.SANITATION,
    'healthcare': Department.HEALTHCARE,
    'education': Department.EDUCATION,
    'other': Department.OTHER
  };
  return deptMap[department] || Department.OTHER;
}

/**
 * Convert shared Department format to PM department format
 */
export function convertFromSharedDepartment(department: Department): PMDepartment {
  const deptMap: Record<string, PMDepartment> = {
    [Department.ROADS]: 'roads',
    [Department.ELECTRICITY]: 'electricity',
    [Department.WATER]: 'water',
    [Department.SANITATION]: 'sanitation',
    [Department.HEALTHCARE]: 'healthcare',
    [Department.EDUCATION]: 'education',
    [Department.OTHER]: 'other',
    [Department.GENERAL]: 'other' // Map GENERAL to 'other' for PM Dashboard
  };
  return deptMap[department] || 'other';
}

/**
 * Convert complaint from shared format to PM Dashboard format
 */
export function convertFromSharedComplaint(complaint: ComplaintData): Complaint {
  return {
    id: complaint.id,
    title: complaint.title,
    description: complaint.description,
    department: convertFromSharedDepartment(complaint.department as Department),
    status: convertFromSharedStatus(complaint.status as ComplaintStatus),
    createdAt: typeof complaint.createdAt === 'string' ? complaint.createdAt : complaint.createdAt.toISOString(),
    updatedAt: typeof complaint.updatedAt === 'string' ? complaint.updatedAt : complaint.updatedAt.toISOString(),
    location: complaint.location,
    assignedTo: complaint.assignedTo,
    assignedToName: complaint.assignedToName,
    submittedBy: {
      name: complaint.submittedBy.name,
      phone: complaint.submittedBy.phone,
      email: complaint.submittedBy.email || ''
    },
    attachments: complaint.attachments || [],
    comments: complaint.comments?.map(comment => ({
      id: comment.id || '',
      text: comment.content || comment.text || '',
      createdAt: typeof comment.timestamp === 'string' ? comment.timestamp : comment.createdAt || new Date().toISOString(),
      user: comment.author || comment.user || ''
    })) || [],
    isDummy: complaint.isDummy,
    manuallyCreated: complaint.manuallyCreated,
    googleMapsLink: complaint.googleMapsLink,
    latitude: complaint.latitude,
    longitude: complaint.longitude,
    source: complaint.source,
    language: complaint.language,
    whatsapp_user_name: complaint.submittedBy.name,
    whatsapp_user_phone: complaint.submittedBy.phone
  };
}

/**
 * Convert complaint from PM Dashboard format to shared format
 */
export function convertToSharedComplaint(complaint: Complaint): ComplaintData {
  return {
    id: complaint.id,
    title: complaint.title,
    description: complaint.description,
    department: convertToSharedDepartment(complaint.department),
    status: convertToSharedStatus(complaint.status),
    createdAt: complaint.createdAt,
    updatedAt: complaint.updatedAt,
    location: complaint.location || '',
    assignedTo: complaint.assignedTo,
    assignedToName: complaint.assignedToName,
    submittedBy: {
      name: complaint.submittedBy.name,
      phone: complaint.submittedBy.phone,
      email: complaint.submittedBy.email
    },
    attachments: complaint.attachments,
    comments: complaint.comments?.map(comment => ({
      id: comment.id,
      content: comment.text,
      author: comment.user,
      timestamp: comment.createdAt,
      text: comment.text,
      createdAt: comment.createdAt,
      user: comment.user
    })),
    isDummy: complaint.isDummy,
    manuallyCreated: complaint.manuallyCreated,
    googleMapsLink: complaint.googleMapsLink,
    latitude: complaint.latitude,
    longitude: complaint.longitude,
    source: complaint.source,
    language: complaint.language
  };
}

// Re-export shared utility functions
export { validateComplaint, formatComplaintForDisplay };
