
import { ComplaintService } from './services/ComplaintService';
import { CommentService } from './services/comments';
import { BaseService } from './services/BaseService';
import { Complaint, NewComplaint } from './types/complaints';
import { Comment } from './types/comments';
import { SearchService } from './services/SearchService';
import { debugError } from './debug';

export class SupabaseService extends BaseService {
  // Complaint methods
  static async fetchComplaints(): Promise<Complaint[]> {
    try {
      return await ComplaintService.fetchComplaints();
    } catch (error) {
      debugError("SupabaseService", "Error fetching complaints", error);
      throw error;
    }
  }
  
  static async createComplaint(complaint: NewComplaint): Promise<Complaint | null> {
    try {
      return await ComplaintService.createComplaint(complaint);
    } catch (error) {
      debugError("SupabaseService", "Error creating complaint", error);
      throw error;
    }
  }
  
  static async getComplaintById(id: string): Promise<Complaint | null> {
    try {
      return await ComplaintService.getComplaintById(id);
    } catch (error) {
      debugError("SupabaseService", `Error fetching complaint with ID: ${id}`, error);
      throw error;
    }
  }
  
  static async updateStatus(complaintId: string, status: string): Promise<Complaint | null> {
    try {
      return await ComplaintService.updateStatus(complaintId, status);
    } catch (error) {
      debugError("SupabaseService", `Error updating status for complaint: ${complaintId}`, error);
      throw error;
    }
  }
  
  static async assignComplaint(complaintId: string, assigneeId: string | null): Promise<Complaint | null> {
    try {
      return await ComplaintService.assignComplaint(complaintId, assigneeId);
    } catch (error) {
      debugError("SupabaseService", `Error assigning complaint: ${complaintId}`, error);
      throw error;
    }
  }
  
  // Search methods
  static searchComplaints(complaints: Complaint[], query: string): Complaint[] {
    try {
      return SearchService.searchComplaints(complaints, query);
    } catch (error) {
      debugError("SupabaseService", `Error searching complaints with query: ${query}`, error);
      return complaints; // Return unfiltered complaints on error
    }
  }
  
  // Comment methods
  static async getCommentsByComplaintId(complaintId: string): Promise<Comment[]> {
    try {
      return await CommentService.getComments(complaintId);
    } catch (error) {
      debugError("SupabaseService", `Error fetching comments for complaint: ${complaintId}`, error);
      return []; // Return empty array on error
    }
  }
  
  static async addComment(complaintId: string, comment: { text: string, authorId: string }): Promise<Comment | null> {
    try {
      return await CommentService.addComment(complaintId, comment);
    } catch (error) {
      debugError("SupabaseService", `Error adding comment to complaint: ${complaintId}`, error);
      throw error;
    }
  }
}
