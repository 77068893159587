

import { getStatusColor } from './mapColors';
import { createTooltipContent, createEnhancedTooltipContent } from './tooltipUtils';
import type { Complaint } from '@/lib/types/complaints';
import type { InteractiveMarkerConfig } from '@/lib/types/maps';
import { debugError } from '@/lib/debug';

/**
 * Creates an interactive marker for the map
 */
export const createInteractiveMarker = ({
  map,
  complaint,
  onClick,
  onHover,
  onLeave
}: InteractiveMarkerConfig): google.maps.marker.AdvancedMarkerElement | google.maps.Marker => {
  if (!complaint.latitude || !complaint.longitude) {
    debugError('Map', 'Complaint missing coordinates:', complaint.id);
    return null as any;
  }
  
  const position = { lat: complaint.latitude, lng: complaint.longitude };
  const iconColor = getStatusColor(complaint.status);
  
  try {
    // Try to use Advanced Markers if available
    if (google.maps.marker && google.maps.marker.AdvancedMarkerElement) {
      // Create pin element
      const pinElement = document.createElement('div');
      pinElement.innerHTML = `
        <div class="map-marker" style="
          width: 20px;
          height: 20px;
          background-color: ${iconColor};
          border: 3px solid white;
          border-radius: 50%;
          box-shadow: 0 2px 6px rgba(0,0,0,0.3);
          cursor: pointer;
          transition: transform 0.2s ease, box-shadow 0.2s ease;
        "></div>
      `;
      
      // Add hover effect with CSS
      const style = document.createElement('style');
      style.textContent = `
        .map-marker:hover {
          transform: scale(1.2);
          box-shadow: 0 4px 8px rgba(0,0,0,0.4);
        }
      `;
      document.head.appendChild(style);

      const marker = new google.maps.marker.AdvancedMarkerElement({
        map,
        position,
        title: complaint.title,
        content: pinElement
      });
      
      // Set up tooltip
      let tooltipInfoWindow: google.maps.InfoWindow | null = null;
      
      // Marker events - using the more modern gmp-* events
      marker.addEventListener('gmp-mouseover', () => {
        if (!tooltipInfoWindow) {
          tooltipInfoWindow = new google.maps.InfoWindow({
            content: createTooltipContent(complaint),
            disableAutoPan: true
          });
        }
        tooltipInfoWindow.open(map, marker as unknown as google.maps.Marker);
        
        // Call the onHover callback if provided
        if (onHover) onHover(complaint);
      });
      
      marker.addEventListener('gmp-mouseout', () => {
        if (tooltipInfoWindow) {
          tooltipInfoWindow.close();
        }
        
        // Call the onLeave callback if provided
        if (onLeave) onLeave();
      });
      
      marker.addEventListener('gmp-click', () => {
        // Close hover tooltip first
        if (tooltipInfoWindow) {
          tooltipInfoWindow.close();
        }
        
        // Call the onClick callback if provided
        if (onClick) onClick(complaint);
      });
      
      return marker;
    } else {
      // Fallback to basic markers
      const marker = new google.maps.Marker({
        map,
        position,
        title: complaint.title,
        animation: google.maps.Animation.DROP,
        icon: {
          path: google.maps.SymbolPath.CIRCLE,
          fillColor: iconColor,
          fillOpacity: 1,
          strokeColor: '#FFFFFF',
          strokeWeight: 2,
          scale: 10
        }
      });
      
      // Hover tooltip
      const hoverInfoWindow = new google.maps.InfoWindow({
        content: createTooltipContent(complaint),
        disableAutoPan: true
      });
      
      // Show tooltip on hover
      marker.addListener('mouseover', () => {
        hoverInfoWindow.open(map, marker);
        
        // Call the onHover callback if provided
        if (onHover) onHover(complaint);
      });
      
      marker.addListener('mouseout', () => {
        hoverInfoWindow.close();
        
        // Call the onLeave callback if provided
        if (onLeave) onLeave();
      });
      
      // Show full info on click
      marker.addListener('click', () => {
        // Close hover tooltip first
        hoverInfoWindow.close();
        
        // Call the onClick callback if provided
        if (onClick) onClick(complaint);
      });
      
      return marker as unknown as google.maps.marker.AdvancedMarkerElement;
    }
  } catch (error) {
    debugError('Map', 'Error creating marker:', error);
    return null as any;
  }
};
