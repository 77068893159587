
import { cn } from "@/lib/utils";
import { PMComplaintStatus } from "@/lib/types/complaints";

interface StatusBadgeProps {
  status: PMComplaintStatus;
  className?: string;
}

export function StatusBadge({ status, className }: StatusBadgeProps) {
  const statusMap = {
    new: {
      label: "New",
      className: "bg-status-new/10 text-status-new border-status-new/20"
    },
    "in-progress": {
      label: "In Progress",
      className: "bg-status-in-progress/10 text-status-in-progress border-status-in-progress/20"
    },
    resolved: {
      label: "Resolved",
      className: "bg-status-resolved/10 text-status-resolved border-status-resolved/20"
    },
    rejected: {
      label: "Rejected",
      className: "bg-status-rejected/10 text-status-rejected border-status-rejected/20"
    }
  };

  const { label, className: badgeClassName } = statusMap[status];

  return (
    <span
      className={cn(
        "inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium border",
        badgeClassName,
        className
      )}
    >
      {label}
    </span>
  );
}
