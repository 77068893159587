
import { useState, useEffect } from "react";
import { Complaint, statusOptions, departmentOptions } from "@/lib/data";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { useQuery } from "@tanstack/react-query";
import { RoleService } from "@/lib/RoleService";
import { Skeleton } from "@/components/ui/skeleton";

interface ComplaintStatusProps {
  complaint: Complaint;
  onStatusChange?: (status: string) => void;
  onAssigneeChange?: (assigneeId: string | null) => void;
  isUpdatingStatus?: boolean;
  isAssigning?: boolean;
}

export function ComplaintStatus({ 
  complaint, 
  onStatusChange, 
  onAssigneeChange,
  isUpdatingStatus = false,
  isAssigning = false
}: ComplaintStatusProps) {
  const [currentAssigneeId, setCurrentAssigneeId] = useState<string | null>(complaint.assignedTo || null);
  
  // Get staff users for assignment
  const { data: staffUsers, isLoading: isLoadingStaff } = useQuery({
    queryKey: ['staff-users'],
    queryFn: () => RoleService.getUserRoles()
  });

  // Update local state when complaint assignee changes from props
  useEffect(() => {
    setCurrentAssigneeId(complaint.assignedTo || null);
  }, [complaint.assignedTo]);

  // Handle staff assignment change
  const handleAssigneeChange = (value: string) => {
    if (value === "unassigned") {
      setCurrentAssigneeId(null);
      if (onAssigneeChange) onAssigneeChange(null);
    } else {
      setCurrentAssigneeId(value);
      if (onAssigneeChange) onAssigneeChange(value);
    }
  };

  return (
    <div className="rounded-xl border bg-card shadow-sm p-6 animate-scale-in">
      <h2 className="text-lg font-medium mb-4">Status</h2>
      
      <Select 
        defaultValue={complaint.status} 
        onValueChange={onStatusChange}
        disabled={isUpdatingStatus || !onStatusChange}
      >
        <SelectTrigger>
          <SelectValue placeholder="Select status" />
        </SelectTrigger>
        <SelectContent>
          {statusOptions.map((option) => (
            <SelectItem key={option.value} value={option.value}>
              {option.label}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
      
      <div className="mt-6">
        <h3 className="text-sm font-medium mb-2">Department</h3>
        <Select defaultValue={complaint.department} disabled>
          <SelectTrigger>
            <SelectValue placeholder="Select department" />
          </SelectTrigger>
          <SelectContent>
            {departmentOptions.map((option) => (
              <SelectItem key={option.value} value={option.value}>
                {option.label}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>

      {/* Assignment Section */}
      <div className="mt-6">
        <h3 className="text-sm font-medium mb-2">Assigned To</h3>
        {isLoadingStaff ? (
          <Skeleton className="h-10 w-full" />
        ) : (
          <Select 
            value={currentAssigneeId || "unassigned"}
            onValueChange={handleAssigneeChange}
            disabled={isAssigning || !onAssigneeChange}
          >
            <SelectTrigger>
              <SelectValue placeholder="Assign to staff" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="unassigned">Unassigned</SelectItem>
              {staffUsers?.map((user) => (
                <SelectItem key={user.user_id} value={user.user_id}>
                  {user.name || user.email || "Unknown"}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        )}
      </div>
    </div>
  );
}
