import { Complaint } from "@/lib/types/complaints";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { TabNavigation } from "./TabNavigation";
import { FilteredStats } from "./FilteredStats";
import { useComplaintFilters } from "./hooks";
import { ViewRenderer } from "./ViewRenderer";
import { EmptyStateHandler } from "./EmptyStateHandler";
import { SearchHandler } from "./SearchHandler";
import { debugLog } from "@/lib/debug";
import { useEffect } from "react";

interface ComplaintListLogicProps {
  complaints: Complaint[];
  isLoading: boolean;
  searchQuery?: string;
  searchResults?: Complaint[];
  isSearching?: boolean;
}

export function ComplaintListLogic({
  complaints,
  isLoading,
  searchQuery = "",
  searchResults = [],
  isSearching = false,
}: ComplaintListLogicProps) {
  // Ensure complaints is always an array
  const safeComplaints = Array.isArray(complaints) ? complaints : [];
  
  // Use our custom hook for filter and sort logic
  const {
    activeFilter,
    statusFilter,
    departmentFilter,
    setStatusFilter,
    setDepartmentFilter,
    sortField,
    sortDirection,
    showFilters,
    activeView,
    toggleFilters,
    handleFilterChange,
    handleSortChange,
    handleViewChange,
    resetFilters,
    filteredComplaints,
    sortedComplaints
  } = useComplaintFilters(safeComplaints);
  
  // Log filtering info
  useEffect(() => {
    debugLog("ComplaintListLogic", "Filtering complaints", {
      activeFilter,
      complaintsCount: safeComplaints.length,
      filteredCount: filteredComplaints?.length || 0,
      sortedCount: sortedComplaints?.length || 0,
      activeView
    });
  }, [activeFilter, safeComplaints, filteredComplaints, sortedComplaints, activeView]);
  
  // Determine if we have active filters
  const hasActiveFilters = statusFilter.length > 0 || departmentFilter.length > 0;

  // If we're searching, show search results instead
  const displayComplaints = isSearching ? searchResults : sortedComplaints;
  
  // Check if we should show empty state
  const shouldShowEmptyState = 
    (isLoading === false && safeComplaints.length === 0) || // No complaints at all
    (isLoading === false && filteredComplaints.length === 0 && safeComplaints.length > 0) || // No filtered results
    (isSearching && searchResults.length === 0); // No search results
  
  // Handle search results display
  if (isSearching) {
    return (
      <SearchHandler
        isSearching={isSearching}
        searchQuery={searchQuery}
        searchResults={searchResults}
        totalComplaints={safeComplaints.length}
        onClearSearch={() => {}}
        activeView={activeView}
      />
    );
  }

  return (
    <div className="space-y-4">
      <Tabs value={activeFilter} onValueChange={handleFilterChange} defaultValue="all">
        <TabNavigation
          complaintCount={displayComplaints?.length || 0}
          activeFilter={activeFilter}
          onFilterChange={handleFilterChange}
          onSortChange={handleSortChange}
          sortField={sortField}
          sortDirection={sortDirection}
          showFilters={showFilters}
          toggleFilters={toggleFilters}
          activeView={activeView}
          onViewChange={handleViewChange}
          statusFilter={statusFilter}
          departmentFilter={departmentFilter}
          setStatusFilter={setStatusFilter}
          setDepartmentFilter={setDepartmentFilter}
          resetFilters={resetFilters}
        />
        
        <TabsContent value={activeFilter} className="py-4">
          {!isLoading && filteredComplaints?.length > 0 && (
            <FilteredStats 
              complaints={filteredComplaints} 
              filteredCount={filteredComplaints.length}
              showFilterStats={hasActiveFilters || activeFilter !== "all"}
            />
          )}
          
          {shouldShowEmptyState ? (
            <EmptyStateHandler
              isLoading={isLoading}
              isSearching={isSearching}
              complaints={safeComplaints}
              filteredComplaints={filteredComplaints}
              searchResults={searchResults}
              searchQuery={searchQuery}
              activeFilter={activeFilter}
              hasActiveFilters={hasActiveFilters}
              resetFilters={resetFilters}
            />
          ) : (
            <div className="mt-4">
              <ViewRenderer view={activeView} complaints={displayComplaints || []} />
            </div>
          )}
        </TabsContent>
      </Tabs>
    </div>
  );
}
