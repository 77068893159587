
import { Comment } from "../../types/comments";
import { BaseService } from "../BaseService";
import { debugError } from "../../debug";
import { PostgrestError } from "@supabase/supabase-js";

export class AddCommentService extends BaseService {
  static async addComment(complaintId: string, comment: { text: string, authorId: string }): Promise<Comment | null> {
    try {
      const { data, error } = await this.supabase
        .from('comments')
        .insert([{ 
          complaint_id: complaintId,
          text: comment.text,
          user_id: comment.authorId
        }])
        .select()
        .single();

      if (error) {
        throw new Error(`Failed to add comment: ${error.message}`);
      }

      if (!data) {
        throw new Error('No data returned after adding comment');
      }

      return {
        id: data.id,
        complaintId: data.complaint_id,
        text: data.text,
        authorId: data.user_id,
        createdAt: new Date(data.created_at),
      };
    } catch (error) {
      let errorMessage = 'Unknown error in addComment';
      
      if (error instanceof Error) {
        errorMessage = error.message;
      } else if (typeof error === 'object' && error !== null) {
        const pgError = error as PostgrestError;
        errorMessage = pgError.message || 'Database error';
      }
      
      debugError('AddCommentService', errorMessage, error);
      return null;
    }
  }
}
