
import { DashboardLayout } from "@/components/layout/DashboardLayout";
import { ComplaintList } from "@/components/dashboard/complaints";
import { useQuery } from "@tanstack/react-query";
import { ComplaintService } from "@/lib/services/ComplaintService";
import { Complaint } from "@/lib/types/complaints";
import { toast } from "sonner";
import { debugLog, debugError } from "@/lib/debug";
import { Button } from "@/components/ui/button";
import { useState } from "react";
import { AlertCircle, Database } from "lucide-react";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { checkSupabaseConnection } from "@/integrations/supabase/client";

const Complaints = () => {
  const [retryCount, setRetryCount] = useState(0);
  const [showDebugInfo, setShowDebugInfo] = useState(true);
  const [dbStatus, setDbStatus] = useState<{connected: boolean, message: string}>({connected: false, message: "Checking..."});
  
  // Check Supabase connection
  const checkDatabase = async () => {
    try {
      const isConnected = await checkSupabaseConnection();
      setDbStatus({
        connected: isConnected,
        message: isConnected ? "Connected to database" : "Failed to connect"
      });
    } catch (error) {
      setDbStatus({
        connected: false,
        message: "Error checking connection"
      });
    }
  };
  
  // Check connection on mount and on retry
  useState(() => {
    checkDatabase();
  });
  
  const { 
    data: complaints, 
    isLoading, 
    error,
    refetch,
    isError
  } = useQuery({
    queryKey: ['complaints', retryCount],
    queryFn: async () => {
      try {
        debugLog('Complaints', 'Fetching complaints...');
        const result = await ComplaintService.fetchComplaints();
        debugLog('Complaints', `Received ${result?.length || 0} complaints`, result);
        return result || []; 
      } catch (error) {
        debugError('Complaints', 'Error fetching complaints:', error);
        throw error;
      }
    },
    retry: 1,
    meta: {
      onError: (error: Error) => {
        debugError('Complaints', 'Query error:', error);
        toast.error('Error loading complaints. Please try again.');
      }
    }
  });

  const handleRetry = () => {
    setRetryCount(prev => prev + 1);
    checkDatabase();
    refetch();
    toast.info('Retrying data fetch...');
  };

  // Ensure complaints is always an array
  const safeComplaints = Array.isArray(complaints) ? complaints : [];

  return (
    <DashboardLayout>
      <div className="space-y-8">
        <div className="flex justify-between items-center">
          <div>
            <h1 className="text-3xl font-bold tracking-tight">Complaints</h1>
            <p className="text-muted-foreground">
              Manage and track constituent complaints
              {safeComplaints.length > 0 && (
                <span className="ml-1 font-medium">
                  ({safeComplaints.length} {safeComplaints.length === 1 ? 'complaint' : 'complaints'})
                </span>
              )}
            </p>
          </div>
          <div className="flex gap-2">
            <Button 
              onClick={() => setShowDebugInfo(prev => !prev)} 
              variant="outline" 
              size="sm"
            >
              {showDebugInfo ? 'Hide Debug' : 'Show Debug'}
            </Button>
            <Button onClick={handleRetry} variant="outline" size="sm">
              Reload Data
            </Button>
          </div>
        </div>

        {dbStatus && (
          <Alert variant={dbStatus.connected ? "default" : "destructive"}>
            <Database className="h-4 w-4" />
            <AlertTitle>Database Connection</AlertTitle>
            <AlertDescription>
              {dbStatus.message}
            </AlertDescription>
          </Alert>
        )}

        {isError && (
          <Alert variant="destructive">
            <AlertCircle className="h-4 w-4" />
            <AlertTitle>Error fetching complaints</AlertTitle>
            <AlertDescription>
              {error?.message || 'Unknown error occurred'}
            </AlertDescription>
          </Alert>
        )}

        {showDebugInfo && (
          <Alert>
            <AlertTitle>Debug Information</AlertTitle>
            <AlertDescription className="space-y-2">
              <div>Query Status: {isLoading ? 'Loading' : isError ? 'Error' : 'Success'}</div>
              <div>Database Connected: {dbStatus.connected ? 'Yes' : 'No'}</div>
              <div>Complaints Count: {safeComplaints.length}</div>
              <div>Retry Count: {retryCount}</div>
              <div className="font-semibold mt-2">Allowed Department Values:</div>
              <pre className="text-xs overflow-auto bg-muted p-2 rounded-md max-h-20">
                {"others" | "water" | "electricity" | "roads" | "sanitation"}
              </pre>
              {safeComplaints.length > 0 && (
                <>
                  <div className="font-semibold mt-2">First Complaint:</div>
                  <pre className="text-xs overflow-auto bg-muted p-2 rounded-md max-h-40">
                    {JSON.stringify(safeComplaints[0], null, 2)}
                  </pre>
                </>
              )}
            </AlertDescription>
          </Alert>
        )}

        <ComplaintList 
          complaints={safeComplaints} 
          isLoading={isLoading} 
        />
      </div>
    </DashboardLayout>
  );
};

export default Complaints;
