import { ClipboardIcon } from "./ClipboardIcon";
import { Button } from "@/components/ui/button";
import { RefreshCw, Filter, Search, PlusCircle } from "lucide-react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

interface EmptyStateProps {
  type: "no-complaints" | "no-filtered-results" | "no-search-results";
  filter?: string;
  query?: string;
  hasActiveFilters?: boolean;
  resetFilters?: () => void;
}

export function EmptyState({ type, filter, query, hasActiveFilters = false, resetFilters }: EmptyStateProps) {
  let title = "No complaints found";
  let description = "No complaints match the current criteria";
  let icon = <ClipboardIcon className="h-6 w-6 text-muted-foreground" />;
  
  if (type === "no-complaints") {
    title = "No complaints found";
    description = "There are currently no complaints in the system";
    icon = <ClipboardIcon className="h-6 w-6 text-muted-foreground" />;
  } else if (type === "no-filtered-results") {
    title = `No ${filter} complaints found`;
    description = "Try changing or clearing your filters";
    icon = <Filter className="h-6 w-6 text-muted-foreground" />;
  } else if (type === "no-search-results") {
    title = "No search results";
    description = query ? `No results found for "${query}"` : "No results found for your search";
    icon = <Search className="h-6 w-6 text-muted-foreground" />;
  }

  return (
    <motion.div 
      className="flex flex-col items-center justify-center py-12 text-center"
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
    >
      <div className="rounded-full bg-muted p-3 mb-4">
        {icon}
      </div>
      <h3 className="font-medium text-lg">{title}</h3>
      <p className="text-sm text-muted-foreground mt-1 max-w-md">{description}</p>
      
      <div className="mt-6 flex flex-wrap gap-3 justify-center">
        {hasActiveFilters && resetFilters && (
          <Button
            variant="outline"
            size="sm"
            onClick={resetFilters}
            className="flex items-center gap-2"
          >
            <RefreshCw className="h-4 w-4" />
            Clear filters
          </Button>
        )}
        
        {type === "no-complaints" && (
          <Button asChild size="sm" className="flex items-center gap-2">
            <Link to="/new-complaint">
              <PlusCircle className="h-4 w-4" />
              Create Complaint
            </Link>
          </Button>
        )}
      </div>
    </motion.div>
  );
}
